import React, { useState } from "react";

import styles from "./ReviewsDesignPopUp.module.scss";

import { Modal } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleLeft,
  faAngleRight,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

import parse from "html-react-parser";
import { useTranslation } from "react-i18next";

const fit1 = "https://d16qu8ed6p45v5.cloudfront.net/dlctechwebsite/FIT_1.png";
const fit2 = "https://d16qu8ed6p45v5.cloudfront.net/dlctechwebsite/FIT_2.png";
const fit3 = "https://d16qu8ed6p45v5.cloudfront.net/dlctechwebsite/FIT_3.png";
const fit4 = "https://d16qu8ed6p45v5.cloudfront.net/dlctechwebsite/FIT_4.png";
const fit5 = "https://d16qu8ed6p45v5.cloudfront.net/dlctechwebsite/FIT_5.png";
const fit6 = "https://d16qu8ed6p45v5.cloudfront.net/dlctechwebsite/FIT_6.png";
interface PopUpExampleProps {
  popup: boolean;
  close: () => void;
}

const ReviewsDesignPopUp: React.FC<PopUpExampleProps> = (props) => {
  const { t } = useTranslation();

  const [popup, setPopup] = useState(false);

  const [desktopIndex, setDesktopIndex] = useState(0);
  const [mobileIndex, setMobileIndex] = useState(0);

  const mobilePhoto = [fit1, fit4, fit5];
  const desktopPhoto = [fit2, fit3, fit6];

  const addIndexDesktop = () => {
    if (desktopIndex < desktopPhoto.length - 1) {
      setDesktopIndex(desktopIndex + 1);
    } else {
      setDesktopIndex(0);
    }
  };

  const removeIndexDesktop = () => {
    if (desktopIndex > 0) {
      setDesktopIndex(desktopIndex - 1);
    } else {
      setDesktopIndex(desktopPhoto.length - 1);
    }
  };

  const addIndexMobile = () => {
    if (mobileIndex < desktopPhoto.length - 1) {
      setMobileIndex(mobileIndex + 1);
    } else {
      setMobileIndex(0);
    }
  };

  const removeIndexMobile = () => {
    if (mobileIndex > 0) {
      setMobileIndex(mobileIndex - 1);
    } else {
      setMobileIndex(mobilePhoto.length - 1);
    }
  };

  return (
    <>
      <Modal
        show={props.popup}
        onHide={() => (setPopup(!popup), props.close)}
        className={`d-flex flex-row justify-content-center align-items-center  `}
        style={{
          height: "100vh",
          width: "100vw",
          overflow: "scroll",
          //   border: "1px solid red",
          background: "rgba(0,0,0,0.85)",
        }}
        contentClassName={`${styles.bodyModal}  `}
      >
        <div className={`${styles.body} `}>
          <Modal.Body
            className={`d-flex flex-column align-items-start  justify-content-start p-4
            bg-light color-black 
            
            `}
            style={{
              height: "90vh",
              width: "90vw",
              borderRadius: "20px",
              overflow: "scroll",
              //   border: `2px solid red`,
            }}
          >
            <div
              className="d-flex flex-row align-items-center justify-content-end col-12 "
              style={{ height: "50px" }}
            >
              <span
                className={`${styles.closeButton} `}
                onClick={() => props.close()}
              >
                <FontAwesomeIcon icon={faXmark as IconProp} />
              </span>
            </div>
            <div className="ps-7 pe-7 d-md-flex d-none flex-column align-items-start justify-content-start col-12 ">
              <div className="d-flex flex-row align-items-center  justify-content-start col-12 ">
                <span className=" sub-title">
                  {parse(t(`Review_quadratoni_titoletto_4`))}
                  {/* Design */}
                </span>
              </div>
              <div className="d-flex flex-row align-items-center  justify-content-start col-12 mt-4">
                <span className=" small-title fw-bold">
                  {parse(t(`Review_quadratoni_titolo_4_interno`))}
                  {/* Fits <br /> Everywhere */}
                </span>
              </div>
              <div className={`${styles.content} col-12  mt-5 `}>
                <div className="d-flex flex-row align-items-center justify-content-center col-12 h-100 ">
                  <div className="col-1 d-flex flex-row align-items-center justify-content-center ">
                    <FontAwesomeIcon
                      icon={faAngleLeft as IconProp}
                      style={{ fontSize: "3rem" }}
                      onClick={() => removeIndexDesktop()}
                    />
                  </div>
                  <div className={`col-10    ${styles.imgContainer1}`}>
                    <img
                      src={desktopPhoto[desktopIndex]}
                      alt="revDesignPopUpImage"
                    />
                  </div>
                  <div className="col-1 d-flex flex-row align-items-center justify-content-center ">
                    <FontAwesomeIcon
                      icon={faAngleRight as IconProp}
                      style={{ fontSize: "3rem" }}
                      onClick={() => addIndexDesktop()}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className=" d-flex d-sm-none flex-column align-items-start justify-content-start col-12 pb-4">
              <div className="d-flex flex-row align-items-center justify-content-start col-12 ">
                <span className=" sub-title">
                  {parse(t(`Review_quadratoni_titoletto_4`))}
                  {/* Design */}
                </span>
              </div>
              <div className="d-flex flex-row align-items-center justify-content-start col-12 mt-4">
                <span className=" small-title fw-bold">
                  {parse(t(`Review_quadratoni_titolo_4_interno`))}
                  {/* Fits <br /> Everywhere */}
                </span>
              </div>
              <div className={`${styles.content} col-12  mt-5  `}>
                <div className="d-flex flex-row col-12  d-flex flex-row align-items-center justify-content-center h-100 ">
                  <div className="col-2 d-flex flex-row align-items-center justify-content-start  ps-3 ">
                    <FontAwesomeIcon
                      icon={faAngleLeft as IconProp}
                      style={{ fontSize: "3rem" }}
                      onClick={() => removeIndexMobile()}
                    />
                  </div>
                  <div className={`${styles.imgContainer1} col-8  `}>
                    <img
                      src={mobilePhoto[mobileIndex]}
                      alt="revDesignPopUpImage"
                    />
                  </div>
                  <div className="col-2  d-flex flex-row align-items-center justify-content-end pe-3 ">
                    <FontAwesomeIcon
                      icon={faAngleRight as IconProp}
                      style={{ fontSize: "3rem" }}
                      onClick={() => addIndexMobile()}
                    />
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
        </div>
      </Modal>
    </>
  );
};

export default ReviewsDesignPopUp;
