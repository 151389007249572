import { Route, Routes, useLocation } from "react-router-dom";
import Home from "./Routes/Home/Home";
import Products from "./Routes/Products/Products";
import Services from "./Routes/Services/Services";
import Contacts from "./Routes/Contacts/Contacts";
import Assistance from "./Routes/Assistance/Assistance";
import AboutUs from "./Routes/AboutUs/AboutUs";
// import DLCMenu from "./Routes/DLCMenu/DLCMenu";
import DLCReview from "./Routes/DLCReview/DLCReview";
// import DigitalMenu from "./Routes/DigitalMenu/DigitalMenu";
import WebsiteCreation from "./Routes/WebsiteCreation/WebsiteCreation";
import FAQ from "./Routes/FAQ/FAQ";
import Businesscard from "./Routes/Businesscard/Businesscard";
import PrecontactCreation from "./Routes/PrecontactCreation/PrecontactCreation";
import ContactComplete from "./Routes/ContactComplete/ContactComplete";
import PrivacyPolicy from "./Routes/PrivacyPolicy/PrivacyPolicy";
import AnnexB from "./Routes/AnnexB/AnnexB";
import TermsContditions from "./Routes/Terms&Conditions/Terms&Conditions";
import { useEffect } from "react";

declare global {
  interface Window {
    gtag: (...args: any[]) => void;
  }
}

const usePageTracking = () => {
  const location = useLocation();

  useEffect(() => {
    // Invia una "pageview" a Google Analytics ogni volta che cambia la route
    window.gtag("config", "YOUR_TRACKING_ID", {
      page_path: location.pathname,
    });
  }, [location]);
};

const Container = () => {
  usePageTracking();

  return (
    <Routes>
      <Route path={"/"} element={<Home />} />
      <Route path={"/Products"} element={<Products />} />
      {/* <Route path={"/Products/DLCMenu"} element={<DLCMenu />} /> */}
      <Route path={"/Products/DLCReviews"} element={<DLCReview />} />
      <Route path={"/Products/Businesscard"} element={<Businesscard />} />
      <Route path={"/Services"} element={<Services />} />
      {/* <Route path={"/Services/DigitalMenu"} element={<DigitalMenu />} /> */}
      <Route path={"/Services/WebsiteCreation"} element={<WebsiteCreation />} />

      <Route path={"/Contact-us"} element={<Contacts />} />
      <Route path={"/Assistance"} element={<Assistance />} />
      <Route path={"/Assistance/FAQ"} element={<FAQ />} />
      <Route path={"/AboutUs"} element={<AboutUs />} />
      <Route
        path={"/Products/Businesscard/GenerateContact"}
        element={<PrecontactCreation />}
      />
      <Route
        path={"/Products/Businesscard/GenerateContact/Done"}
        element={<ContactComplete />}
      />

      <Route path={"/Privacy-Policy"} element={<PrivacyPolicy />} />
      <Route path={"/Privacy-Policy/Annex-B"} element={<AnnexB />} />
      <Route path={"/Terms&Conditions"} element={<TermsContditions />} />

      {/* <Route path={"/prezzofisso"} element={<PrezzoFisso />} /> */}
      {/* <Route path={"/allacarta"} element={<AllaCarta />} /> */}
      {/* <Route path={"/delgiorno"} element={<DelGiorno />} /> */}
    </Routes>
  );
};

export default Container;
