import { useEffect, useRef, useState } from "react";

import smile from "../../assets/emoji white.png";
import bamboo from "../../assets/bamboo.png";
import faggio from "../../assets/faggio.png";
import ciliegio from "../../assets/ciliegio.png";
import basicfaggio from "../../assets/basicfaggio.png";

import bambooQR from "../../assets/bambooQRNome.png";
import faggioQR from "../../assets/faggioQRNome.png";
import ciliegioQR from "../../assets/ciliegioQRNome.png";
import hand from "../../assets/business-card-hand.png";
import thikness from "../../assets/measure.png";

import faggioretro from "../../assets/busscard_388.png";
import faggiofront from "../../assets/busscard_389.png";

import blackfront from "../../assets/blackfront.png";
import blackretro from "../../assets/blackretro.png";

import stainlessfront from "../../assets/stainlessfront.png";
import stainlessretro from "../../assets/stainlessretro.png";

import goldfront from "../../assets/goldfront.png";
import goldretro from "../../assets/goldretro.png";

import cherryfront from "../../assets/busscard_390.png";
import cherryRetro from "../../assets/busscard_391.png";
import bamboofront from "../../assets/busscard_392.png";
import bambooretro from "../../assets/busscard_394.png";

import efficiencyImg from "../../assets/cardEfficiency.png";
import environmentImg from "../../assets/cardEnvironment.png";
import moneyImg from "../../assets/MONEY_quadratone.png";
import timeImg from "../../assets/CLESSIODRA.png";

import whitestd from "../../assets/dlc_original_white.png";
import blackstd from "../../assets/dlc_original_black.png";
import stdtwo_1 from "../../assets/dlc_original_coppia.png";
import stdtwo_2 from "../../assets/dlc_original_coppia_2.png";
import designer from "../../assets/Web_Designer.png";
import designertwo from "../../assets/Designer_coppia.png";
import businessman from "../../assets/Businessman_1.png";
import businessman_2 from "../../assets/Businessman_2.png";
import businessmantwo from "../../assets/Businessman_coppia.png";

// import menuimg from "../assets/Menu2.png";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";

import styles from "./Businesscard.module.scss";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faNfcSymbol } from "@fortawesome/free-brands-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import {
  faAngleDown,
  faAngleLeft,
  faAngleRight,
  faAngleUp,
  faCircle,
  faMobile,
  faMobileScreen,
  faPlus,
  faPrint,
  faQrcode,
  faRulerCombined,
  faSmile,
  faSmileBeam,
} from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import parse from "html-react-parser";
import { faIdCard } from "@fortawesome/free-regular-svg-icons";
import { Swiper, SwiperSlide } from "swiper/react";
import BusinessCardEfficiencyPopUp from "../../components/BusinessCardPopUp/BusinessCardEfficiencyPopUp/BusinessCardEfficiencyPopUp";
import BusinessCardMoneyPopUp from "../../components/BusinessCardPopUp/BusinessCardMoneyPopUp/BusinessCardMoneyPopUp";
import BusinessCardEnvironmentPopUp from "../../components/BusinessCardPopUp/BusinessCardEnvironmentPopUp/BusinessCardEnvironmentPopUp";
import BusinessCardTimePopUp from "../../components/BusinessCardPopUp/BusinessCardTimePopUp/BusinessCardTimePopUp";
import DropDown from "../../components/DropDown/DropDown";
import { useForm } from "react-hook-form";
import axiosHelper from "../../helpers/axiosHelper";

const wood: CardMaterial = {
  name: "WOOD",
  color: [
    {
      name: "bamboo",
      imageurl: bamboo,
      images: [bamboofront, bambooretro, bambooQR],
    },
    {
      name: "faggio",
      imageurl: faggio,
      images: [faggiofront, faggioretro, faggioQR],
    },
    {
      name: "ciliegio",
      imageurl: ciliegio,
      images: [cherryfront, cherryRetro, ciliegioQR],
    },
  ],
};

const pvc: CardMaterial = {
  name: "PVC",
  color: [{ name: "black", color: "black", images: [blackretro, blackfront] }],
};

const metal: CardMaterial = {
  name: "METAL",
  color: [
    {
      name: "satin steel",
      imageurl:
        "https://d16qu8ed6p45v5.cloudfront.net/dlctechwebsite/Stainless-Steel.jpg",
      images: [stainlessretro, stainlessfront],
    },
    {
      name: "gold",
      imageurl: "https://d16qu8ed6p45v5.cloudfront.net/dlctechwebsite/gold.jpg",
      images: [goldretro, goldfront],
    },
    { name: "black", color: "black", images: [blackretro, blackfront] },
  ],
};

const webstandardImages = [whitestd, blackstd, stdtwo_1, stdtwo_2];
const webbusinessImages = [
  designer,
  designertwo,
  businessman,
  businessman_2,
  businessmantwo,
];

const Businesscard: React.FC = () => {
  const { t } = useTranslation();
  const redirect = useNavigate();
  const [scrollTop, setScrollTop] = useState(0);

  const refMobile = useRef<null | HTMLDivElement>(null);
  const refDesktop = useRef<null | HTMLDivElement>(null);
  const rightDivRef = useRef<null | HTMLDivElement>(null);
  const refPersonalization = useRef<null | HTMLDivElement>(null);
  const [imageN, setImageN] = useState(0);
  const [cardImages, setCardImages] = useState<string[]>([]);
  const [cardPhotoNumber, setCardPhotoNumber] = useState(0);
  const [cardPhotoId, setCardPhotoId] = useState("B");
  const [material, setMaterial] = useState<CardMaterial>(wood);
  const [color, setColor] = useState<CardColor>({
    name: "bamboo",
    imageurl: bamboo,
    images: [bamboofront, bambooretro, bambooQR],
  });

  const [essentialOpen, setEssentialOpen] = useState(false);
  const [necessaryOpen, setNecessaryOpen] = useState(false);
  const [totalOpen, setTotalOpen] = useState(false);

  const [woodOpen, setWoodOpen] = useState(false);
  const [pvcOpen, setPvcOpen] = useState(false);
  const [metalOpen, setMetalOpen] = useState(false);

  const [timePopUp, setTimePopUp] = useState(false);
  const [environmentPopUp, setEnvironmentPopUp] = useState(false);
  const [moneyPopUp, setMoneyPopUp] = useState(false);
  const [efficiencyPopUp, setEfficiencyPopUp] = useState(false);

  const [clientName, setClientName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [object, setObject] = useState("");
  const [region, setRegion] = useState("");
  const [message, setMessage] = useState("");
  const [jobTitle, setJobTitle] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [companySize, setCompanySize] = useState("");
  const [potentialUsers, setPotentialUsers] = useState("");
  const [privacy, setPrivacy] = useState(false);
  const [defaultIndex, setDefaultIndex] = useState(0);
  const [businessIndex, setBusinessIndex] = useState(0);

  const formError = useRef(false);
  const formSuccess = useRef(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<BusinessForm>();

  const onSubmit = async (data: BusinessForm) => {
    console.log("submit");
    formError.current = false;

    if (clientName.length > 0) {
      data.client_name = clientName;
    } else {
      formError.current = true;
    }

    if (email !== "") {
      data.email = email;
    } else {
      formError.current = true;
    }

    if (region !== "") {
      data.region = region;
    } else {
      formError.current = true;
    }

    if (phone !== "") {
      data.phone_number = phone;
    } else {
      formError.current = true;
    }

    if (message !== "") {
      data.message = message;
    }

    if (object !== "") {
      data.object = object;
    } else {
      formError.current = true;
    }
    if (companyName !== "") {
      data.company_name = companyName;
    } else {
      formError.current = true;
    }

    if (companySize !== "") {
      data.company_size = companySize;
    } else {
      formError.current = true;
    }

    if (potentialUsers !== "") {
      data.potential_users = potentialUsers;
    } else {
      formError.current = true;
    }

    if (!privacy) {
      formError.current = true;
    }

    console.log(data);
    if (formError.current === false) {
      await axiosHelper
        .createContactRequest(data)
        .then((response: any) => (formSuccess.current = true));
    }
  };

  const addIndexDefault = () => {
    if (defaultIndex < webstandardImages.length - 1) {
      setDefaultIndex(defaultIndex + 1);
    } else {
      setDefaultIndex(0);
    }
  };

  const removeIndexDefault = () => {
    if (defaultIndex > 0) {
      setDefaultIndex(defaultIndex - 1);
    } else {
      setDefaultIndex(webstandardImages.length - 1);
    }
  };

  const addIndexBusiness = () => {
    if (businessIndex < webbusinessImages.length - 1) {
      setBusinessIndex(businessIndex + 1);
    } else {
      setBusinessIndex(0);
    }
  };

  const removeIndexBusiness = () => {
    if (businessIndex > 0) {
      setBusinessIndex(businessIndex - 1);
    } else {
      setBusinessIndex(webbusinessImages.length);
    }
  };

  const videourl =
    "https://d16qu8ed6p45v5.cloudfront.net/dlctechwebsite/businesscard3d.mp4";

  //ACTIVATE ON DEPLOY
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleClick = () => {
    // ref.current !== null &&
    //   ref.current!.scrollIntoView({ block: "end", behavior: "smooth" });
    // redirect("/Services/DigitalMenu");
    window.location.href =
      "https://shop.dlctech.it/collections/dlc-business-card";
  };

  const handleClickPersonalization = () => {
    // ref.current !== null &&
    //   ref.current!.scrollIntoView({ block: "end", behavior: "smooth" });
    // redirect("/Services/DigitalMenu");
    refPersonalization.current !== null &&
      refPersonalization.current!.scrollIntoView({
        block: "end",
        behavior: "smooth",
      });
  };

  const changeMaterial = (material: CardMaterial) => {
    setMaterial(material);
    setColor(material.color[0]);
  };

  const handleClickMobile = () => {
    refMobile.current !== null &&
      refMobile.current!.scrollIntoView({ block: "end", behavior: "smooth" });
  };

  const handleScroll = () => {
    let vDistance = window.scrollY;

    if (rightDivRef.current) {
      // console.log(rightDivRef.current.getBoundingClientRect().top, vDistance);
      setScrollTop(vDistance);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const upImage = () => {
    if (color.images.length > 2) {
      if (imageN < 2) {
        setImageN(imageN + 1);
      } else {
        setImageN(0);
      }
    } else {
      if (imageN === 0) {
        setImageN(1);
      } else {
        setImageN(0);
      }
    }
  };

  useEffect(() => {
    changeCardImage();
  }, [cardPhotoId]);

  const changeCardImage = () => {
    setCardPhotoNumber(0);
    if (cardPhotoId === "F") {
      setCardImages([faggiofront, faggioretro, faggioQR]);
    } else if (cardPhotoId === "B") {
      setCardImages([bamboofront, bambooretro, bambooQR]);
    } else {
      setCardImages([cherryfront, cherryRetro, ciliegioQR]);
    }
  };

  // useEffect(() => {
  //   console.log(color);
  // }, [color]);

  const downImage = () => {
    if (color.images.length > 2) {
      if (imageN > 0) {
        setImageN(imageN - 1);
      } else {
        setImageN(2);
      }
    } else {
      if (imageN === 1) {
        setImageN(0);
      } else {
        setImageN(1);
      }
    }
  };
  const handleCustomizeMobile = () => {
    // setImageN(2);
    window.location.href =
      "https://shop.dlctech.it/collections/dlc-business-card";
  };

  return (
    <>
      <div className="bg-black ">
        <Header dark={true} selected="Businesscard" />

        <div
          className="d-md-flex  d-none flex-row align-items-start justify-content-around col-12 p-7 "
          style={{ backgroundColor: "black" }}
        >
          <div
            className="  d-flex flex-column align-items-center justify-content-center   "
            style={{ width: "60%" }}
          >
            <div
              className={`col-12   d-flex flex-column align-items-center justify-content-end ${styles.space} `}
            ></div>
            <div className="col-12 d-flex flex-column align-items-center justify-content-center">
              <span className="col-12 bigger-title-esquare color-dark_grey_50">
                DLC
              </span>
              <span className="col-12 bigger-title-esquare color-white">
                BUSINESS CARD
              </span>
            </div>
            <div className="col-12 mt-7 d-flex flex-row align-items-start justify-content-start">
              <div
                className="col-2  d-flex flex-column align-items-start justify-content-start "
                style={{ marginInlineEnd: "10%" }}
              >
                <div className="col-12">
                  <img src={smile} style={{ height: "35px" }} alt="smile" />
                </div>
                <div className="col-12 mt-2">
                  <span className="sub-title-ml fw-normal color-white pe-5">
                    {parse(t(`dlcreview_ecofriendly`))}
                  </span>
                </div>
              </div>

              <div
                className="col-2   d-flex flex-column align-items-start justify-content-start "
                style={{ marginInlineEnd: "10%" }}
              >
                <div className="col-12" style={{ height: "35px" }}>
                  <FontAwesomeIcon
                    icon={faNfcSymbol as IconProp}
                    className="color-white"
                    style={{ fontSize: "35px" }}
                  />
                </div>
                <div className="col-10 mt-2">
                  <span className="sub-title-ml fw-normal color-white pe-5 ">
                    {parse(t(`dlcreview_nfc`))}
                  </span>
                </div>
              </div>
              <div className="col-2  d-flex flex-column align-items-start justify-content-start ">
                <div className="col-12" style={{ height: "35px" }}>
                  <FontAwesomeIcon
                    icon={faQrcode as IconProp}
                    className="color-white"
                    style={{ fontSize: "35px" }}
                  />
                </div>
                <div className="col-10 mt-2">
                  <span className="sub-title-ml fw-normal color-white pe-5 ">
                    {parse(t(`dlcreview_qr`))}
                  </span>
                </div>
              </div>
            </div>
            <div
              className="col-12   d-flex flex-column align-items-center justify-content-end "
              style={{ height: "8vh" }}
            ></div>

            <div
              className={`col-12  d-flex flex-row align-items-center justify-content-center ${styles.animationContainer}`}
            >
              <img
                src="https://d16qu8ed6p45v5.cloudfront.net/dlctechwebsite/businesscard3d.mp4"
                alt="videobusiness"
              />
            </div>
          </div>

          <div className={`${styles.imageContainer}  col-12`}>
            <div className={`${styles.space} `}></div>
            <div className="d-flex flex-row align-items-center justify-content-center col-12 ">
              <div className="d-flex flex-row align-items-center justify-content-between col-9  ">
                <span
                  className={`col-3  text-center cursor-pointer ${
                    styles.materialBtn
                  } ${material.name === "WOOD" && styles.selectedMaterial}`}
                  onClick={() => changeMaterial(wood)}
                >
                  WOOD
                </span>
                <span
                  className={`col-3  text-center cursor-pointer ${
                    styles.materialBtn
                  } ${material.name === "PVC" && styles.selectedMaterial}`}
                  onClick={() => changeMaterial(pvc)}
                >
                  PVC
                </span>
                <span
                  className={`col-3  text-center cursor-pointer ${
                    styles.materialBtn
                  } ${material.name === "METAL" && styles.selectedMaterial}`}
                  onClick={() => changeMaterial(metal)}
                >
                  METAL
                </span>
              </div>
            </div>
            <div
              className={`${styles.image} d-flex  flex-row align-items-center justify-content-center  col-12 `}
            >
              <div className="d-flex flex-row align-items-center justify-content-center col-1  color-white">
                <FontAwesomeIcon
                  className="cursor-pointer"
                  icon={faAngleLeft as IconProp}
                  style={{ fontSize: "4rem" }}
                  onClick={() => downImage()}
                />
              </div>
              <div className={`col-6  ${styles.imageDiv} `}>
                {color.images.map((img, index) => (
                  <>
                    <img
                      className={`${imageN === index ? "d-block" : "d-none"}`}
                      alt="businesscardImg"
                      src={img}
                    />
                  </>
                ))}
              </div>
              <div className="d-flex  cursor-pointer flex-row align-items-center justify-content-center col-1 color-white">
                <FontAwesomeIcon
                  className="cursor-pointer"
                  icon={faAngleRight as IconProp}
                  style={{ fontSize: "4rem" }}
                  onClick={() => upImage()}
                />
              </div>
            </div>

            <div
              className={`d-flex  flex-row align-items-start justify-content-center  col-12`}
            >
              {material?.color.map((colore) => (
                <div
                  className={`col-2 ${
                    color.name !== colore.name
                      ? styles.colorBtn
                      : styles.colorBtnSelected
                  } `}
                  onClick={() => setColor(colore)}
                  style={colore.color ? { backgroundColor: colore.color } : {}}
                >
                  {colore.imageurl && (
                    <img src={colore.imageurl} alt="businesscardColor" />
                  )}
                </div>
              ))}
            </div>
            <div className="col-12  mt-5 d-flex flex-row align-items-center justify-content-center  ">
              <div className="col-8  d-flex flex-row justify-content-between ">
                <span
                  className={`cursor-pointer col-12 sub-title  d-flex flex-row align-items-center justify-content-center ${styles.button}`}
                  onClick={() => handleClick()}
                  style={{ height: "50px", borderRadius: "30px" }}
                >
                  {t(`buynow_btn`)}
                  {/* Customize your product */}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="d-md-flex d-none  flex-row align-items-center justify-content-center text-center mt-10 bg-white  ">
          <span className="col-12 text-center small-title color-black  mt-7 mb-7">
            {t(`businesscard_perche_title`)}
          </span>
        </div>
        <div className={`col-12 bg-white d-md-block d-none pb-7  `}>
          {/* <div className="d-sm-flex d-none border flex-column align-items-center justify-content-start col-12 color-white p-7"> */}
          <Swiper
            className={` p-md-5 p-4  ${styles.swiperContainer}`}
            slidesPerView={3.5}
            spaceBetween={30}
            direction={"horizontal"}
            loop={false}
            grabCursor={true}
            mousewheel={true}
            breakpoints={{
              1024: {
                slidesPerView: 3.2,
                spaceBetween: 30,
              },
              1250: {
                slidesPerView: 3.5,
                spaceBetween: 30,
              },
            }}
          >
            <SwiperSlide
              className={`${styles.efficiencySlide}`}
              onClick={() => setEfficiencyPopUp(!efficiencyPopUp)}
            >
              <div className={`${styles.innovationBackgroundImage}`}>
                <img src={efficiencyImg} alt="businesscardEfficiencyImg" />
              </div>
              <div
                className={` ps-4 pt-4 d-flex flex-row color-white sub-title ${styles.title} `}
              >
                {parse(t(`Business_quadratoni_titoletto_1`))}
                {/* Efficiency */}
              </div>
              <div
                className={` ps-4 pt-4 d-flex flex-row color-white small-title ${styles.text} `}
              >
                {parse(t(`Business_quadratoni_titolo_1`))}
                {/* Networking <br /> Reinvented */}
              </div>
              <div className={` color-black col-12  pe-4  ${styles.buttonRow}`}>
                <span className={` ${styles.button}`}>
                  <FontAwesomeIcon
                    icon={faPlus as IconProp}
                    style={{ fontSize: "2.5rem" }}
                  />
                </span>
              </div>
              {/* </div> */}
            </SwiperSlide>
            <BusinessCardEfficiencyPopUp
              popup={efficiencyPopUp}
              close={() => setEfficiencyPopUp(!efficiencyPopUp)}
            />
            <SwiperSlide
              className={`${styles.moneySlide}`}
              onClick={() => setMoneyPopUp(!moneyPopUp)}
            >
              <div className={`${styles.environmentBackgroundImage}`}>
                <img src={moneyImg} alt="businesscardMoneyImg" />
              </div>
              <div
                className={` ps-4 pt-4 d-flex flex-row color-white sub-title ${styles.title} `}
              >
                {parse(t(`Business_quadratoni_titoletto_2`))}
                {/* Money */}
              </div>
              <div
                className={` ps-4 pt-4 d-flex flex-row color-white small-title ${styles.text} `}
              >
                {parse(t(`Business_quadratoni_titolo_2`))}
                {/* Save <br /> Them */}
              </div>
              <div className={` color-black col-12  pe-4  ${styles.buttonRow}`}>
                <span className={` ${styles.button}`}>
                  <FontAwesomeIcon
                    icon={faPlus as IconProp}
                    style={{ fontSize: "2.5rem" }}
                  />
                </span>
              </div>
              {/* </div> */}
            </SwiperSlide>
            <BusinessCardMoneyPopUp
              popup={moneyPopUp}
              close={() => setMoneyPopUp(!moneyPopUp)}
            />
            <SwiperSlide
              className={` ${styles.environmentSlide}`}
              onClick={() => setEnvironmentPopUp(!environmentPopUp)}
            >
              <div className={`${styles.privacyBackgroundImage}`}>
                <img src={environmentImg} alt="businesscardEnvironmentImg" />
              </div>
              <div
                className={` ps-4 pt-4 d-flex flex-row color-white sub-title ${styles.title} `}
              >
                {parse(t(`Business_quadratoni_titoletto_3`))}
                {/* Environment */}
              </div>
              <div
                className={` ps-4 pt-4 d-flex flex-row color-white small-title ${styles.text} `}
              >
                {parse(t(`Business_quadratoni_titolo_3`))}
                {/* Reduce the <br /> Waste */}
              </div>
              <div className={` color-black col-12  pe-4  ${styles.buttonRow}`}>
                <span className={` ${styles.button}`}>
                  <FontAwesomeIcon
                    icon={faPlus as IconProp}
                    style={{ fontSize: "2.5rem" }}
                  />
                </span>
              </div>
            </SwiperSlide>
            <BusinessCardEnvironmentPopUp
              popup={environmentPopUp}
              close={() => setEnvironmentPopUp(!environmentPopUp)}
            />
            <SwiperSlide
              className={`  ${styles.timeSlide}`}
              onClick={() => setTimePopUp(!timePopUp)}
            >
              <div className={`${styles.customizationBackgroundImage}`}>
                <img src={timeImg} alt="businesscardTimeImg" />
              </div>
              <div
                className={` ps-4 pt-4 d-flex flex-row color-white sub-title ${styles.title} `}
              >
                {parse(t(`Business_quadratoni_titoletto_4`))}
                {/* Time */}
              </div>
              <div
                className={` ps-4 pt-4 d-flex flex-row color-white small-title ${styles.text} `}
              >
                {parse(t(`Business_quadratoni_titolo_4`))}
                {/* Don't
                <br /> Lose it */}
              </div>
              <div className={` color-black col-12  pe-4  ${styles.buttonRow}`}>
                <span className={` ${styles.button}`}>
                  <FontAwesomeIcon
                    icon={faPlus as IconProp}
                    style={{ fontSize: "2.5rem" }}
                  />
                </span>
              </div>
            </SwiperSlide>
            <BusinessCardTimePopUp
              popup={timePopUp}
              close={() => setTimePopUp(!timePopUp)}
            />
          </Swiper>
          {/* </div> */}
        </div>

        <div
          className="d-md-flex  d-none flex-row align-items-start justify-content-around col-12  overflow-hidden"
          style={{ backgroundColor: "white" }}
        >
          <div className="col-12   d-flex flex-column align-items-center justify-content-center  ">
            <div className=" col-12  d-flex flex-row align-items-start  justify-content-start ">
              <div className="d-flex  flex-column align-items-center  justify-content-center   col-12  ">
                {/* <div style={{ height: "150px" }}></div> */}
                <div className="col-12  d-flex flex-column  align-items-start justify-content-start ">
                  <div className="col-12  bg-white  pb-7 d-flex flex-column align-items-center justify-content-start color-black">
                    <span className="small-title  mt-5">
                      {parse(t(`Business_webinterface_titolo`))}
                      {/* Web interface */}
                    </span>
                    <div className="d-flex flex-row align-items-center justify-content-center col-12">
                      <div className="d-flex flex-column col-6  align-items-center justify-content-start ">
                        <span className="sub-title mt-5 mb-5 ">
                          {parse(t(`Business_webinterface_titoletto1`))}
                          {/* Default */}
                        </span>
                        <div className="col-12 d-flex flex-row align-items-center justify-content-center">
                          <div className="col-1  d-flex flex-column align-items-center justify-content-center">
                            <FontAwesomeIcon
                              onClick={() => removeIndexDefault()}
                              icon={faAngleLeft as IconProp}
                              className="cursor-pointer"
                              style={{ fontSize: "3rem" }}
                            />
                          </div>
                          <div
                            className={`col-8  ${styles.webInterfaceImgContainer}`}
                          >
                            <img
                              src={webstandardImages[defaultIndex]}
                              alt="businesscardwebpageStandard"
                            />
                          </div>
                          <div className="col-1  d-flex flex-column align-items-center justify-content-center">
                            <FontAwesomeIcon
                              onClick={() => addIndexDefault()}
                              icon={faAngleRight as IconProp}
                              className="cursor-pointer"
                              style={{ fontSize: "3rem" }}
                            />
                          </div>
                        </div>
                        <div className="d-flex flex-row col-8 mt-5  ">
                          <span className="text-helvetica col-12 text-center ">
                            {parse(t(`Business_webinterface_testo1`))}
                            {/* Scegli tra dark e light mode
                            <br /> ( potrai cambiarla quando vorrai ) */}
                          </span>
                        </div>
                      </div>
                      <div className="d-flex flex-column col-6  align-items-center justify-content-start ">
                        <span className="sub-title mt-5 mb-5">
                          {parse(t(`Business_webinterface_titoletto2`))}
                          {/* Business solutions */}
                        </span>
                        <div className="col-12 d-flex flex-row align-items-center justify-content-center">
                          <div className="col-1  d-flex flex-column align-items-center justify-content-center">
                            <FontAwesomeIcon
                              onClick={() => removeIndexBusiness()}
                              icon={faAngleLeft as IconProp}
                              className="cursor-pointer"
                              style={{ fontSize: "3rem" }}
                            />
                          </div>
                          <div
                            className={`col-8   ${styles.webInterfaceImgContainer}`}
                          >
                            <img
                              src={webbusinessImages[businessIndex]}
                              alt="businesscardwebpageBusiness"
                            />
                          </div>
                          <div className="col-1  d-flex flex-column align-items-center justify-content-center">
                            <FontAwesomeIcon
                              onClick={() => addIndexBusiness()}
                              icon={faAngleRight as IconProp}
                              className="cursor-pointer"
                              style={{ fontSize: "3rem" }}
                            />
                          </div>
                        </div>
                        <div className="d-flex flex-row col-8  mt-5 ">
                          <span className="text-helvetica text-center col-12">
                            {parse(t(`Business_webinterface_testo2`))}
                            {/* Il nostro team di esperti darà vita alla tua web
                            page personalizzata.
                            <br /> Tutto ciò che devi fare è chiedere. */}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="d-md-flex d-none flex-column align-items-center justify-content-center sub-title col-12 color-black bg-white pb-10 pt-10  ">
                    <span className="small-title">
                      {parse(t(`Business_tabella1_titolo`))}
                      {/* Customize Your Solution */}
                    </span>
                    <span className="sub-title fw-bold mt-5 ">
                      {parse(t(`Business_tabella1_sottotitolo`))}
                      {/* Three Plans Available */}
                    </span>

                    <div className="d-flex flex-row align-items-start justify-content-center col-12 mt-10">
                      <div
                        className="d-flex flex-column col-3 text-center  align-items-center justify-content-center"
                        style={{ borderRight: "1px solid black" }}
                      >
                        <span className="light-title-esquare">
                          {parse(t(`Business_tabella1_titoletto1`))}
                          {/* Essential */}
                        </span>
                        <span className="mt-2 color-dark_grey_50 fw-bold text-helvetica">
                          {parse(t(`Business_tabella1_prezzo1`))}
                          {/* From 34.<sup style={{ fontSize: "1rem" }}>99</sup> € */}
                        </span>
                        <span className="mt-5 ">
                          {parse(t(`Business_tabella1_testo1`))}
                          {/* Salta a bordo dell'innovazione */}
                        </span>
                        <div className="d-flex flex-row align-items-center justify-content-center col-12 mt-10">
                          <div
                            className={`col-6  d-flex flex-row align-items-center justify-content-center ${styles.persCardL}`}
                          >
                            <img
                              src={faggioretro}
                              alt="businesscardfaggioretro"
                            />
                          </div>
                          <div
                            className={`col-6  d-flex flex-row align-items-center justify-content-center ${styles.persCardR}`}
                          >
                            <img
                              src={basicfaggio}
                              alt="businesscardfaggiofront"
                            />
                          </div>
                        </div>
                        <div
                          className={`${styles.personalizationCardDiv} mt-7 col-8 `}
                        >
                          <span>
                            <FontAwesomeIcon icon={faIdCard as IconProp} />
                          </span>
                          <span className="fw-bold mt-3  col-12 text-center">
                            Card
                          </span>
                          <span className="mt-3 fw-normal col-10 text-center">
                            {parse(t(`Business_tabella1_testo1_descrizione_1`))}
                            {/* &#8226; Aggiungi il tuo nome */}
                          </span>
                          <span className="mt-2 fw-normal col-10 text-center">
                            {parse(t(`Business_tabella1_testo1_descrizione_2`))}
                            {/* &#8226; Aggiungi il tuo nome */}
                          </span>
                        </div>
                      </div>
                      <div
                        className="d-flex flex-column col-3 text-center  align-items-center justify-content-center"
                        style={{ borderRight: "1px solid black" }}
                      >
                        <span className="light-title-esquare">
                          {parse(t(`Business_tabella1_titoletto2`))}
                          {/* Elite */}
                        </span>
                        <span className="mt-2 color-dark_grey_50 fw-bold text-helvetica">
                          {parse(t(`Business_tabella1_prezzo2`))}
                          {/* From 42.<sup style={{ fontSize: "1rem" }}>99</sup> € */}
                        </span>
                        <span className="mt-5 ">
                          {parse(t(`Business_tabella1_testo2`))}
                          {/* Dai un volto alla tua card */}
                        </span>
                        <div className="d-flex flex-row align-items-center justify-content-center col-12 mt-10">
                          <div
                            className={`col-6  d-flex flex-row align-items-center justify-content-center ${styles.persCardL}`}
                          >
                            <img
                              src={faggioretro}
                              alt="businesscardfaggioretro"
                            />
                          </div>
                          <div
                            className={`col-6  d-flex flex-row align-items-center justify-content-center ${styles.persCardR}`}
                          >
                            <img
                              src={faggiofront}
                              alt="businesscardfaggiofront"
                            />
                          </div>
                        </div>
                        <div
                          className={`${styles.personalizationCardDiv} mt-7 col-8`}
                        >
                          <span>
                            <FontAwesomeIcon icon={faIdCard as IconProp} />
                          </span>
                          <span className="fw-bold mt-3 col-12 text-center">
                            Card
                          </span>

                          <span className="mt-3 fw-normal col-10 text-center">
                            {parse(t(`Business_tabella1_testo2_descrizione_1`))}
                            {/* Aggiungi il tuo nome */}
                          </span>
                          <span className="mt-2 fw-normal col-10 text-center">
                            {parse(t(`Business_tabella1_testo2_descrizione_2`))}
                            {/* Aggiungi il tuo logo */}
                          </span>
                          <span className="mt-2 fw-normal col-10 text-center">
                            {parse(t(`Business_tabella1_testo2_descrizione_3`))}
                            {/* Aggiungi il tuo logo */}
                          </span>
                        </div>
                      </div>
                      <div className="d-flex flex-column col-3 text-center  align-items-center justify-content-center">
                        <span className="light-title-esquare">
                          {parse(t(`Business_tabella1_titoletto3`))}
                          {/* Total */}
                        </span>
                        <span className="mt-2 color-dark_grey_50 fw-bold text-helvetica">
                          {parse(t(`Business_tabella1_prezzo3`))}
                          {/* From 54<sup style={{ fontSize: "1rem" }}>99</sup> € */}
                        </span>
                        <span className="mt-5 ">
                          {parse(t(`Business_tabella1_testo3`))}
                          {/* Come la vuoi tu */}
                        </span>
                        <div className="d-flex flex-row align-items-center justify-content-center col-12 mt-10">
                          <div
                            className={`col-6  d-flex flex-row align-items-center justify-content-center ${styles.persCardL}`}
                          >
                            <img
                              src={faggioQR}
                              alt="businesscardfaggioretroQR"
                            />
                          </div>
                          <div
                            className={`col-6  d-flex flex-row align-items-center justify-content-center ${styles.persCardR}`}
                          >
                            <img
                              src={faggiofront}
                              alt="businesscardfaggiofront"
                            />
                          </div>
                        </div>
                        <div
                          className={`${styles.personalizationCardDiv} mt-7 col-8`}
                        >
                          <span>
                            <FontAwesomeIcon icon={faIdCard as IconProp} />
                          </span>
                          <span className="fw-bold mt-3 ">Card</span>
                          <span className="mt-3 fw-normal ">
                            {parse(t(`Business_tabella1_testo3_descrizione`))}
                            {/* Aggiungi il tuo nome */}
                          </span>
                          {/* <span className="mt-3 fw-normal ">
                            Aggiungi il tuo logo
                          </span> */}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="d-md-flex d-none flex-column align-items-center  justify-content-center sub-title col-12 color-black bg-white  pt-10">
                    {/* <span className="light-title-esquare me-4">
                      <span className="color-dark_grey_50">DLC</span> BUSINESS
                      CARD
                    </span> */}
                    <span className="mt-3 sub-title"> Card Details</span>
                  </div>
                  <div
                    className={`d-none d-md-flex pt-10 pb-10 flex-row align-items-start justify-content-center  col-12 color-black bg-white  ${styles.cardDetails}`}
                  >
                    <div
                      className={`d-flex flex-column align-items-center justify-content-center  col-3 `}
                    >
                      <span
                        className={`light-title-esquare ${styles.cardDetailsTitle}`}
                      >
                        WOOD
                      </span>
                      <span className={` ${styles.cardDetailsDescription}`}>
                        {parse(t(`businesscard_tabba_subtitle_wood`))}
                      </span>
                      <span className={` pt-4 ${styles.cardDetailsImgWood}`}>
                        <img src={bamboofront} alt="businesscardbamboofront" />
                      </span>
                      <span className={` ${styles.cardDetailsParagraph}`}>
                        <span>
                          <img
                            src={hand}
                            style={{ height: "30px" }}
                            alt="businesscardhand"
                          />
                        </span>
                        <span className="mt-3 sub-title">
                          {t(`businesscard_finishes_title`)}
                          {/*  Finishes */}
                        </span>
                        <span className="mt-3 text-center">
                          {parse(t(`businesscard_finishes_text_wood`))}
                        </span>
                      </span>
                      <span className={`mt-7 ${styles.cardDetailsParagraph}`}>
                        <span>
                          <img
                            src={thikness}
                            style={{ height: "30px" }}
                            alt="businesscardthiknessicon"
                          />
                        </span>
                        <span className="mt-3 sub-title">
                          {t(`businesscard_weight_title`)}
                        </span>
                        <span className="mt-3 text-center">
                          {t(`businesscard_weight_text`)}
                        </span>
                      </span>
                      <span className={`mt-7 ${styles.cardDetailsParagraph}`}>
                        <span>
                          <FontAwesomeIcon icon={faRulerCombined as IconProp} />
                        </span>
                        <span className="mt-3 sub-title">
                          {parse(t(`businesscard_dimensions_title`))}{" "}
                        </span>
                        <span className="mt-3 text-center">
                          {parse(t(`businesscard_dimensions_text`))}
                        </span>
                      </span>
                      <span className={`mt-7 ${styles.cardDetailsParagraph}`}>
                        <span>
                          <FontAwesomeIcon icon={faPrint as IconProp} />
                        </span>
                        <span className="mt-3 sub-title">
                          {parse(t(`businesscard_printing_title`))}{" "}
                        </span>
                        <span className="mt-3 text-center">
                          {parse(t(`businesscard_printing_wood`))}
                        </span>
                      </span>
                    </div>
                    <div
                      className={`d-flex flex-column align-items-center justify-content-center  col-3 ${styles.cardDetailsCenter}`}
                    >
                      <span
                        className={`light-title-esquare ${styles.cardDetailsTitle}`}
                      >
                        PVC
                      </span>
                      <span className={`  ${styles.cardDetailsDescription}`}>
                        {parse(t(`businesscard_tabba_subtitle_pvc`))}
                      </span>
                      <span className={`  ${styles.cardDetailsImg} `}>
                        <img src={blackfront} alt="businesscardblackfront" />
                      </span>
                      <span className={` ${styles.cardDetailsParagraph}`}>
                        <span>
                          <img
                            src={hand}
                            style={{ height: "30px" }}
                            alt="businesscardhand"
                          />
                        </span>
                        <span className="mt-3 sub-title">
                          {t(`businesscard_finishes_title`)}
                        </span>
                        <span className="mt-3 text-center">
                          {parse(t(`businesscard_finishes_text_pvc`))}
                        </span>
                      </span>
                      <span className={`mt-7 ${styles.cardDetailsParagraph}`}>
                        <span>
                          <img
                            src={thikness}
                            style={{ height: "30px" }}
                            alt="businesscardthiknessicon"
                          />
                        </span>
                        <span className="mt-3 sub-title">
                          {t(`businesscard_weight_title`)}
                        </span>
                        <span className="mt-3 text-center">
                          {t(`businesscard_weight_text`)}
                        </span>
                      </span>
                      <span className={`mt-7 ${styles.cardDetailsParagraph}`}>
                        <span>
                          <FontAwesomeIcon icon={faRulerCombined as IconProp} />
                        </span>
                        <span className="mt-3 sub-title">
                          {parse(t(`businesscard_dimensions_title`))}
                        </span>
                        <span className="mt-3 text-center">
                          {parse(t(`businesscard_dimensions_text`))}
                        </span>
                      </span>
                      <span className={`mt-7 ${styles.cardDetailsParagraph}`}>
                        <span>
                          <FontAwesomeIcon icon={faPrint as IconProp} />
                        </span>
                        <span className="mt-3 sub-title">
                          {parse(t(`businesscard_printing_title`))}
                        </span>
                        <span className="mt-3 text-center">
                          {parse(t(`businesscard_printing_pvc`))}
                        </span>
                      </span>
                    </div>
                    <div
                      className={`d-flex flex-column align-items-center justify-content-center  col-3 ${styles.cardDetailsRight}`}
                    >
                      <span
                        className={`light-title-esquare ${styles.cardDetailsTitle}`}
                      >
                        METAL
                      </span>
                      <span className={` ${styles.cardDetailsDescription}`}>
                        {parse(t(`businesscard_tabba_subtitle_metal`))}
                      </span>
                      <span className={`  ${styles.cardDetailsImg}`}>
                        <img
                          src={stainlessfront}
                          alt="businesscardstainlessfront"
                        />
                      </span>
                      <span className={` ${styles.cardDetailsParagraph}`}>
                        <span>
                          <img
                            src={hand}
                            style={{ height: "30px" }}
                            alt="businesscardhand"
                          />
                        </span>
                        <span className="mt-3 sub-title">
                          {t(`businesscard_finishes_title`)}
                        </span>
                        <span className="mt-3 text-center">
                          {parse(t(`businesscard_finishes_text_metal`))}
                        </span>
                      </span>
                      <span className={`mt-7 ${styles.cardDetailsParagraph}`}>
                        <span>
                          <img
                            src={thikness}
                            style={{ height: "30px" }}
                            alt="businesscardthiknessicon"
                          />
                        </span>
                        <span className="mt-3 sub-title">
                          {t(`businesscard_weight_title`)}
                        </span>
                        <span className="mt-3 text-center">
                          {t(`businesscard_weight_text`)}
                        </span>
                      </span>
                      <span className={`mt-7 ${styles.cardDetailsParagraph}`}>
                        <span>
                          <FontAwesomeIcon icon={faRulerCombined as IconProp} />
                        </span>
                        <span className="mt-3 sub-title">
                          {parse(t(`businesscard_dimensions_title`))}
                        </span>
                        <span className="mt-3 text-center">
                          {parse(t(`businesscard_dimensions_text`))}
                        </span>
                      </span>
                      <span className={`mt-7 ${styles.cardDetailsParagraph}`}>
                        <span>
                          <FontAwesomeIcon icon={faPrint as IconProp} />
                        </span>
                        <span className="mt-3 sub-title">
                          {parse(t(`businesscard_printing_title`))}
                        </span>
                        <span className="mt-3 text-center">
                          {parse(t(`businesscard_printing_metal`))}
                          {/* Printed in 600 DPI resolution <br />
                          Printable area: 100%
                          <br />
                          (over the edge) */}
                        </span>
                      </span>
                    </div>
                    <div className="d-flex flex-row "></div>
                  </div>

                  <div
                    className={`d-none d-md-flex flex-row align-items-start justify-content-center  col-12 color-black bg-white   pb-7 `}
                  >
                    <div
                      className="d-none d-md-flex flex-column col-10 align-items-center justify-content-center "
                      style={{ borderTop: "1px solid black " }}
                    >
                      <span className="pt-7 light-title fw-bold d-flex flex-row col-12 align-items-center justify-content-center">
                        {parse(t(`Business_preventivo_titolo`))}
                        {/* Richiedi un preventivo per la tua azienda */}
                      </span>

                      <div className="mt-4  d-flex flex-column col-10 align-items-center justify-content-start">
                        <div className="d-flex flex-row align-items-center justify-content-center text-xxl">
                          {parse(t(`Business_preventivo_sottotitolo`))}
                          {/* Compila il form sottostante e verrai contattato prima
                          possibile */}
                        </div>
                        <form
                          onSubmit={handleSubmit(onSubmit)}
                          className="col-12 d-flex flex-row align-items-center justify-content-center "
                        >
                          <div className="col-10 pt-7">
                            <div className="d-flex flex-row align-items-center justify-content-between col-12 ">
                              <div className="d-flex flex-column col-5 align-items-start justify-content-start">
                                <div className="sub-title ps-5 ">
                                  {parse(t(`Business_preventivo_form_titolo1`))}
                                </div>
                                <div
                                  className={`${styles.inputDiv} mt-2 col-12`}
                                >
                                  <input
                                    type="text"
                                    {...register("client_name", {
                                      onChange: (e) =>
                                        setClientName(e.target.value),
                                    })}
                                    value={clientName}
                                    className=" col-12  d-flex ps-5 flex-row align-items-center justify-content-center  text-helvetica"
                                    placeholder={`${parse(
                                      t(`Business_preventivo_form_sottotitolo1`)
                                    )}`}
                                  />
                                </div>
                              </div>
                              <div className="d-flex flex-column col-6 align-items-start justify-content-start">
                                <div className="sub-title ps-5 ">
                                  {parse(t(`Business_preventivo_form_titolo2`))}
                                </div>
                                <div
                                  className={`${styles.inputDiv}  mt-2 col-12`}
                                >
                                  <input
                                    type="text"
                                    {...register("email", {
                                      onChange: (e) => setEmail(e.target.value),
                                    })}
                                    value={email}
                                    className=" col-12  d-flex ps-5 flex-row align-items-center justify-content-center  text-helvetica"
                                    placeholder={`${parse(
                                      t(`Business_preventivo_form_sottotitolo2`)
                                    )}`}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="d-flex flex-row align-items-center justify-content-between col-12 mt-4">
                              <div className="d-flex flex-column col-5 align-items-start justify-content-start">
                                <div className="sub-title ps-5 ">
                                  {parse(t(`Business_preventivo_form_titolo3`))}
                                </div>
                                <div
                                  className={`${styles.inputDiv} mt-2 col-12`}
                                >
                                  <input
                                    type="text"
                                    {...register("job_title", {
                                      onChange: (e) =>
                                        setJobTitle(e.target.value),
                                    })}
                                    value={jobTitle}
                                    className=" col-12  d-flex ps-5 flex-row align-items-center justify-content-center  text-helvetica"
                                    placeholder={`${parse(
                                      t(`Business_preventivo_form_sottotitolo3`)
                                    )}`}
                                  />
                                </div>
                              </div>
                              <div className="d-flex flex-column col-6 align-items-start justify-content-start">
                                <div className="sub-title ps-5 ">
                                  {parse(t(`Business_preventivo_form_titolo4`))}
                                </div>
                                <div
                                  className={`${styles.inputDiv}  mt-2 col-12`}
                                >
                                  <input
                                    type="text"
                                    {...register("company_name", {
                                      onChange: (e) =>
                                        setCompanyName(e.target.value),
                                    })}
                                    value={companyName}
                                    className=" col-12  d-flex ps-5 flex-row align-items-center justify-content-center  text-helvetica"
                                    placeholder={`${parse(
                                      t(`Business_preventivo_form_sottotitolo4`)
                                    )}`}
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="d-flex flex-row align-items-start justify-content-between col-12  mt-4">
                              <div className="d-flex flex-column col-5 align-items-start justify-content-start">
                                <div className="sub-title ps-5 ">
                                  {parse(t(`Business_preventivo_form_titolo5`))}
                                </div>
                                <div
                                  className={`${styles.inputDiv} mt-2 col-12`}
                                  style={{ marginBottom: "5px" }}
                                >
                                  <input
                                    type="text"
                                    {...register("phone_number", {
                                      onChange: (e) => setPhone(e.target.value),
                                    })}
                                    value={phone}
                                    className=" col-12  d-flex ps-5 flex-row align-items-center justify-content-center  text-helvetica"
                                    placeholder={`${parse(
                                      t(`Business_preventivo_form_sottotitolo5`)
                                    )}`}
                                  />
                                </div>
                              </div>
                              <div className="d-flex flex-column col-6 align-items-start justify-content-start ">
                                <div className="sub-title ps-5 ">
                                  {parse(t(`Business_preventivo_form_titolo6`))}
                                </div>
                                <div className="mt-2 col-12">
                                  <DropDown
                                    click={(e) => setRegion(e)}
                                    buttonText={`${parse(
                                      t(`Business_preventivo_form_sottotitolo6`)
                                    )}`}
                                    options={[
                                      { label: "Italy", value: "IT" },
                                      { label: "Europe", value: "EU" },
                                      {
                                        label: "United States / Canada",
                                        value: "USA",
                                      },
                                      {
                                        label: "Australia / New Zeland",
                                        value: "ANZ",
                                      },
                                      { label: "Asia", value: "ASIA" },
                                      { label: "South America", value: "SA" },
                                      { label: "Other", value: "O" },
                                    ]}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="d-flex flex-row align-items-start justify-content-between col-12  mt-4">
                              <div className="d-flex flex-column col-5 align-items-start justify-content-start">
                                <div className="sub-title ps-5 ">
                                  {parse(t(`Business_preventivo_form_titolo7`))}
                                </div>
                                <div className="mt-2 col-12">
                                  <DropDown
                                    click={(e) => setCompanySize(e)}
                                    buttonText={`${parse(
                                      t(`Business_preventivo_form_sottotitolo7`)
                                    )}`}
                                    options={[
                                      { label: "1 - 19", value: "1" },
                                      { label: "20 - 49", value: "2" },
                                      { label: "50 - 99", value: "3" },
                                      { label: "100 - 249", value: "4" },
                                      { label: "250 - 499", value: "5" },
                                      { label: "500 - 999", value: "6" },
                                      { label: "1000+", value: "7" },
                                    ]}
                                  />
                                </div>
                              </div>
                              <div className="d-flex flex-column col-6 align-items-start justify-content-start ">
                                <div className="sub-title ps-5 ">
                                  {parse(t(`Business_preventivo_form_titolo8`))}
                                </div>
                                <div className="mt-2 col-12">
                                  <DropDown
                                    click={(e) => setPotentialUsers(e)}
                                    buttonText={`${parse(
                                      t(`Business_preventivo_form_sottotitolo8`)
                                    )}`}
                                    options={[
                                      { label: "1 - 19", value: "1" },
                                      { label: "20 - 49", value: "2" },
                                      { label: "50 - 99", value: "3" },
                                      { label: "100 - 249", value: "4" },
                                      { label: "250 - 499", value: "5" },
                                      { label: "500 - 999", value: "6" },
                                      { label: "1000+", value: "7" },
                                    ]}
                                  />
                                </div>
                              </div>
                            </div>
                            <div
                              className="d-flex flex-row align-items-center justify-content-start col-12 sub-title mt-4 ps-5"
                              style={{ zIndex: 1 }}
                            >
                              {parse(t(`Business_preventivo_form_titolo9`))}
                            </div>
                            <div className="mt-2">
                              <DropDown
                                click={(e) => setObject(e)}
                                options={[
                                  {
                                    label: `${parse(
                                      t(
                                        `Business_preventivo_form_sottotitolo9_1`
                                      )
                                    )}`,
                                    value: "demo",
                                  },
                                  {
                                    label: `${parse(
                                      t(
                                        `Business_preventivo_form_sottotitolo9_2`
                                      )
                                    )}`,
                                    value: "new order",
                                  },
                                  {
                                    label: `${parse(
                                      t(
                                        `Business_preventivo_form_sottotitolo9_3`
                                      )
                                    )}`,
                                    value: "existing order",
                                  },
                                  {
                                    label: `${parse(
                                      t(
                                        `Business_preventivo_form_sottotitolo9_4`
                                      )
                                    )}`,
                                    value: "partnership",
                                  },
                                  {
                                    label: `${parse(
                                      t(
                                        `Business_preventivo_form_sottotitolo9_5`
                                      )
                                    )}`,
                                    value: "help",
                                  },
                                  {
                                    label: `${parse(
                                      t(
                                        `Business_preventivo_form_sottotitolo9_6`
                                      )
                                    )}`,
                                    value: "other",
                                  },
                                ]}
                                buttonText={`${parse(
                                  t(`Business_preventivo_form_sottotitolo9`)
                                )}`}
                              />
                            </div>
                            <div
                              className="d-flex flex-row align-items-center justify-content-start col-12 sub-title mt-4 ps-5"
                              style={{ zIndex: 1 }}
                            >
                              {parse(t(`Business_preventivo_form_titolo10`))}
                              {/* Your message */}
                            </div>
                            <div className={`${styles.textareaDiv} mt-2`}>
                              <textarea
                                {...register("message", {
                                  onChange: (e) =>
                                    e.target.value.length < 150 &&
                                    setMessage(e.target.value),
                                })}
                                value={message}
                                className=" col-12  d-flex ps-5 flex-row align-items-center justify-content-center pt-4 text-helvetica"
                                placeholder={`${parse(
                                  t(`Business_preventivo_form_sottotitolo10`)
                                )}`}
                              />
                            </div>
                            <div className="d-flex flex-row align-items-start justify-content-between col-12 mt-5 ">
                              <div className="col-1  ">
                                <label
                                  className={`${styles.checkboxContainer}  `}
                                >
                                  <input
                                    type="checkbox"
                                    // checked={privacy}
                                    onClick={() => setPrivacy(!privacy)}
                                  />
                                  <span
                                    className={`${styles.checkmark} `}
                                  ></span>
                                </label>
                              </div>
                              <div
                                className="col-9 pe-3 "
                                style={{ fontSize: "1.2rem" }}
                              >
                                {parse(
                                  t(
                                    `Business_preventivo_testo_privacypolicy_before`
                                  )
                                )}
                                {/* I confirm that i have read the{` `} */}
                                <a
                                  href="/Privacy-Policy"
                                  style={{ color: "black" }}
                                  // className="color-black"
                                >
                                  privacy policy
                                </a>
                                {parse(
                                  t(
                                    `Business_preventivo_testo_privacypolicy_after`
                                  )
                                )}
                                {/* {` `}
                                and agree to the processing of my personal data
                                by the user or his company for the stated
                                purposes. I can revoke this consent at any time. */}
                              </div>
                              <div className=" col-2 d-flex flex-row align-self-end justify-content-end     ">
                                <button
                                  type="submit"
                                  className={`submit cursor-pointer d-flex flex-row align-items-center justify-content-center   ${styles.sendButton}`}
                                  // onClick={() => sendEmail()}
                                >
                                  {t(`contact_us_send_button`)}
                                  {/* Send */}
                                </button>
                              </div>
                            </div>
                            {formError.current === true && (
                              <div className="d-flex flex-row align-items-center justify-content-center col-12 mt-5">
                                {parse(t(`Business_preventivo_success_text`))}
                                {/* Riempi i campi obbligatori per poter inviare la
                                tua richiesta! */}
                              </div>
                            )}
                            {formSuccess.current === true && (
                              <div className="d-flex flex-row align-items-center justify-content-center col-12 mt-5">
                                {parse(t(`Business_preventivo_error_text`))}
                                {/* La tua richiesta è stata inviata. */}
                              </div>
                            )}
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* MOBILE */}
        <div className="bg-black d-flex d-sm-none mt-7 overflow-hidden flex-column">
          <div
            className={`col-12 ps-5    color-light flex-column align-items-start justify-content-start overflow-hidden mb-5 `}
            style={{ backgroundColor: "black" }}
          >
            <span className="title-mob-e color-dark_grey_50 col-12 ">
              {t(`businesscard_title1`)}
            </span>{" "}
            <br />
            <span className="title-mob-e color-white  col-12 ">
              {t(`businesscard_title2`)}
            </span>
          </div>

          <div className={`${styles.imageContainerMob}  d-md-none  p-5   `}>
            <div className="d-flex flex-row align-items-center justify-content-center col-12  ">
              <div className="d-flex flex-row align-items-center justify-content-between col-12 ">
                <span
                  className={`col-3  text-center cursor-pointer ${
                    styles.materialBtnMob
                  } ${material.name === "WOOD" && styles.selectedMaterialMob}`}
                  onClick={() => changeMaterial(wood)}
                >
                  WOOD
                </span>
                <span
                  className={`col-3  text-center cursor-pointer ${
                    styles.materialBtnMob
                  } ${material.name === "PVC" && styles.selectedMaterialMob}`}
                  onClick={() => changeMaterial(pvc)}
                >
                  PVC
                </span>
                <span
                  className={`col-3  text-center cursor-pointer ${
                    styles.materialBtnMob
                  } ${material.name === "METAL" && styles.selectedMaterialMob}`}
                  onClick={() => changeMaterial(metal)}
                >
                  METAL
                </span>
              </div>
            </div>
            <div
              className={`${styles.imageMob} d-flex  flex-row align-items-center justify-content-between col-12 `}
            >
              <div className="d-flex flex-row align-items-center justify-content-center col-1 color-white">
                <FontAwesomeIcon
                  className="cursor-pointer"
                  icon={faAngleLeft as IconProp}
                  style={{ fontSize: "4rem" }}
                  onClick={() => downImage()}
                />
              </div>
              <div className={`col-10  ${styles.imageDivMob} `}>
                {color.images.map((img, index) => (
                  <img
                    className={`${imageN === index ? "d-block" : "d-none"}`}
                    src={img}
                  />
                ))}
              </div>
              <div className="d-flex cursor-pointer flex-row align-items-center justify-content-center col-1 color-white">
                <FontAwesomeIcon
                  className="cursor-pointer"
                  icon={faAngleRight as IconProp}
                  style={{ fontSize: "4rem" }}
                  onClick={() => upImage()}
                />
              </div>
            </div>

            <div
              className={`d-flex  flex-row align-items-start justify-content-center  col-12`}
            >
              {material?.color.map((colore) => (
                <div
                  className={`col-2 ${
                    color.name !== colore.name
                      ? styles.colorBtnMob
                      : styles.colorBtnMobSelected
                  }`}
                  style={color.color ? { backgroundColor: color.color } : {}}
                  onClick={() => setColor(colore)}
                >
                  {color.imageurl && (
                    <img src={colore.imageurl} alt="businesscardcolorpicker" />
                  )}
                </div>
              ))}
            </div>
          </div>

          <div className="col-12   ps-5 pe-5 pb-5  d-flex flex-row align-items-start justify-content-start   text-center  bg-black color-white d-md-none">
            <div className="col-4   d-flex flex-column align-items-start justify-content-start ">
              <div className="col-12 ">
                <img
                  src={smile}
                  alt="businesscardsmile"
                  style={{ maxHeight: "35px", minHeight: "35px" }}
                />
              </div>
              <div className="col-12 mt-2">
                <span className="sub-title-mob fw-normal color-white">
                  {parse(t(`dlcreview_ecofriendly`))}
                </span>
              </div>
            </div>
            <div className="col-4    d-flex flex-column align-items-start justify-content-start ">
              <div className="col-12">
                <FontAwesomeIcon
                  icon={faNfcSymbol as IconProp}
                  className="color-white"
                  style={{ fontSize: "35px" }}
                />
              </div>
              <div className="col-12 mt-2">
                <span className="sub-title-mob fw-normal color-white">
                  {parse(t(`dlcmenu_nfc`))}
                </span>
              </div>
            </div>
            <div className="col-4   d-flex flex-column align-items-start justify-content-start ">
              <div className="col-12">
                <FontAwesomeIcon
                  icon={faQrcode as IconProp}
                  className="color-white"
                  style={{ fontSize: "35px" }}
                />
              </div>
              <div className="col-12 mt-2">
                <span className="sub-title-mob fw-normal color-white">
                  {parse(t(`dlcmenu_qr`))}
                </span>
              </div>
            </div>
          </div>
          <div className="col-12  d-md-none d-flex flex-row align-items-center justify-content-start bg-black pt-3 flex-wrap p-5">
            <div className="d-flex mt-3 flex-column align-items-center justify-content-center col-12 ">
              <div
                className={` col-9  ${styles.buttonMobile} `}
                onClick={() => handleCustomizeMobile()}
              >
                {t(`dlcmenu_customize_button`)}
              </div>
              {/* <div
                className={` col-9 mt-3 ${styles.buttonMobile} `}
                onClick={() => handleClickMobile()}
              >
                {t(`dlcmenu_info_button`)}
              </div> */}
            </div>
          </div>

          <div className="d-flex d-md-none flex-column align-items-center justify-content-start  pt-7  bg-black color-white fw-bold">
            <div
              className={`${styles.animationContainer}  d-flex flex-row align-items-center justify-content-center`}
            >
              <img
                src="https://d16qu8ed6p45v5.cloudfront.net/dlctechwebsite/businesscard3d.mp4"
                alt="businesscardanimation"
              />
            </div>

            <div className="d-flex flex-row align-items-center justify-content-center col-12 mt-10 bg-white color-black p-5">
              <span className="col-12 small-title ">
                {parse(t(`businesscard_perche_title`))}
              </span>
            </div>
            <div className="col-12 bg-white ">
              <Swiper
                className=" p-md-5 p-4  mb-10 "
                slidesPerView={"auto"}
                spaceBetween={30}
                breakpoints={{
                  300: {
                    slidesPerView: 1.3,
                    spaceBetween: 30,
                  },
                  430: {
                    slidesPerView: 1.5,
                    spaceBetween: 30,
                  },
                  600: {
                    slidesPerView: 2.2,
                    spaceBetween: 30,
                  },
                  // 1024: {
                  //   slidesPerView: 3.5,
                  //   spaceBetween: 30,
                  // },
                }}
              >
                <SwiperSlide
                  className={`${styles.efficiencySlide}`}
                  onClick={() => setEfficiencyPopUp(!efficiencyPopUp)}
                >
                  <div className={`${styles.innovationBackgroundImage}`}>
                    <img src={efficiencyImg} alt="businesscardefficiencyImg" />
                  </div>
                  <div
                    className={` ps-4 pt-4 d-flex flex-row color-white sub-title ${styles.title} `}
                  >
                    {parse(t(`Business_quadratoni_titoletto_1`))}
                    {/* Efficiency */}
                  </div>
                  <div
                    className={` ps-4 pt-4 d-flex flex-row color-white small-title ${styles.text} `}
                  >
                    {parse(t(`Business_quadratoni_titolo_1`))}
                    {/* Networking <br /> Reinvented */}
                  </div>
                  <div
                    className={` color-black col-12  pe-4  ${styles.buttonRow}`}
                  >
                    <span className={` ${styles.button}`}>
                      <FontAwesomeIcon
                        icon={faPlus as IconProp}
                        style={{ fontSize: "2.5rem" }}
                      />
                    </span>
                  </div>
                  {/* </div> */}
                </SwiperSlide>
                <BusinessCardEfficiencyPopUp
                  popup={efficiencyPopUp}
                  close={() => setEfficiencyPopUp(!efficiencyPopUp)}
                />
                <SwiperSlide
                  className={`${styles.moneySlide}`}
                  onClick={() => setMoneyPopUp(!moneyPopUp)}
                >
                  <div className={`${styles.environmentBackgroundImage}`}>
                    <img src={moneyImg} alt="businesscardMoneyImg" />
                  </div>
                  <div
                    className={` ps-4 pt-4 d-flex flex-row color-white sub-title ${styles.title} `}
                  >
                    {parse(t(`Business_quadratoni_titoletto_2`))}
                    {/* Money */}
                  </div>
                  <div
                    className={` ps-4 pt-4 d-flex flex-row color-white small-title ${styles.text} `}
                  >
                    {parse(t(`Business_quadratoni_titolo_2`))}
                    {/* Save <br /> Them */}
                  </div>
                  <div
                    className={` color-black col-12  pe-4  ${styles.buttonRow}`}
                  >
                    <span className={` ${styles.button}`}>
                      <FontAwesomeIcon
                        icon={faPlus as IconProp}
                        style={{ fontSize: "2.5rem" }}
                      />
                    </span>
                  </div>
                  {/* </div> */}
                </SwiperSlide>
                <BusinessCardMoneyPopUp
                  popup={moneyPopUp}
                  close={() => setMoneyPopUp(!moneyPopUp)}
                />
                <SwiperSlide
                  className={` ${styles.environmentSlide}`}
                  onClick={() => setEnvironmentPopUp(!environmentPopUp)}
                >
                  <div className={`${styles.privacyBackgroundImage}`}>
                    <img
                      src={environmentImg}
                      alt="businesscardEnvironmentImg"
                    />
                  </div>
                  <div
                    className={` ps-4 pt-4 d-flex flex-row color-white sub-title ${styles.title} `}
                  >
                    {parse(t(`Business_quadratoni_titoletto_3`))}
                    {/* Environment */}
                  </div>
                  <div
                    className={` ps-4 pt-4 d-flex flex-row color-white small-title ${styles.text} `}
                  >
                    {parse(t(`Business_quadratoni_titolo_3`))}
                    {/* Reduce the <br /> Waste */}
                  </div>
                  <div
                    className={` color-black col-12  pe-4  ${styles.buttonRow}`}
                  >
                    <span className={` ${styles.button}`}>
                      <FontAwesomeIcon
                        icon={faPlus as IconProp}
                        style={{ fontSize: "2.5rem" }}
                      />
                    </span>
                  </div>
                </SwiperSlide>
                <BusinessCardEnvironmentPopUp
                  popup={environmentPopUp}
                  close={() => setEnvironmentPopUp(!environmentPopUp)}
                />
                <SwiperSlide
                  className={`  ${styles.timeSlide}`}
                  onClick={() => setTimePopUp(!timePopUp)}
                >
                  <div className={`${styles.customizationBackgroundImage}`}>
                    <img src={timeImg} alt="businesscardTimeImg" />
                  </div>
                  <div
                    className={` ps-4 pt-4 d-flex flex-row color-white sub-title ${styles.title} `}
                  >
                    {parse(t(`Business_quadratoni_titoletto_4`))}
                    {/* Time */}
                  </div>
                  <div
                    className={` ps-4 pt-4 d-flex flex-row color-white small-title ${styles.text} `}
                  >
                    {parse(t(`Business_quadratoni_titolo_4`))}
                    {/* Don't
                    <br /> Lose it */}
                  </div>
                  <div
                    className={` color-black col-12  pe-4  ${styles.buttonRow}`}
                  >
                    <span className={` ${styles.button}`}>
                      <FontAwesomeIcon
                        icon={faPlus as IconProp}
                        style={{ fontSize: "2.5rem" }}
                      />
                    </span>
                  </div>
                </SwiperSlide>
                <BusinessCardTimePopUp
                  popup={timePopUp}
                  close={() => setTimePopUp(!timePopUp)}
                />
              </Swiper>
              {/* </div> */}
            </div>
            <div className="bg-white color-black ">
              <div className="d-flex flex-row col-12 mt-7 align-items-center justify-content-center small-title">
                {parse(t(`Business_webinterface_titolo`))}
              </div>
              <span className="d-flex flex-row align-items-center justify-content-center col-12 mt-5 sub-title">
                {parse(t(`Business_webinterface_titoletto1`))}
              </span>
              <span className="d-flex flex-row align-items-center justify-content-center col-12 mt-3 text-center text px-4">
                {parse(t(`Business_webinterface_testo1`))}
              </span>
              <div className="d-flex flex-row align-items-center justify-content-center col-12 p-5 ">
                <div className="col-1  text-center">
                  <FontAwesomeIcon
                    icon={faAngleLeft as IconProp}
                    style={{ fontSize: "2.5rem" }}
                    onClick={() => removeIndexDefault()}
                  />
                </div>
                <div
                  className={`col-10 d-flex flex-row align-items-center justify-content-center ${styles.webInterfaceImgContainer}`}
                >
                  <img
                    src={webstandardImages[defaultIndex]}
                    alt="businesscardwebpageStandard"
                  />
                </div>
                <div className="col-1 text-center">
                  <FontAwesomeIcon
                    icon={faAngleRight as IconProp}
                    style={{ fontSize: "2.5rem" }}
                    onClick={() => addIndexDefault()}
                  />
                </div>
              </div>
              <span className="d-flex flex-row align-items-center justify-content-center col-12 mt-5 sub-title ">
                {parse(t(`Business_webinterface_titoletto2`))}
              </span>
              <span className="d-flex flex-row align-items-center justify-content-center col-12 mt-3 text-center px-4 text">
                {parse(t(`Business_webinterface_testo2`))}
              </span>
              <div className="d-flex flex-row align-items-center justify-content-center col-12 p-5 ">
                <div className="col-1  text-center">
                  <FontAwesomeIcon
                    icon={faAngleLeft as IconProp}
                    style={{ fontSize: "2.5rem" }}
                    onClick={() => removeIndexBusiness()}
                  />
                </div>
                <div
                  className={`col-10 d-flex flex-row align-items-center justify-content-center ${styles.webInterfaceImgContainer}`}
                >
                  <img
                    src={webbusinessImages[businessIndex]}
                    alt="businesscardwebpageBusiness"
                  />
                </div>
                <div className="col-1 text-center">
                  <FontAwesomeIcon
                    icon={faAngleRight as IconProp}
                    style={{ fontSize: "2.5rem" }}
                    onClick={() => addIndexBusiness()}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="p-5 d-md-none d-flex flex-column align-items-center bg-white justify-content-center sub-title-mob col-12 color-black ">
            <span
              className="small-title text-center"
              style={{ lineHeight: "1.1" }}
            >
              {parse(t(`Business_tabella1_titolo`))}
              {/* Customize Your Solution */}
            </span>
            <span className="sub-title fw-bold mt-3">
              {parse(t(`Business_tabella1_sottotitolo`))}
            </span>

            <div className="d-flex flex-column align-items-start justify-content-center col-12 mt-7">
              <div className="d-flex flex-column col-12 text-center  align-items-center justify-content-center">
                <div
                  className="d-flex flex-row align-items-center justify-content-aroud col-12  "
                  onClick={() => setEssentialOpen(!essentialOpen)}
                >
                  <span className="light-title-esquare text-start col-11 d-flex flex-row align-items-center justify-content-between ">
                    {parse(t(`Business_tabella1_titoletto1`))}
                    <span className="text color-dark_grey_50 me-5">
                      {parse(t(`Business_tabella1_prezzo1`))}
                    </span>
                    {/* Essential */}
                  </span>
                  <span className="col-1">
                    <FontAwesomeIcon
                      icon={
                        (essentialOpen ? faAngleUp : faAngleDown) as IconProp
                      }
                    />
                  </span>
                </div>
                {essentialOpen && (
                  <>
                    <span className="mt-5 ">
                      {parse(t(`Business_tabella1_testo1`))}
                      {/* Salta a bordo dell'innovazione */}
                    </span>
                    <div className="d-flex flex-row align-items-center justify-content-center col-12 mt-10">
                      <div
                        className={`col-6  d-flex flex-row align-items-center justify-content-center ${styles.persCardL}`}
                      >
                        <img src={faggioretro} alt="businesscardfaggioretro" />
                      </div>
                      <div
                        className={`col-6  d-flex flex-row align-items-center justify-content-center ${styles.persCardR}`}
                      >
                        <img src={basicfaggio} alt="businesscardfaggiofront" />
                      </div>
                    </div>
                    <div
                      className={`${styles.personalizationCardDiv} mt-7 col-8`}
                    >
                      <span>
                        <FontAwesomeIcon icon={faIdCard as IconProp} />
                      </span>
                      <span className="fw-bold mt-3  col-12">Card</span>
                      <span className="mt-3 fw-normal col-12 text-center">
                        {parse(t(`Business_tabella1_testo1_descrizione_1`))}
                        {/* Aggiungi il tuo nome */}
                      </span>
                      <span className="mt-2 fw-normal col-12 text-center">
                        {parse(t(`Business_tabella1_testo1_descrizione_2`))}
                        {/* Aggiungi il tuo nome */}
                      </span>
                    </div>
                  </>
                )}
              </div>
              <div className="d-flex flex-column col-12 text-center  align-items-center justify-content-center mt-7">
                <div
                  className="d-flex flex-row align-items-center justify-content-aroud col-12  "
                  onClick={() => setNecessaryOpen(!necessaryOpen)}
                >
                  <span className="light-title-esquare text-start col-11 d-flex flex-row align-items-center justify-content-between">
                    {parse(t(`Business_tabella1_titoletto2`))}
                    <span className="text color-dark_grey_50 me-5">
                      {parse(t(`Business_tabella1_prezzo2`))}
                    </span>
                  </span>
                  <span className="col-1">
                    <FontAwesomeIcon
                      icon={
                        (necessaryOpen ? faAngleUp : faAngleDown) as IconProp
                      }
                    />
                  </span>
                </div>
                {necessaryOpen && (
                  <>
                    <span className="mt-7 ">
                      {parse(t(`Business_tabella1_testo2`))}
                      {/* Dai un volto all'innovazione */}
                    </span>
                    <div className="d-flex flex-row align-items-center justify-content-center col-12 mt-10">
                      <div
                        className={`col-6  d-flex flex-row align-items-center justify-content-center ${styles.persCardL}`}
                      >
                        <img src={faggioretro} alt="businesscardfaggioretro" />
                      </div>
                      <div
                        className={`col-6  d-flex flex-row align-items-center justify-content-center ${styles.persCardR}`}
                      >
                        <img src={faggiofront} alt="businesscardfaggiofront" />
                      </div>
                    </div>
                    <div
                      className={`${styles.personalizationCardDiv} mt-7 col-8`}
                    >
                      <span>
                        <FontAwesomeIcon icon={faIdCard as IconProp} />
                      </span>
                      <span className="fw-bold mt-3 ">Card</span>
                      <span className="mt-3 fw-normal col-12   text-center">
                        {parse(t(`Business_tabella1_testo2_descrizione_1`))}
                        {/* Aggiungi il tuo nome */}
                      </span>
                      <span className="mt-2 fw-normal col-12  text-center">
                        {parse(t(`Business_tabella1_testo2_descrizione_2`))}
                        {/* Aggiungi il tuo logo */}
                      </span>
                      <span className="mt-2 fw-normal col-12  text-center">
                        {parse(t(`Business_tabella1_testo2_descrizione_3`))}
                        {/* Aggiungi il tuo logo */}
                      </span>
                    </div>
                  </>
                )}
              </div>
              <div className="d-flex flex-column col-12 text-center  align-items-center justify-content-center mt-7">
                <div
                  className="d-flex flex-row align-items-center justify-content-aroud col-12  "
                  onClick={() => setTotalOpen(!totalOpen)}
                >
                  <span className="light-title-esquare text-start col-11 d-flex flex-row align-items-center justify-content-between">
                    {parse(t(`Business_tabella1_titoletto3`))}
                    <span className="text color-dark_grey_50 me-5">
                      {parse(t(`Business_tabella1_prezzo3`))}
                    </span>
                  </span>
                  <span className="col-1">
                    <FontAwesomeIcon
                      icon={(totalOpen ? faAngleUp : faAngleDown) as IconProp}
                    />
                  </span>
                </div>
                {totalOpen && (
                  <>
                    <span className="mt-7 ">
                      {parse(t(`Business_tabella1_testo3`))}
                      {/* Consacrati nell'innovazione */}
                    </span>
                    <div className="d-flex flex-row align-items-center justify-content-center col-12 mt-10">
                      <div
                        className={`col-6  d-flex flex-row align-items-center justify-content-center ${styles.persCardL}`}
                      >
                        <img src={faggioQR} alt="businesscardfaggioretroQR" />
                      </div>
                      <div
                        className={`col-6  d-flex flex-row align-items-center justify-content-center ${styles.persCardR}`}
                      >
                        <img src={faggiofront} alt="businesscardfaggiofront" />
                      </div>
                    </div>
                    <div
                      className={`${styles.personalizationCardDiv} mt-7 col-8`}
                    >
                      <span>
                        <FontAwesomeIcon icon={faIdCard as IconProp} />
                      </span>
                      <span className="fw-bold mt-3 ">Card </span>

                      <span className="mt-3 fw-normal col-12 text-center">
                        {/* Aggiungi il tuo logo */}
                        {parse(t(`Business_tabella1_testo3_descrizione`))}
                      </span>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
        {/* <div
            ref={refMobile}
            className="col-12  d-md-none d-flex flex-row align-items-center justify-content-center bg-black mt-5 flex-wrap mb-5"
          >
            <div
              className={` col-7 mt-3 ${styles.buttonMobile} `}
              onClick={() => redirect("/Contact-us")}
            >
              {t(`dlcmenu_quote_button`)}
            </div>
          </div> */}
        <div className="d-flex d-md-none flex-column align-items-center justify-content-center sub-title col-12 color-black bg-white  pt-7">
          <span className="light-title-esquare me-4">
            {/* <span className="color-dark_grey_50">DLC</span> BUSINESS CARD */}
          </span>
          <span className="mt-3 sub-title"> Card Details</span>
        </div>
        <div
          className={`d-md-none d-flex flex-column align-items-start justify-content-start pb-5 pt-7 col-12 color-black bg-white  ${styles.cardDetails}`}
        >
          <div
            className={`d-flex flex-column align-items-center justify-content-center  col-12 ${styles.cardDetailsLeft}`}
          >
            <div
              className="d-flex flex-row align-items-center justify-content-around col-12 "
              onClick={() => setWoodOpen(!woodOpen)}
            >
              <span
                className={`light-title-esquare col-6 ${styles.cardDetailsTitle}`}
              >
                WOOD
              </span>
              <span className="col-1">
                <FontAwesomeIcon
                  icon={(woodOpen ? faAngleUp : faAngleDown) as IconProp}
                />
              </span>
            </div>
            {woodOpen && (
              <>
                <span className={` text-center mt-5`}>
                  {parse(t(`businesscard_tabba_subtitle_wood`))}
                  {/* The conscious choice.
                    <br /> Smooth - Light - Eco-friendly */}
                </span>
                <span className={`  ${styles.cardDetailsImgWood}`}>
                  <img src={faggioretro} alt="businesscardfaggioretro" />
                </span>
                <span className={` ${styles.cardDetailsParagraph}`}>
                  <span>
                    <img
                      src={hand}
                      style={{ height: "30px" }}
                      alt="businesscardhand"
                    />
                  </span>
                  <span className="mt-3 sub-title">
                    {t(`businesscard_finishes_title`)}
                    {/* Finishes */}
                  </span>
                  <span className="mt-3 text-center">
                    {parse(t(`businesscard_finishes_text_wood`))}
                    {/* Polished finish <br />
                      Available in Bamboo, Beech and Cherry */}
                  </span>
                </span>
                <span className={`mt-7 ${styles.cardDetailsParagraph}`}>
                  <span>
                    <img
                      src={thikness}
                      style={{ height: "30px" }}
                      alt="businesscardthiknessIcon"
                    />
                  </span>
                  <span className="mt-3 sub-title">
                    {t(`businesscard_weight_title`)}
                    {/* Weight & Thickness */}
                  </span>
                  <span className="mt-3 text-center">
                    {t(`businesscard_weight_text`)}
                    {/* 0.8mm thick */}
                  </span>
                </span>
                <span className={`mt-7 ${styles.cardDetailsParagraph}`}>
                  <span>
                    <FontAwesomeIcon icon={faRulerCombined as IconProp} />
                  </span>
                  <span className="mt-3 sub-title">
                    {" "}
                    {parse(t(`businesscard_dimensions_title`))}{" "}
                  </span>
                  <span className="mt-3 text-center">
                    {parse(t(`businesscard_dimensions_text`))}
                  </span>
                </span>
                <span className={`mt-7 ${styles.cardDetailsParagraph}`}>
                  <span>
                    <FontAwesomeIcon icon={faPrint as IconProp} />
                  </span>
                  <span className="mt-3 sub-title">
                    {parse(t(`businesscard_printing_title`))}{" "}
                  </span>
                  <span className="mt-3 text-center">
                    {parse(t(`businesscard_printing_wood`))}
                  </span>
                </span>
              </>
            )}
          </div>
          <div
            className={`d-flex flex-column align-items-center justify-content-center  col-12 ${styles.cardDetailsCenter}`}
          >
            <div
              className="d-flex flex-row align-items-center justify-content-around col-12 "
              onClick={() => setPvcOpen(!pvcOpen)}
            >
              <span
                className={`light-title-esquare col-6 ${styles.cardDetailsTitle}`}
              >
                PVC
              </span>
              <span className="col-1">
                <FontAwesomeIcon
                  icon={(pvcOpen ? faAngleUp : faAngleDown) as IconProp}
                />
              </span>
            </div>
            {pvcOpen && (
              <>
                <span className={`  mt-5 text-center`}>
                  {parse(t(`businesscard_tabba_subtitle_pvc`))}
                </span>
                <span className={`  ${styles.cardDetailsImg}`}>
                  <img src={blackretro} alt="businesscardblackretro" />
                </span>
                <span className={` ${styles.cardDetailsParagraph}`}>
                  <span>
                    <img
                      src={hand}
                      style={{ height: "30px" }}
                      alt="businesscardhand"
                    />
                  </span>
                  <span className="mt-3 sub-title">
                    {parse(t(`businesscard_finishes_title`))}
                  </span>
                  <span className="mt-3 text-center">
                    {parse(t(`businesscard_finishes_text_pvc`))}
                  </span>
                </span>
                <span className={`mt-7 ${styles.cardDetailsParagraph}`}>
                  <span>
                    <img
                      src={thikness}
                      style={{ height: "30px" }}
                      alt="businesscardthiknessIcon"
                    />
                  </span>
                  <span className="mt-3 sub-title">
                    {t(`businesscard_weight_title`)}
                  </span>
                  <span className="mt-3 text-center">
                    {t(`businesscard_weight_text`)}
                  </span>
                </span>
                <span className={`mt-7 ${styles.cardDetailsParagraph}`}>
                  <span>
                    <FontAwesomeIcon icon={faRulerCombined as IconProp} />
                  </span>
                  <span className="mt-3 sub-title">
                    {parse(t(`businesscard_dimensions_title`))}{" "}
                  </span>
                  <span className="mt-3 text-center">
                    {parse(t(`businesscard_dimensions_text`))}
                  </span>
                </span>
                <span className={`mt-7 ${styles.cardDetailsParagraph}`}>
                  <span>
                    <FontAwesomeIcon icon={faPrint as IconProp} />
                  </span>
                  <span className="mt-3 sub-title">
                    {parse(t(`businesscard_printing_title`))}{" "}
                  </span>
                  <span className="mt-3 text-center">
                    {parse(t(`businesscard_printing_pvc`))}
                  </span>
                </span>
              </>
            )}
          </div>
          <div
            className={`d-flex flex-column align-items-center justify-content-center  col-12 ${styles.cardDetailsRight}`}
          >
            <div
              className="d-flex flex-row align-items-center justify-content-around col-12 "
              onClick={() => setMetalOpen(!metalOpen)}
            >
              <span
                className={`light-title-esquare col-6 ${styles.cardDetailsTitle}`}
              >
                METAL
              </span>
              <span className="col-1">
                <FontAwesomeIcon
                  icon={(metalOpen ? faAngleUp : faAngleDown) as IconProp}
                />
              </span>
            </div>
            {metalOpen && (
              <>
                <span className={` text-center mt-5`}>
                  {parse(t(`businesscard_tabba_subtitle_metal`))}
                </span>
                <span className={`  ${styles.cardDetailsImg}`}>
                  <img src={stainlessretro} alt="businesscardstainlessRetro" />
                </span>
                <span className={` ${styles.cardDetailsParagraph}`}>
                  <span>
                    <img
                      src={hand}
                      style={{ height: "30px" }}
                      alt="businesscardhand"
                    />
                  </span>
                  <span className="mt-3 sub-title">
                    {parse(t(`businesscard_finishes_title`))}
                  </span>
                  <span className="mt-3 text-center">
                    {parse(t(`businesscard_finishes_text_metal`))}
                  </span>
                </span>
                <span className={`mt-7 ${styles.cardDetailsParagraph}`}>
                  <span>
                    <img
                      src={thikness}
                      style={{ height: "30px" }}
                      alt="businesscardthiknessIcon"
                    />
                  </span>
                  <span className="mt-3 sub-title">
                    {t(`businesscard_weight_title`)}
                  </span>
                  <span className="mt-3 text-center">
                    {t(`businesscard_weight_text`)}
                  </span>
                </span>
                <span className={`mt-7 ${styles.cardDetailsParagraph}`}>
                  <span>
                    <FontAwesomeIcon icon={faRulerCombined as IconProp} />
                  </span>
                  <span className="mt-3 sub-title">
                    {parse(t(`businesscard_dimensions_title`))}{" "}
                  </span>
                  <span className="mt-3 text-center">
                    {parse(t(`businesscard_dimensions_text`))}
                  </span>
                </span>
                <span className={`mt-7 ${styles.cardDetailsParagraph}`}>
                  <span>
                    <FontAwesomeIcon icon={faPrint as IconProp} />
                  </span>
                  <span className="mt-3 sub-title">
                    {parse(t(`businesscard_printing_title`))}{" "}
                  </span>
                  <span className="mt-3 text-center">
                    {parse(t(`businesscard_printing_metal`))}
                    {/* Printed in 600 DPI resolution <br />
                      Printable area: 100%
                      <br />
                      (over the edge) */}
                  </span>
                </span>
              </>
            )}
          </div>

          <div className="d-flex flex-column align-items-center justify-content-center p-5   bg-white">
            <div
              className="d-flex flex-row align-items-center justify-content-center bg-white col-12"
              style={{ borderTop: "1px solid black " }}
            >
              <span className="sub-title text-center mt-4">
                {parse(t(`Business_preventivo_titolo`))}
                {/* Richiedi un preventivo
                <br /> per la tua azienda */}
              </span>
            </div>
            <div className="d-flex flex-row align-items-center justify-content-center text-xxl col-12 text-center mt-3">
              {parse(t(`Business_preventivo_sottotitolo`))}
              {/* Compila il form sottostante <br /> verrai contattato prima
              possibile */}
            </div>
            <form
              onSubmit={handleSubmit(onSubmit)}
              className="col-12 d-flex flex-row align-items-center justify-content-center "
            >
              <div className="col-10 pt-5">
                <div className="d-flex flex-row align-items-center justify-content-start col-12 ">
                  <div className="d-flex flex-column col-12 align-items-start justify-content-start">
                    <div className="sub-title ps-5 ">
                      {parse(t(`Business_preventivo_form_titolo1`))}
                      {/* Your full name * */}
                    </div>
                    <div className={`${styles.inputDiv} mt-2 col-12`}>
                      <input
                        type="text"
                        {...register("client_name", {
                          onChange: (e) => setClientName(e.target.value),
                        })}
                        value={clientName}
                        className=" col-12  d-flex ps-5 flex-row align-items-center justify-content-center  text-helvetica"
                        placeholder={`${parse(
                          t(`Business_preventivo_form_sottotitolo1`)
                        )}`}
                      />
                    </div>
                  </div>
                </div>
                <div className="d-flex flex-row align-items-center justify-content-start col-12 mt-4">
                  <div className="d-flex flex-column col-12 align-items-start justify-content-start">
                    <div className="sub-title ps-5 ">
                      {parse(t(`Business_preventivo_form_titolo2`))}
                      {/* Your email address * */}
                    </div>
                    <div className={`${styles.inputDiv}  mt-2 col-12`}>
                      <input
                        type="text"
                        {...register("email", {
                          onChange: (e) => setEmail(e.target.value),
                        })}
                        value={email}
                        className=" col-12  d-flex ps-5 flex-row align-items-center justify-content-center  text-helvetica"
                        placeholder={`${parse(
                          t(`Business_preventivo_form_sottotitolo2`)
                        )}`}
                      />
                    </div>
                  </div>
                </div>
                <div className="d-flex flex-row align-items-center justify-content-start col-12 mt-4">
                  <div className="d-flex flex-column col-12 align-items-start justify-content-start">
                    <div className="sub-title ps-5 ">
                      {parse(t(`Business_preventivo_form_titolo3`))}
                      {/* Job Title * */}
                    </div>
                    <div className={`${styles.inputDiv} mt-2 col-12`}>
                      <input
                        type="text"
                        {...register("job_title", {
                          onChange: (e) => setJobTitle(e.target.value),
                        })}
                        value={jobTitle}
                        className=" col-12  d-flex ps-5 flex-row align-items-center justify-content-center  text-helvetica"
                        placeholder={`${parse(
                          t(`Business_preventivo_form_sottotitolo3`)
                        )}`}
                      />
                    </div>
                  </div>
                </div>
                <div className="d-flex flex-row align-items-center justify-content-start col-12 mt-4 ">
                  <div className="d-flex flex-column col-12 align-items-start justify-content-start">
                    <div className="sub-title ps-5 ">
                      {parse(t(`Business_preventivo_form_titolo4`))}
                      {/* Your official company name * */}
                    </div>
                    <div className={`${styles.inputDiv}  mt-2 col-12`}>
                      <input
                        type="text"
                        {...register("company_name", {
                          onChange: (e) => setCompanyName(e.target.value),
                        })}
                        value={companyName}
                        className=" col-12  d-flex ps-5 flex-row align-items-center justify-content-center  text-helvetica"
                        placeholder={`${parse(
                          t(`Business_preventivo_form_sottotitolo4`)
                        )}`}
                      />
                    </div>
                  </div>
                </div>

                <div className="d-flex flex-row align-items-start justify-content-start col-12  mt-4">
                  <div className="d-flex flex-column col-12 align-items-start justify-content-start">
                    <div className="sub-title ps-5 ">
                      {parse(t(`Business_preventivo_form_titolo5`))}
                      {/* Your phone number * */}
                    </div>
                    <div
                      className={`${styles.inputDiv} mt-2 col-12`}
                      style={{ marginBottom: "5px" }}
                    >
                      <input
                        type="text"
                        {...register("phone_number", {
                          onChange: (e) => setPhone(e.target.value),
                        })}
                        value={phone}
                        className=" col-12  d-flex ps-5 flex-row align-items-center justify-content-center  text-helvetica"
                        placeholder={`${parse(
                          t(`Business_preventivo_form_sottotitolo5`)
                        )}`}
                      />
                    </div>
                  </div>
                </div>
                <div className="d-flex flex-row align-items-center justify-content-start col-12 mt-4">
                  <div className="d-flex flex-column col-12 align-items-start justify-content-start ">
                    <div className="sub-title ps-5 ">
                      {parse(t(`Business_preventivo_form_titolo6`))}
                      {/* Your region * */}
                    </div>
                    <div className="mt-2 col-12">
                      <DropDown
                        click={(e) => setRegion(e)}
                        buttonText={`${parse(
                          t(`Business_preventivo_form_sottotitolo6`)
                        )}`}
                        options={[
                          { label: "Italy", value: "IT" },
                          { label: "Europe", value: "EU" },
                          {
                            label: "United States / Canada",
                            value: "USA",
                          },
                          {
                            label: "Australia / New Zeland",
                            value: "ANZ",
                          },
                          { label: "Asia", value: "ASIA" },
                          { label: "South America", value: "SA" },
                          { label: "Other", value: "O" },
                        ]}
                      />
                    </div>
                  </div>
                </div>
                <div className="d-flex flex-row align-items-start justify-content-start col-12  mt-4">
                  <div className="d-flex flex-column col-12 align-items-start justify-content-start">
                    <div className="sub-title ps-5 ">
                      {parse(t(`Business_preventivo_form_titolo7`))}
                      {/* Company size * */}
                    </div>
                    <div className="mt-2 col-12">
                      <DropDown
                        click={(e) => setCompanySize(e)}
                        buttonText={`${parse(
                          t(`Business_preventivo_form_sottotitolo7`)
                        )}`}
                        options={[
                          { label: "1 - 19", value: "1" },
                          { label: "20 - 49", value: "2" },
                          { label: "50 - 99", value: "3" },
                          { label: "100 - 249", value: "4" },
                          { label: "250 - 499", value: "5" },
                          { label: "500 - 999", value: "6" },
                          { label: "1000+", value: "7" },
                        ]}
                      />
                    </div>
                  </div>
                </div>
                <div className="d-flex flex-row align-items-start justify-content-start col-12  mt-4">
                  <div className="d-flex flex-column col-12 align-items-start justify-content-start">
                    <div className="sub-title ps-5 ">
                      {parse(t(`Business_preventivo_form_titolo8`))}
                      {/* Company size * */}
                    </div>
                    <div className="mt-2 col-12">
                      <DropDown
                        click={(e) => setCompanySize(e)}
                        buttonText={`${parse(
                          t(`Business_preventivo_form_sottotitolo8`)
                        )}`}
                        options={[
                          { label: "1 - 19", value: "1" },
                          { label: "20 - 49", value: "2" },
                          { label: "50 - 99", value: "3" },
                          { label: "100 - 249", value: "4" },
                          { label: "250 - 499", value: "5" },
                          { label: "500 - 999", value: "6" },
                          { label: "1000+", value: "7" },
                        ]}
                      />
                    </div>
                  </div>
                </div>
                <div
                  className="d-flex flex-row align-items-center justify-content-start col-12 sub-title mt-4 ps-5"
                  style={{ zIndex: 1 }}
                >
                  {parse(t(`Business_preventivo_form_titolo9`))}
                  {/* How can we help you? * */}
                </div>
                <div className="mt-2">
                  <DropDown
                    click={(e) => setObject(e)}
                    options={[
                      {
                        label: `${parse(
                          t(`Business_preventivo_form_sottotitolo9_1`)
                        )}`,
                        value: "demo",
                      },
                      {
                        label: `${parse(
                          t(`Business_preventivo_form_sottotitolo9_2`)
                        )}`,
                        value: "new order",
                      },
                      {
                        label: `${parse(
                          t(`Business_preventivo_form_sottotitolo9_3`)
                        )}`,
                        value: "existing order",
                      },
                      {
                        label: `${parse(
                          t(`Business_preventivo_form_sottotitolo9_4`)
                        )}`,
                        value: "partnership",
                      },
                      {
                        label: `${parse(
                          t(`Business_preventivo_form_sottotitolo9_5`)
                        )}`,
                        value: "help",
                      },
                      {
                        label: `${parse(
                          t(`Business_preventivo_form_sottotitolo9_6`)
                        )}`,
                        value: "other",
                      },
                    ]}
                    buttonText={"How can we help you"}
                  />
                </div>
                <div
                  className="d-flex flex-row align-items-center justify-content-start col-12 sub-title mt-4 ps-5"
                  style={{ zIndex: 1 }}
                >
                  {parse(t(`Business_preventivo_form_titolo10`))}
                  {/* Your message */}
                </div>
                <div className={`${styles.textareaDiv} mt-2`}>
                  <textarea
                    {...register("message", {
                      onChange: (e) =>
                        e.target.value.length < 150 &&
                        setMessage(e.target.value),
                    })}
                    value={message}
                    className=" col-12  d-flex ps-5 flex-row align-items-center justify-content-center pt-4 text-helvetica"
                    placeholder={`${parse(
                      t(`Business_preventivo_form_sottotitolo10`)
                    )}`}
                  />
                </div>
                <div className="d-flex flex-row align-items-start justify-content-between col-12 mt-5 ">
                  <div className="col-1  ">
                    <label className={`${styles.checkboxContainer}  `}>
                      <input
                        type="checkbox"
                        // checked={privacy}
                        onClick={() => setPrivacy(!privacy)}
                      />
                      <span className={`${styles.checkmark} `}></span>
                    </label>
                  </div>
                  <div className="col-10 pe-3 " style={{ fontSize: "1.2rem" }}>
                    {parse(t(`Business_preventivo_testo_privacypolicy_before`))}
                    <a href="/Privacy-Policy" className="color-black ">
                      privacy policy
                    </a>
                    {parse(t(`Business_preventivo_testo_privacypolicy_after`))}
                  </div>
                </div>
                {formError.current === true && (
                  <div
                    className="d-flex flex-row align-items-center justify-content-center col-12 mt-5"
                    style={{ color: "red" }}
                  >
                    {parse(t(`Business_preventivo_error_text`))}
                  </div>
                )}
                {formSuccess.current === true && (
                  <div className="d-flex flex-row align-items-center justify-content-center col-12 mt-5">
                    {parse(t(`Business_preventivo_success_text`))}
                    {/* La tua richiesta è stata inviata. */}
                  </div>
                )}
                <div className="d-flex flex-row align-items-center justify-content-end mt-4">
                  <div className=" col-5 d-flex flex-row align-self-end justify-content-end     ">
                    <button
                      type="submit"
                      className={`submit cursor-pointer d-flex flex-row align-items-center justify-content-center   ${styles.sendButton}`}
                      // onClick={() => sendEmail()}
                    >
                      {t(`contact_us_send_button`)}
                      {/* Send */}
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <Footer dark={false} />
    </>
  );
};
export default Businesscard;
