import React, { useEffect, useRef, useState } from "react";

import styles from "./DropDown.module.scss";

import { Modal } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faXmark } from "@fortawesome/free-solid-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import parse from "html-react-parser";
import DropDownButton from "./DropDownButton/DropDownButton";
import DropDownContent from "./DropDownContent/DropDownContent";

interface PopUpOption {
  value: string;
  label: string;
}

interface DropDownProps {
  //   image: string;
  //   close: () => void;
  //   title: string;
  buttonText: string;
  options: PopUpOption[];
  click: (e: string) => void;
}

const DropDown: React.FC<DropDownProps> = (props) => {
  const [selectedValue, setSelectedValue] = useState("");
  const [open, setOpen] = useState(false);

  const dropdownRef = useRef<any>();
  const buttonRef = useRef<any>();
  const contentRef = useRef<any>();

  useEffect(() => {
    const handler = (e: any) => {
      if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
        setOpen(false);
      }
    };
    document.addEventListener("click", handler);

    return () => {
      document.removeEventListener("click", handler);
    };
  }, [dropdownRef]);

  const toggleDropDown = () => {
    setOpen(!open);
  };

  return (
    <>
      <div className={`col-12 ${styles.container} `} ref={dropdownRef}>
        <DropDownButton
          text={selectedValue !== "" ? selectedValue : props.buttonText}
          toggle={toggleDropDown}
          open={open}
        />
        <DropDownContent
          click={(e) => {
            setSelectedValue(e.label);
            setOpen(!open);
            props.click(e.value);
          }}
          options={props.options}
          open={open}
        />
      </div>
    </>
  );
};

export default DropDown;
