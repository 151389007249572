import { useEffect, useRef, useState } from "react";

import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";

import styles from "./WebsiteCreation.module.scss";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import parse from "html-react-parser";

const smile =
  "https://d16qu8ed6p45v5.cloudfront.net/dlctechwebsite/emoji white.png";
const world =
  "https://d16qu8ed6p45v5.cloudfront.net/dlctechwebsite/world white.png";
const thunder =
  "https://d16qu8ed6p45v5.cloudfront.net/dlctechwebsite/fulmine white.png";
const star =
  "https://d16qu8ed6p45v5.cloudfront.net/dlctechwebsite/star white.png";
const webimg =
  "https://d16qu8ed6p45v5.cloudfront.net/dlctechwebsite/website.png";

const WebsiteCreation: React.FC = () => {
  const redirect = useNavigate();

  const { t } = useTranslation();
  const ref = useRef<null | HTMLDivElement>(null);
  const refMobuile = useRef<null | HTMLDivElement>(null);
  const rightDivRef = useRef<null | HTMLDivElement>(null);
  const [scrollTop, setScrollTop] = useState(0);

  const handleClick = () => {
    ref.current !== null &&
      ref.current!.scrollIntoView({ block: "end", behavior: "smooth" });
  };

  const handleScroll = () => {
    let vDistance = window.scrollY;

    if (rightDivRef.current) {
      console.log(rightDivRef.current.getBoundingClientRect().top, vDistance);
      setScrollTop(vDistance);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <div className="col-12   bg-black">
        <Header dark={true} selected="Products" />
        <div
          className={`d-none d-md-flex flex-row  flex-xl-row align-items-start justify-content-around col-12 ${styles.pagediv}`}
          style={{ backgroundColor: "black" }}
        >
          <div
            className="col-7   d-flex flex-column align-items-center justify-content-center "
            style={{ width: "60%" }}
          >
            <div
              className={`col-12  d-none d-md-flex flex-column align-items-center justify-content-end ${styles.space}`}
            ></div>
            <div className="col-12 d-md-flex d-none flex-column align-items-center justify-content-center ">
              <span className="col-12 bigger-title-esquare color-dark_grey_50">
                Website
              </span>
              <span className="col-12 bigger-title-esquare color-white">
                Creation
              </span>
            </div>

            <div className="col-12 mt-7  d-md-flex d-none flex-row align-items-center justify-content-between">
              <div
                className="col-3   d-md-flex d-none flex-column align-items-start justify-content-start "
                style={{ marginInlineEnd: "10%", minHeight: "120px" }}
              >
                <div className="col-12 ">
                  <img src={smile} style={{ height: "40px" }} alt="smile" />
                </div>
                <div className="col-12  mt-2">
                  <span className="sub-title-ml d-md-flex d-none fw-normal color-white">
                    {t(`website_1`)}
                    {/* personalized experience */}
                  </span>
                </div>
              </div>
              <div
                className="col-3    d-md-flex d-none flex-column align-items-start justify-content-start "
                style={{ marginInlineEnd: "10%", minHeight: "120px" }}
              >
                <div className="col-12">
                  <img src={world} style={{ height: "40px" }} alt="world" />
                </div>
                <div className="col-12 mt-2">
                  <span className="sub-title-ml d-md-flex d-none fw-normal color-white">
                    {t(`website_2`)}
                    {/* eye-catching design */}
                  </span>
                </div>
              </div>

              <div
                className="col-3   d-md-flex d-none flex-column align-items-start justify-content-start "
                style={{ marginInlineEnd: "10%", minHeight: "120px" }}
              >
                <div className="col-12">
                  <img src={thunder} style={{ height: "40px" }} alt="thunder" />
                </div>
                <div className="col-12 mt-2">
                  <span className="sub-title-ml d-md-flex d-none fw-normal color-white">
                    {t(`website_3`)}
                    {/* performance enhancement */}
                  </span>
                </div>
              </div>
            </div>
            <div
              className="col-12  d-none d-md-block flex-column align-items-center justify-content-end "
              style={{ height: "8vh" }}
            ></div>
            <div className="col-12   d-md-flex d-none flex-column align-items-start justify-content-end ">
              <span className="col-12 sub-title color-white">
                {parse(t(`website_4`))}
                {/* Creation / <br />
                re-branding */}
              </span>
              {/* <span className="col-12 sub-title color-white ">$ 29 / piece</span> */}
            </div>
            <div className="col-12  mt-3 d-md-flex d-none flex-row align-items-start justify-content-start ">
              <div className="col-xl-9 col-12  d-flex flex-row justify-content-between">
                <span
                  className={`cursor-pointer col-xl-5 col-6 sub-title  d-flex flex-row align-items-center justify-content-center ${styles.button} `}
                  style={{
                    minHeight: "50px",
                    borderRadius: "30px",
                    minWidth: "230px",
                  }}
                  onClick={() => handleClick()}
                >
                  {t(`website_5_button`)}
                  {/* More informations */}
                </span>
              </div>
            </div>

            <div
              style={{ backgroundColor: "black" }}
              className={`col-12 mt-10  d-md-flex d-none pe-5 flex-row align-items-start justify-content-start `}
            >
              <span className=" col-11   sub-title-text color-white">
                {parse(t(`website_text`))}
                {/* What ever is your sector, if you are looking to advance your
                business, your online presence is essential. <br /> This is
                where DLC comes into play, offering you website creation and
                rebranding services to transform your digital presence into an
                engaging and memorable experience. <br /> Thanks to our skilled
                coding and graphics teams we can provide a professional service
                with competitive pricing. */}
              </span>
            </div>
            <div
              className={`col-12 mt-7 d-none d-md-block`}
              style={{ backgroundColor: "black" }}
            >
              <div
                className={`col-6      d-md-flex d-none flex-row align-items-center justify-content-start  flex-wrap ${styles.textDiv}`}
              >
                <div className="col-12">
                  <img style={{ height: "40px" }} src={smile} alt="smile" />
                </div>
                <span className="col-8 sub-title-ml mt-2 color-white ">
                  {t(`website_7`)}
                  {/* personalized experience */}
                </span>
                <span
                  className={`col-12 sub-title mt-5 fw-normal color-white  `}
                  ref={ref}
                >
                  {t(`website_8`)}
                  {/* DLC is committed to creating a website that reflects your
                  uniqueness as a restaurant or business. Each project is
                  customized to meet your specific needs and convey your unique
                  identity. */}
                </span>
              </div>
            </div>
            <div
              className={`col-12 mt-7 d-none d-md-block`}
              style={{ backgroundColor: "black" }}
            >
              <div
                className={`col-6 d-md-flex d-none flex-row align-items-center justify-content-start  flex-wrap ${styles.textDiv}`}
              >
                <div className="col-12">
                  <img style={{ height: "40px" }} src={star} alt="star" />
                </div>
                <span className="col-6 sub-title-ml mt-2 color-white ">
                  {t(`website_9`)}
                  {/* eye-catching design */}
                </span>
                <span
                  className={`col-12 sub-title mt-5 fw-normal color-white `}
                >
                  {t(`website_10`)}
                  {/* Our team of graphic design experts works diligently to ensure
                  that your website is visually appealing, easy to navigate, and
                  reflects your brand. */}
                </span>
              </div>
            </div>
            <div
              className={`col-12 mt-7 d-none d-md-block `}
              style={{ backgroundColor: "black" }}
            >
              <div
                className={` col-6    d-md-flex d-none flex-row align-items-center justify-content-start  flex-wrap ${styles.textDiv}`}
              >
                <div className="col-12">
                  <img style={{ height: "40px" }} src={world} alt="world" />
                </div>
                <span className="col-6 sub-title-ml mt-2 color-white ">
                  {t(`website_11`)}
                  {/* performance enhancement */}
                </span>
                <span
                  className={`col-12 sub-title mt-5 fw-normal  color-white  
                  
                   `}
                >
                  {t(`website_12`)}
                  {/* A well-designed website not only attracts customers but also
                  increases your online visibility and improves your
                  conversions. DLC is dedicated to ensuring that your website
                  functions efficiently and is optimized for search engines. */}
                </span>
              </div>
            </div>
            <div
              className={`col-12 mt-7 d-none d-md-block `}
              style={{ backgroundColor: "black" }}
            >
              <div
                className={`col-6   d-md-flex d-none flex-row align-items-center justify-content-start  flex-wrap ${styles.textDiv}`}
              >
                <div className="col-12">
                  <img style={{ height: "40px" }} src={thunder} alt="thunder" />
                </div>
                <span className="col-6 sub-title-ml mt-2 color-white ">
                  {t(`website_13`)}
                  {/* effective rebranding */}
                </span>
                <span
                  className={`col-12 sub-title mt-5 fw-normal color-white `}
                >
                  {t(`website_14`)}
                  {/* If you're looking to revamp the appearance of your existing
                  website, we are here to help you breathe new life into your
                  online brand. Our graphics designer with our rebranding
                  service will transform your site into a modern digital
                  storefront. */}
                </span>
              </div>
            </div>
            <div
              className={`col-12  mt-7`}
              style={{ backgroundColor: "black" }}
            >
              <div
                className={`cursor-pointer col-xl-6 col-xl-6 col-md-6 col-xxl-4 sub-title  d-md-flex d-none flex-row align-items-center justify-content-center ${styles.button}`}
                style={{ height: "50px", borderRadius: "30px" }}
                onClick={() => redirect("/Contact-us")}
              >
                {t(`website_15_button`)}
                {/* Request a quote */}
              </div>
            </div>
          </div>
          <div className={`${styles.imageContainer}`}>
            <img src={webimg} alt="websitecreationImage" />
          </div>
        </div>

        {/* MOBILE */}
        <div className="col-12 d-flex  d-md-none flex-column align-items-center justify-content-center p-5">
          <span className="title-mob-e color-dark_grey_50 col-12">Website</span>
          <span className="title-mob-e color-white col-12">Creation</span>
        </div>
        <div className="col-12 mt-7 d-flex d-md-none flex-row align-items-center justify-content-between p-5">
          <div
            className="col-3   d-flex flex-column align-items-start justify-content-start "
            style={{ marginInlineEnd: "10%", minHeight: "120px" }}
          >
            <div className="col-12 ">
              <img src={smile} style={{ height: "40px" }} alt="smile" />
            </div>
            <div className="col-12  mt-2">
              <span className="sub-title-mob d-md-none d-flex fw-normal color-white">
                {t(`website_1`)}
                {/* personalized experience */}
              </span>
            </div>
          </div>
          <div
            className="col-3    d-flex flex-column align-items-start justify-content-start "
            style={{ marginInlineEnd: "10%", minHeight: "120px" }}
          >
            <div className="col-12">
              <img src={world} style={{ height: "40px" }} alt="world" />
            </div>
            <div className="col-12 mt-2">
              <span className="sub-title-mob d-md-none d-flex fw-normal color-white">
                {t(`website_2`)}
                {/* eye-catching design */}
              </span>
            </div>
          </div>
          <div
            className="col-3   d-flex flex-column align-items-start justify-content-start "
            style={{ marginInlineEnd: "10%", minHeight: "120px" }}
          >
            <div className="col-12">
              <img src={thunder} style={{ height: "40px" }} alt="thunder" />
            </div>
            <div className="col-12 mt-2">
              <span className="sub-title-mob d-md-none d-flex fw-normal color-white">
                {t(`website_3`)}
                {/* performance enhancement */}
              </span>
            </div>
          </div>
        </div>
        <div className="col-12   d-flex d-md-none flex-column align-items-start justify-content-end p-5">
          <span className="col-12 sub-title color-white">
            {parse(t(`website_4`))}
            {/* Creation / <br />
            re-branding */}
          </span>
          <span
            className={`cursor-pointer mt-4 col-xl-5 col-6 sub-title  d-flex flex-row align-items-center justify-content-center ${styles.button} `}
            style={{
              minHeight: "50px",
              borderRadius: "30px",
              minWidth: "230px",
            }}
            onClick={() => handleClick()}
          >
            {t(`website_5_button`)}
            {/* More informations */}
          </span>
        </div>
        <div className="col-12  mt-7 d-flex d-md-none flex-row align-items-start justify-content-start p-5 ">
          <div className="col-12  d-flex flex-row justify-content-center">
            <img
              alt="websitecreationImage"
              src={webimg}
              style={{
                objectFit: "contain",
                objectPosition: "center",
                borderRadius: "30px",

                height: "100%",
                width: "100%",
              }}
            />
          </div>
        </div>
        <div
          style={{ backgroundColor: "black" }}
          className={`col-12 mt-7 d-flex d-md-none flex-column align-items-start justify-content-end ${styles.pagediv}`}
        >
          <span
            ref={ref}
            className="col-xl-6 col-12   sub-title-text color-white"
          >
            {parse(t(`website_text`))}
            {/* What ever is your sector, if you are looking to advance your
            business, your online presence is essential. <br /> This is where
            DLC comes into play, offering you website creation and rebranding
            services to transform your digital presence into an engaging and
            memorable experience. <br /> Thanks to our skilled coding and
            graphics teams we can provide a professional service with
            competitive pricing. */}
          </span>
          <div
            className={`col-12 mt-7 d-md-none d-block`}
            style={{ backgroundColor: "black" }}
          >
            <div
              className={`col-12  mt-7 d-flex d-md-none flex-row align-items-center justify-content-start  flex-wrap ${styles.textDiv}`}
            >
              <div className="col-12">
                <img style={{ height: "40px" }} src={smile} alt="smile" />
              </div>
              <span className="col-6 sub-title-ml mt-2 color-white ">
                {t(`website_7`)}
                {/* personalized experience */}
              </span>
              <span className={`col-12 sub-title mt-5 fw-normal  color-white`}>
                {t(`website_8`)}
                {/* DLC is committed to creating a website that reflects your
                uniqueness as a restaurant or business. Each project is
                customized to meet your specific needs and convey your unique
                identity. */}
              </span>
            </div>
            <div
              className={`col-12 mt-7 d-md-none d-block`}
              style={{ backgroundColor: "black" }}
            >
              <div
                className={`col-12   d-flex d-md-none flex-row align-items-center justify-content-start  flex-wrap ${styles.textDiv}`}
              >
                <div className="col-12 mt-7">
                  <img style={{ height: "40px" }} src={star} alt="star" />
                </div>
                <span className="col-6 sub-title-ml mt-2 color-white ">
                  {t(`website_9`)}
                  {/* eye-catching design */}
                </span>
                <span
                  className={`col-12 sub-title mt-5 fw-normal color-white `}
                >
                  {t(`website_10`)}
                  {/* Our team of graphic design experts works diligently to ensure
                  that your website is visually appealing, easy to navigate, and
                  reflects your brand. */}
                </span>
              </div>
            </div>
            <div
              className={`col-12 mt-7 d-md-none d-block `}
              style={{ backgroundColor: "black" }}
            >
              <div
                className={`col-12    d-flex d-md-none flex-row align-items-center justify-content-start  flex-wrap ${styles.textDiv}`}
              >
                <div className="col-12 mt-7">
                  <img style={{ height: "40px" }} src={world} alt="world" />
                </div>
                <span className="col-6 sub-title-ml mt-2 color-white ">
                  {t(`website_11`)}
                  {/* performance enhancement */}
                </span>
                <span className={`col-12 sub-title mt-5 fw-normal color-white`}>
                  {t(`website_12`)}
                  {/* A well-designed website not only attracts customers but also
                  increases your online visibility and improves your
                  conversions. DLC is dedicated to ensuring that your website
                  functions efficiently and is optimized for search engines. */}
                </span>
              </div>
            </div>
            <div
              className={`col-12 mt-7 d-md-none d-block `}
              style={{ backgroundColor: "black" }}
            >
              <div
                className={`col-12   d-flex d-md-none flex-row align-items-center justify-content-start  flex-wrap ${styles.textDiv}`}
              >
                <div className="col-12 mt-7 ">
                  <img style={{ height: "40px" }} src={thunder} alt="thunder" />
                </div>
                <span className="col-6 sub-title-ml mt-2 color-white ">
                  {t(`website_13`)}
                  {/* effective rebranding */}
                </span>
                <span
                  className={`col-12 sub-title mt-5 fw-normal color-white `}
                >
                  {t(`website_14`)}
                  {/* If you're looking to revamp the appearance of your existing
                  website, we are here to help you breathe new life into your
                  online brand. Our graphics designer with our rebranding
                  service will transform your site into a modern digital
                  storefront. */}
                </span>
              </div>
            </div>
            <div
              className={`col-12  mt-7`}
              style={{ backgroundColor: "black" }}
            >
              <div
                className={`cursor-pointer col-xl-6 col-xl-6 col-md-6 col-xxl-2 sub-title  d-flex d-md-none flex-row align-items-center justify-content-center ${styles.button}`}
                style={{ height: "50px", borderRadius: "30px" }}
                onClick={() => redirect("/Contact-us")}
              >
                {t(`website_15_button`)}
                {/* Request a quote */}
              </div>
            </div>
          </div>
        </div>

        <Footer dark={true} />
      </div>
    </>
  );
};
export default WebsiteCreation;
