import { Store } from "pullstate";

export interface IWebsiteStore {
  currentPrecontact: Precontact;
  currentUuid: string;
}

const WebsiteStore = new Store<IWebsiteStore>({
  currentPrecontact: {
    name: "",
    surname: "",
    email: "",
    phone: "",
    workmail: "",
    role: "",
    linkedin: "",
    facebook: "",
    instagram: "",
    whatsapp: 0,
    company: "",
    website: "",
    uuid: "",
    mode: "",
    logourl: "",
    imageurl: "",
    orderId: "",
  },
  currentUuid: "",
});

export const setCurrentPrecontact = (precontact: Precontact) => {
  WebsiteStore.update((s) => {
    s.currentPrecontact = precontact;
  });
};

export default WebsiteStore;
