import { useNavigate } from "react-router-dom";

import styles from "./Footer.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebookSquare,
  faInstagramSquare,
  faLinkedin,
} from "@fortawesome/free-brands-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { useTranslation } from "react-i18next";

const logoBlack =
  "https://d16qu8ed6p45v5.cloudfront.net/dlctechwebsite/LOGO_FINALE1.png";
const logoWhite =
  "https://d16qu8ed6p45v5.cloudfront.net/dlctechwebsite/LOGO_WHITE.png";
const arrow =
  "https://d16qu8ed6p45v5.cloudfront.net/dlctechwebsite/freccia nera.png";
const arrowWhite =
  "https://d16qu8ed6p45v5.cloudfront.net/dlctechwebsite/freccia.png";

interface FooterProps {
  dark: boolean;
}

const Footer: React.FC<FooterProps> = (props) => {
  const { t } = useTranslation();
  const redirect = useNavigate();

  return (
    <>
      <div
        className={`d-flex align-items-start justify-content-center ${
          props.dark ? "bg-black" : ""
        } flex-column px-5 col-12`}
        style={{ zIndex: "2 ", position: "relative" }}
      >
        <div
          className="d-flex flex-row "
          style={{
            width: "100%",
            border: `${props.dark ? "1px solid white" : "1px solid black"}`,
          }}
        ></div>
        <div className="d-flex flex-row align-items-center justify-content-between  d-sm-none col-12 mt-3 ">
          <span className={` color-dark_grey_50 col-3 ${styles.title} `}>
            {t(`footer_follow_us`)}
            {/* Follow us on socials */}
          </span>
          <div className="d-flex flex-column align-items-start  justify-content-start">
            <span
              className={`${styles.title} col-12  ${
                props.dark ? "color-white" : "color-black"
              } `}
            >
              <a
                className={`${styles.title} col-4 ${
                  props.dark ? "color-white" : "color-black"
                } `}
                style={{ textDecoration: "none" }}
                href="https://www.linkedin.com/company/dlctech"
              >
                <FontAwesomeIcon
                  icon={faLinkedin as IconProp}
                  style={{ fontSize: "2.5rem" }}
                />
              </a>
            </span>
            <span
              className={`${styles.title} col-12 ${
                props.dark ? "color-white" : "color-black"
              } `}
            >
              <a
                className={`${styles.title} col-4  ${
                  props.dark ? "color-white" : "color-black"
                } `}
                style={{ textDecoration: "none" }}
                href="https://instagram.com/dlctech.it"
              >
                <FontAwesomeIcon
                  icon={faInstagramSquare as IconProp}
                  style={{ fontSize: "2.5rem" }}
                />
              </a>
            </span>
            <span
              className={`${styles.title} col-12 ${
                props.dark ? "color-white" : "color-black"
              } `}
            >
              <a
                className={`${styles.title} col-4 ${
                  props.dark ? "color-white" : "color-black"
                } `}
                style={{ textDecoration: "none" }}
                href="https://www.facebook.com/profile.php?id=61556555681980"
              >
                <FontAwesomeIcon
                  icon={faFacebookSquare as IconProp}
                  style={{ fontSize: "2.5rem" }}
                />
              </a>
            </span>
          </div>
        </div>
        <div className="d-flex mt-4 flex-row align-items-center justify-content-between col-12">
          <div className="d-flex   flex-row align-items-center justify-content-start p-sm-5 col-sm-4 col-9  ">
            <span
              className={`${styles.title} ${
                props.dark ? "color-white" : "color-black"
              }`}
            >
              {t(`footer_contact_us`)}
              {/* Contact us for a quote, collaborations or special requests. */}
            </span>
          </div>
          <div className="d-flex flex-column align-items-start justify-content-end d-none d-sm-flex col-2 ">
            <span className={` color-dark_grey_50 ${styles.title} `}>
              {t(`footer_follow_us`)}
              {/* Follow us on socials */}
            </span>
            <span
              className={`${styles.title} ${
                props.dark ? "color-white" : "color-black"
              } `}
            >
              <a
                className={`${styles.title} col-4 ${
                  props.dark ? "color-white" : "color-black"
                } `}
                style={{ textDecoration: "none" }}
                href="https://www.linkedin.com/company/dlctech"
              >
                Linkedin
              </a>
            </span>
            <span
              className={`${styles.title} ${
                props.dark ? "color-white" : "color-black"
              } `}
            >
              <a
                className={`${styles.title} col-4 ${
                  props.dark ? "color-white" : "color-black"
                } `}
                style={{ textDecoration: "none" }}
                href="https://instagram.com/dlctech.it"
              >
                Instagram
              </a>
            </span>
            <span
              className={`${styles.title} ${
                props.dark ? "color-white" : "color-black"
              } `}
            >
              <a
                className={`${styles.title} col-4 ${
                  props.dark ? "color-white" : "color-black"
                } `}
                style={{ textDecoration: "none" }}
                href="https://www.facebook.com/profile.php?id=61556555681980"
              >
                Facebook
              </a>
            </span>
          </div>
        </div>
        <div className="d-flex flex-row align-items-center justify-content-between p-sm-5 col-12 mt-3 mt-sm-0">
          <div
            className={`d-flex flex-row align-items-center justify-content-between px-5 col-sm-5 col-12 ${
              props.dark
                ? styles.contactsButtonWhite
                : styles.contactsButtonDark
            }`}
            onClick={() => redirect("/Contact-us")}
          >
            <span className="sub-title fw-normal color-dark_grey_50">
              {t(`footer_contacts_button`)}
              {/* Contacts */}
            </span>
            <div>
              <img
                src={props.dark ? arrowWhite : arrow}
                className={`${styles.arrow}`}
                alt="arrow"
              />
            </div>
          </div>
        </div>
        <div className="d-flex flex-row col-sm-5  col-12 ps-md-5 mt-3 mt-sm-0">
          <a
            className={`${
              props.dark === false ? "color-black" : "color-white"
            }  text`}
            style={{ textDecoration: "none" }}
            href="/Privacy-Policy"
          >
            {" "}
            Privacy Policy{" "}
          </a>
          <a
            className={`${
              props.dark === false ? "color-black" : "color-white"
            }  text ms-5`}
            style={{ textDecoration: "none" }}
            href="/Terms&Conditions"
          >
            {" "}
            Terms & Conditions{" "}
          </a>
        </div>
        <div className="d-flex flex-row align-items-center justify-content-between col-12 p-sm-5 mt-4 mt-sm-0 mb-4 mb-sm-0">
          <div className="col-3 d-flex flex-row align-items-center justify-content-start ">
            <img
              className={`${styles.logo}`}
              src={props.dark ? logoWhite : logoBlack}
              alt="logo"
              // style={{ height: "40px" }}
            />
          </div>
          {/* <div className="col-5  d-flex flex-row align-items-center justify-content-center">
            <span
              className={`text-16 ${
                props.dark ? "color-white" : "color-black"
              }`}
            >
              &copy; 2023. DLC Tech s.r.l. <br /> {t(`footer_rights`)}
              All rights reserved
            </span>
          </div> */}
          <div className="col-7  text-end d-none d-sm-block ">
            <span
              className={`text  ${props.dark ? "color-white" : "color-black"}`}
            >
              DLC Tech S.R.L., Selvazzano Dentro (PD) Via Montecchia 22/a CAP
              35030. <br /> P.IVA: 05583080287. REA: PD - 477655. Cap. Sociale
              10'000€
            </span>
          </div>
        </div>
        <div className="d-flex d-sm-none col-12 ">
          <div className="col-12   text-helvetica ">
            <span className={`  ${props.dark ? "color-white" : "color-black"}`}>
              DLC Tech S.R.L., Selvazzano Dentro (PD) Via Montecchia 22/a CAP
              35030. P.IVA: 05583080287. REA: PD - 477655. <br /> Cap. Sociale
              10'000€
            </span>
          </div>
        </div>
      </div>
    </>
  );
};
export default Footer;
