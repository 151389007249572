import React, { useEffect, useState } from "react";

import styles from "./DropDownItem.module.scss";

interface DropDownItemProps {
  //   image: string;
  //   close: () => void;
  //   title: string;
  label: string;
  click: () => void;
}

const DropDownItem: React.FC<DropDownItemProps> = (props) => {
  const [selectedValue, setSelectedValue] = useState("");
  const [open, setOpen] = useState(false);

  const toggleDropDown = () => {
    setOpen(!open);
  };

  return (
    <>
      <div
        className={`col-12 d-flex p-3 cursor-pointer  flex-row align-items-center justify-content-start  ${styles.item}`}
        onClick={props.click}
      >
        {props.label}
      </div>
    </>
  );
};

export default DropDownItem;
