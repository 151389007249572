import { useState } from "react";

import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";

import styles from "./Products.module.scss";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import parse from "html-react-parser";

const maxi = "https://d16qu8ed6p45v5.cloudfront.net/dlctechwebsite/revProd.jpg";
const card =
  "https://d16qu8ed6p45v5.cloudfront.net/dlctechwebsite/busscard_388.png";

const Products: React.FC = () => {
  const { t } = useTranslation();
  const redirect = useNavigate();

  return (
    <>
      <div className="overflow-hidden">
        <Header dark={false} selected="Products" />
        <div
          className="d-sm-flex d-none  flex-row align-items-center justify-content-center col-12"
          style={{ height: "88vh" }}
        >
          {/* <div className="col-2  d-flex flex-column align-items-center  h-75 "></div> */}
          <div className="col-xl-3 col-sm-4 d-flex   flex-column align-items-center h-75 ">
            <div
              className={`col-12  h-75  d-flex flex-row align-items-center justify-content-center ${styles.productsDiv}`}
              style={{ borderRadius: "70px" }}
            >
              <img
                src={maxi}
                alt="dlcreviews"
                style={{ maxWidth: "210%" }}
                onClick={() => redirect("/Products/DLCReviews")}
              />
            </div>
            <div className="col-12 h-25  d-flex flex-column align-items-center justify-content-start ">
              <div className="mt-3 ">
                <span className="esquare-title-prod color-black">Reviews</span>
              </div>
              <div className="mt-3 col-12  d-flex flex-row aling-items-center justify-content-center">
                <span
                  className={`${styles.detailsBtn} col-8 sub-title`}
                  onClick={() => redirect("/Products/DLCReviews")}
                >
                  {parse(t(`Products_btn`))}
                </span>
              </div>
              {/* <div className="mt-5 ">
                <span className="text-16"> $ 80</span>
              </div> */}
            </div>
          </div>

          <div className="col-xl-3 col-sm-4  d-flex flex-column align-items-center  h-75 ">
            <div
              className={`col-12  h-75  d-flex flex-row align-items-center justify-content-center ${styles.productsDiv} `}
              style={{ borderRadius: "70px" }}
            >
              <img
                alt="dlcbusinesscard"
                src={card}
                style={{ maxWidth: "140%", transform: "translateY(6%)" }}
                onClick={() => redirect("/Products/Businesscard")}
              />
            </div>
            <div className="col-12  h-25  d-flex flex-column align-items-center justify-content-start ">
              <div className="mt-3 ">
                <span
                  className="esquare-title-prod color-black"
                  onClick={() => redirect("/Products/Businesscard")}
                >
                  Business Card
                </span>

                {/* <span className="sub-title-esquare color-black ">
                  Coming soon
                </span> */}
                {/* <span className="sub-title"> ...</span> */}
              </div>
              <div className="mt-3 col-12  d-flex flex-row aling-items-center justify-content-center">
                <span
                  className={`${styles.detailsBtn} col-8 sub-title`}
                  onClick={() => redirect("/Products/Businesscard")}
                >
                  {parse(t(`Products_btn`))}
                </span>
              </div>
              {/* <div className="mt-5 ">
                <span className="text-16"> $ 30</span>
              </div> */}
            </div>
          </div>

          {/* <div className="col-2  d-flex flex-column align-items-center  h-75 "></div> */}
        </div>

        {/* MOBILE */}
        <div className="d-flex d-sm-none flex-column align-items-center p-5 justify-content-start col-12">
          <div
            className="col-12  d-flex flex-column align-items-center h-75  "
            style={{ marginTop: "10vh" }}
          >
            <div
              className={`col-12  overflow-hidden  d-flex flex-row align-items-center justify-content-center `}
              style={{ borderRadius: "70px" }}
            >
              <img
                src={maxi}
                alt="dlcreviews"
                className=""
                style={{ maxWidth: "210%" }}
                onClick={() => redirect("/Products/DLCReviews")}
              />
            </div>
            <div className="col-12 mb-5   d-flex flex-column align-items-center justify-content-start ">
              <div className=" ">
                <span className="esquare-title-prod color-black">Reviews</span>
              </div>
              {/* <div className="mt-5 ">
                <span className="text-16"> $ 80</span>
              </div> */}
            </div>
          </div>

          <div
            className="col-12 d-flex flex-column align-items-center   mt-5 "
            style={{ transform: "translateY(-15%)" }}
          >
            <div
              className={`col-12 overflow-hidden  d-flex  flex-row align-items-center justify-content-center  `}
            >
              <img
                src={card}
                alt="dlcbusinesscard"
                style={{ maxWidth: "180%", transform: "translateY(15%)" }}
                onClick={() => redirect("/Products/Businesscard")}
              />
            </div>
            <div className="col-12  h-25 d-flex flex-column align-items-center justify-content-start ">
              <div className="mt-5  text-center">
                <span
                  className="esquare-title-prod color-black "
                  onClick={() => redirect("/Products/Businesscard")}
                >
                  Business Card
                </span>
                <br />
                {/* <span className="sub-title-esquare color-black ">
                  Coming soon
                </span> */}
                {/* <span className="sub-title"> ...</span> */}
              </div>
              {/* <div className="mt-5 ">
                <span className="text-16"> $ 30</span>
              </div> */}
            </div>
          </div>
        </div>
        <Footer dark={false} />
      </div>
    </>
  );
};
export default Products;
