import React, { useEffect, useState } from "react";

import styles from "./ReviewsCustomizationPopUp.module.scss";

import { Modal } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faXmark } from "@fortawesome/free-solid-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

import parse from "html-react-parser";
import { useTranslation } from "react-i18next";

const rev =
  "https://d16qu8ed6p45v5.cloudfront.net/dlctechwebsite/review_logo.png";
const revblack =
  "https://d16qu8ed6p45v5.cloudfront.net/dlctechwebsite/revblack_std.png";
interface PopUpExampleProps {
  popup: boolean;
  close: () => void;
}

const ReviewsCustomizationPopUp: React.FC<PopUpExampleProps> = (props) => {
  const { t } = useTranslation();

  const [popup, setPopup] = useState(false);

  return (
    <>
      <Modal
        show={props.popup}
        onHide={() => (setPopup(!popup), props.close)}
        className={`d-flex flex-row justify-content-center align-items-center  `}
        style={{
          height: "100vh",
          width: "100vw",
          overflow: "scroll",
          //   border: "1px solid red",
          background: "rgba(0,0,0,0.85)",
        }}
        contentClassName={`${styles.bodyModal}  `}
      >
        <div className={`${styles.body} `}>
          <Modal.Body
            className={`d-flex flex-column align-items-start  justify-content-start p-4
            bg-light color-black 
            
            `}
            style={{
              height: "90vh",
              width: "90vw",
              borderRadius: "20px",
              overflow: "scroll",
              //   border: `2px solid red`,
            }}
          >
            <div
              className="d-flex flex-row align-items-center justify-content-end col-12 "
              style={{ height: "50px" }}
            >
              <span
                className={`${styles.closeButton} `}
                onClick={() => props.close()}
              >
                <FontAwesomeIcon icon={faXmark as IconProp} />
              </span>
            </div>
            <div className="ps-7 pe-7 d-md-flex d-none flex-column align-items-start justify-content-start col-12 ">
              <div className="d-flex flex-row align-items-center  justify-content-start col-12 ">
                <span className=" sub-title">
                  {parse(t(`Review_quadratoni_titoletto_5`))}
                  {/* Customization */}
                </span>
              </div>
              <div className="d-flex flex-row align-items-center  justify-content-start col-12 mt-4">
                <span className=" small-title fw-bold">
                  {parse(t(`Review_quadratoni_titolo_5_interno`))}
                  {/* Make it Yours */}
                </span>
              </div>
              <div className={`${styles.content} col-12  mt-5 `}>
                <div className="d-flex flex-row align-items-center justify-content-center col-12  ">
                  <div className="col-4  h-100 d-flex  flex-column align-items-center justify-content-center ">
                    <span className="light-title fw-bold col-3 text-start">
                      {parse(t(`Review_quadratoni_4_interno1_1`))}
                      {/* Add */}
                    </span>

                    <span className="light-title fw-bold col-3 text-start mt-7">
                      {parse(t(`Review_quadratoni_4_interno1_2`))}
                      {/* Your */}
                    </span>

                    <span className="light-title fw-bold col-3 text-start mt-7">
                      {parse(t(`Review_quadratoni_4_interno1_3`))}
                      {/* Logo */}
                    </span>
                  </div>
                  <div className=" col-6 h-100 d-flex  flex-column align-items-center justify-content-start">
                    <div className={`col-6   ${styles.imgContainer1}`}>
                      <img src={rev} alt="reviewsImage" />
                    </div>
                  </div>
                </div>
              </div>
              <div className={`${styles.content} col-12  mt-5 `}>
                <div className="d-flex flex-row align-items-center justify-content-center col-12  ">
                  <div className=" col-6 h-100 d-flex  flex-column align-items-center justify-content-start">
                    <div className={`col-6   ${styles.imgContainer2}`}>
                      <img src={revblack} alt="reviewsBlackImage" />
                    </div>
                  </div>
                  <div className="col-4  h-100 d-flex  flex-column align-items-start justify-content-center ps-5 ">
                    <span className="light-title fw-bold col-3 text-start">
                      {parse(t(`Review_quadratoni_4_interno2_1`))}
                      {/* Choose */}
                    </span>

                    <span className="light-title fw-bold col-3 text-start mt-7">
                      {parse(t(`Review_quadratoni_4_interno2_2`))}
                      {/* Your */}
                    </span>

                    <span className="light-title fw-bold col-3 text-start mt-7">
                      {parse(t(`Review_quadratoni_4_interno2_3`))}
                      {/* Color */}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className=" d-flex d-sm-none flex-column align-items-start justify-content-start col-12 pb-4">
              <div className="d-flex flex-row align-items-center justify-content-start col-12 ">
                <span className=" sub-title">
                  {parse(t(`Review_quadratoni_titoletto_5`))}
                  {/* Customization */}
                </span>
              </div>
              <div className="d-flex flex-row align-items-center justify-content-start col-12 mt-4">
                <span className=" small-title fw-bold">
                  {parse(t(`Review_quadratoni_titolo_5_interno`))}
                  {/* Make it Yours */}
                </span>
              </div>
              <div className={`${styles.content} col-12  mt-5  `}>
                <div className="d-flex flex-column align-items-start justify-content-start col-12  ">
                  <div className="col-12  h-50  d-flex  flex-column align-items-center justify-content-center ">
                    <span className="light-title fw-bold   mt-5">
                      {parse(t(`Review_quadratoni_4_interno1_1`))}
                      {/* Add */}
                    </span>

                    <span className="light-title fw-bold   mt-5">
                      {parse(t(`Review_quadratoni_4_interno1_2`))}
                      {/* Your */}
                    </span>

                    <span className="light-title fw-bold   mt-5">
                      {parse(t(`Review_quadratoni_4_interno1_3`))}
                      {/* Logo */}
                    </span>
                  </div>
                  <div className=" col-12 h-50 d-flex  flex-column align-items-center justify-content-center">
                    <div className={`   ${styles.imgContainer1}`}>
                      <img src={rev} alt="reviewsImage" />
                    </div>
                  </div>
                </div>
              </div>
              <div className={`${styles.content} col-12  mt-5  `}>
                <div className="d-flex flex-column align-items-start justify-content-center col-12  ">
                  <div className=" col-12 h-50 d-flex  flex-column align-items-center justify-content-center">
                    <div className={`   ${styles.imgContainer2}`}>
                      <img src={revblack} alt="reviewsBlackImage" />
                    </div>
                  </div>
                  <div className="col-12  h-50  d-flex  flex-column align-items-center justify-content-center ">
                    <span className="light-title fw-bold  mt-5">
                      {parse(t(`Review_quadratoni_4_interno2_1`))}
                      {/* Choose */}
                    </span>

                    <span className="light-title fw-bold  mt-5">
                      {parse(t(`Review_quadratoni_4_interno2_2`))}
                      {/* Your */}
                    </span>

                    <span className="light-title fw-bold  mt-5">
                      {parse(t(`Review_quadratoni_4_interno2_3`))}
                      {/* Color */}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
        </div>
      </Modal>
    </>
  );
};

export default ReviewsCustomizationPopUp;
