import { useEffect, useState } from "react";

import styles from "./FAQ.module.scss";

import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import { useNavigate } from "react-router-dom";
import { faAngleDown, faAngleUp } from "@fortawesome/free-solid-svg-icons";

import parse from "html-react-parser";
import { useTranslation } from "react-i18next";

interface FAQ {
  id: number;
  question: string;
  ask: string;
}

const FAQ: React.FC = () => {
  const { t } = useTranslation();
  const [domOpen, setdomOpen] = useState(0);
  const parser = new DOMParser();

  const redirect = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const faqtexts: FAQ[] = [
    {
      id: 1,
      question: `${t(`faq1`)}`,
      ask: `${t(`risposta1`)}`,
    },
    {
      id: 2,
      question: `${t(`faq2`)}`,
      ask: `${t(`risposta2`)}`,
    },
    {
      id: 3,
      question: `${t(`faq3`)}`,
      ask: `${t(`risposta3`)}`,
    },
    {
      id: 4,
      question: `${t(`faq4`)}`,
      ask: `${t(`risposta4`)}`,
    },
    {
      id: 5,
      question: `${t(`faq5`)}`,
      ask: `${t(`risposta5`)}`,
    },
    {
      id: 6,
      question: `${t(`faq6`)}`,
      ask: `${t(`risposta6`)}`,
    },
    {
      id: 7,
      question: `${t(`faq7`)}`,
      ask: `${t(`risposta7`)}`,
    },
    {
      id: 8,
      question: `${t(`faq8`)}`,
      ask: `${t(`risposta8`)}`,
    },
    {
      id: 9,
      question: `${t(`faq9`)}`,
      ask: `${t(`risposta9`)}`,
    },
    {
      id: 10,
      question: `${t(`faq10`)}`,
      ask: `${t(`risposta10`)}`,
    },
    {
      id: 11,
      question: `${t(`faq11`)}`,
      ask: `${t(`risposta11`)}`,
    },
    {
      id: 12,
      question: `${t(`faq12`)}`,
      ask: `${t(`risposta12`)}`,
    },
    {
      id: 13,
      question: `${t(`faq13`)}`,
      ask: `${t(`risposta13`)}`,
    },
    {
      id: 14,
      question: `${t(`faq14`)}`,
      ask: `${t(`risposta14`)}`,
    },
    {
      id: 15,
      question: `${t(`faq15`)}`,
      ask: `${t(`risposta15`)}`,
    },
    {
      id: 16,
      question: `${t(`faq16`)}`,
      ask: `${t(`risposta16`)}`,
    },
    {
      id: 17,
      question: `${t(`faq17`)}`,
      ask: `${t(`risposta17`)}`,
    },
    {
      id: 18,
      question: `${t(`faq18`)}`,
      ask: `${t(`risposta18`)}`,
    },
    {
      id: 19,
      question: `${t(`faq19`)}`,
      ask: `${t(`risposta19`)}`,
    },
    {
      id: 20,
      question: `${t(`faq20`)}`,
      ask: `${t(`risposta20`)}`,
    },
    {
      id: 21,
      question: `${t(`faq21`)}`,
      ask: `${t(`risposta21`)}`,
    },
    {
      id: 22,
      question: `${t(`faq22`)}`,
      ask: `${t(`risposta22`)}`,
    },
    {
      id: 23,
      question: `${t(`faq23`)}`,
      ask: `${t(`risposta23`)}`,
    },
    {
      id: 24,
      question: `${t(`faq24`)}`,
      ask: `${t(`risposta24`)}`,
    },
  ];

  return (
    <>
      <div className="overflow-hidden">
        <Header dark={false} selected="Services" />
        <div
          className={`d-sm-flex   flex-column align-items-center justify-content-start col-12 ${styles.pagediv}`}
        >
          <div className="d-flex flex-row align-items-center justify-content-center col-12">
            <span className="bigger-title-faq color-dark_grey_50">
              {t(`faq_title`)}
            </span>
          </div>

          {faqtexts.map((item) => (
            <div
              className={`d-flex ${
                item.id === 1 ? "mt-10" : "mt-5"
              } cursor-pointer flex-column align-items-center justify-content-center  col-sm-8 col-12 ${
                styles.faqRow
              }`}
              onClick={() =>
                domOpen === item.id ? setdomOpen(0) : setdomOpen(item.id)
              }
            >
              <div
                className={`d-flex flex-row align-items-center justify-content-start col-12 ${
                  domOpen === item.id ? "pt-5" : ""
                }`}
              >
                <span
                  className={`sub-title-faq col-1  d-flex flex-row align-items-center justify-content-center `}
                >
                  &#x2022;
                </span>
                <span className={`sub-title-faq col-10  `}>
                  {item.question}
                </span>
                <span
                  className={`sub-title-faq col-1  d-flex flex-row align-items-center justify-content-center `}
                >
                  <FontAwesomeIcon
                    icon={
                      (domOpen !== item.id
                        ? faAngleDown
                        : faAngleUp) as IconProp
                    }
                  />
                </span>
              </div>
              <div
                className={`${
                  domOpen === item.id ? "d-flex" : "d-none"
                } flex-row pt-5 pb-5 text-faq col-10 ${styles.responseDiv}`}
              >
                <span>{parse(item.ask)}</span>
              </div>
            </div>
          ))}

          <div
            className={`d-flex mt-5 cursor-pointer flex-column align-items-center justify-content-center  col-sm-8 col-12 ${styles.faqRow_Last}`}
            onClick={() => redirect("/Assistance")}
          >
            <div
              className={`d-flex flex-row align-items-center justify-content-start col-12 `}
            >
              <span
                className={`sub-title-faq col-1  d-flex flex-row align-items-center justify-content-center `}
              ></span>
              <span className={`sub-title-faq col-10 text-center  `}>
                {t(`faq_grey`)}
              </span>
              <span
                className={`sub-title-faq col-1  d-flex flex-row align-items-center justify-content-center `}
              ></span>
            </div>
            {/* <div
              className={`d-none flex-row pt-5 pb-5 text-faq col-10 ${styles.responseDiv}`}
            >
              <span className="text-center">{parse(t(`faq_grey`))}</span>
            </div> */}
          </div>
          {/* <div
            className={`d-flex mt-10 cursor-pointer flex-column align-items-center justify-content-center  col-sm-8 col-12 ${styles.faqRow}`}
            onClick={() => setdom1open(!dom1open)}
          >
            <div
              className={`d-flex flex-row align-items-center justify-content-start col-12 ${
                dom1open ? "pt-5" : ""
              }`}
            >
              <span
                className={`sub-title-faq col-1  d-flex flex-row align-items-center justify-content-center `}
              >
                &#x2022;
              </span>
              <span className={`sub-title-faq col-10  `}>
                Come utilizzare i prodotti DLC Review e DLC Menu?
              </span>
              <span
                className={`sub-title-faq col-1  d-flex flex-row align-items-center justify-content-center `}
              >
                <FontAwesomeIcon
                  icon={(!dom1open ? faAngleDown : faAngleUp) as IconProp}
                />
              </span>
            </div>
            <div
              className={`${
                dom1open ? "d-flex" : "d-none"
              } flex-row pt-5 pb-5 text-faq col-10 ${styles.responseDiv}`}
            >
              Per utilizzare i prodotti DLC, bisogna avvicinare la porzione
              superiore del proprio telefono alla faccia superiore del prodotto.
              Dopo pochi attimi, nello schermo del vostro device apparirà un
              notifica. Una volta cliccata, questa vi rimanderà alla recensione
              o al menu di riferimento.
            </div>
          </div>

          <div
            className={`d-flex mt-5 cursor-pointer flex-column align-items-center justify-content-center  col-sm-8 col-12 ${styles.faqRow}`}
            onClick={() => setdom2open(!dom2open)}
          >
            <div
              className={`d-flex flex-row align-items-center justify-content-start col-12 ${
                dom2open ? "pt-5" : ""
              }`}
            >
              <span
                className={`sub-title-faq col-1  d-flex flex-row align-items-center justify-content-center `}
              >
                &#x2022;
              </span>
              <span className={`sub-title-faq col-10  `}>
                Come funziona la tecnologia NFC nei prodotti DLC Tech?
              </span>
              <span
                className={`sub-title-faq col-1  d-flex flex-row align-items-center justify-content-center `}
              >
                <FontAwesomeIcon
                  icon={(!dom2open ? faAngleDown : faAngleUp) as IconProp}
                />
              </span>
            </div>
            <div
              className={`${
                dom2open ? "d-flex" : "d-none"
              } flex-row pt-5 pb-5 text-faq col-10 ${styles.responseDiv}`}
            >
              La tecnologia NFC (Near Field Communication) consente una
              comunicazione wireless a breve distanza. Nei nostri prodotti, è
              utilizzata per facilitare l'interazione tra il dispositivo e il
              telefono. Avvicinando il telefono alla superficie del prodotto,
              viene rilevato un link NFC che apre una notifica sul telefono.
            </div>
          </div>
          <div
            className={`d-flex mt-5 cursor-pointer flex-column align-items-center justify-content-center  col-sm-8 col-12 ${styles.faqRow}`}
            onClick={() => setdom3open(!dom3open)}
          >
            <div
              className={`d-flex flex-row align-items-center justify-content-start col-12 ${
                dom3open ? "pt-5" : ""
              }`}
            >
              <span
                className={`sub-title-faq col-1  d-flex flex-row align-items-center justify-content-center `}
              >
                &#x2022;
              </span>
              <span className={`sub-title-faq col-10  `}>
                Come funziona la tecnologia NFC nei prodotti DLC Tech?
              </span>
              <span
                className={`sub-title-faq col-1  d-flex flex-row align-items-center justify-content-center `}
              >
                <FontAwesomeIcon
                  icon={(!dom3open ? faAngleDown : faAngleUp) as IconProp}
                />
              </span>
            </div>
            <div
              className={`${
                dom3open ? "d-flex" : "d-none"
              } flex-row pt-5 pb-5 text-faq col-10 ${styles.responseDiv}`}
            >
              DLC Tech offre al momento due prodotti, DLC Review e DLC Menu, che
              sfruttano la tecnologia NFC per migliorare l'esperienza
              dell'utente ed aumentare l’efficienza del tuo business.
            </div>
          </div>
          <div
            className={`d-flex mt-5  cursor-pointer flex-column align-items-center justify-content-center  col-sm-8 col-12 ${styles.faqRow}`}
            onClick={() => setdom4open(!dom4open)}
          >
            <div
              className={`d-flex flex-row align-items-center justify-content-start col-12 ${
                dom4open ? "pt-5" : ""
              }`}
            >
              <span
                className={`sub-title-faq col-1  d-flex flex-row align-items-center justify-content-center `}
              >
                &#x2022;
              </span>
              <span className={`sub-title-faq col-10  `}>
                Come posso attivare la funzione NFC sul mio telefono?
              </span>
              <span
                className={`sub-title-faq col-1  d-flex flex-row align-items-center justify-content-center `}
              >
                <FontAwesomeIcon
                  icon={(!dom4open ? faAngleDown : faAngleUp) as IconProp}
                />
              </span>
            </div>
            <div
              className={`${
                dom4open ? "d-flex" : "d-none"
              } flex-row pt-5 pb-5 text-faq col-10 ${styles.responseDiv}`}
            >
              Per utilizzare i nostri prodotti, assicurati che la funzione NFC
              del tuo telefono sia attivata. Questa opzione è generalmente
              presente nelle impostazioni del telefono. Consulta il manuale del
              tuo dispositivo per istruzioni specifiche.
            </div>
          </div>
          <div
            className={`d-flex mt-5 cursor-pointer flex-column align-items-center justify-content-center  col-sm-8 col-12 ${styles.faqRow}`}
            onClick={() => setdom5open(!dom5open)}
          >
            <div
              className={`d-flex flex-row align-items-center justify-content-start col-12 ${
                dom5open ? "pt-5" : ""
              }`}
            >
              <span
                className={`sub-title-faq col-1  d-flex flex-row align-items-center justify-content-center `}
              >
                &#x2022;
              </span>
              <span className={`sub-title-faq col-10  `}>
                Come posso attivare la funzione NFC su iphone?
              </span>
              <span
                className={`sub-title-faq col-1  d-flex flex-row align-items-center justify-content-center `}
              >
                <FontAwesomeIcon
                  icon={(!dom5open ? faAngleDown : faAngleUp) as IconProp}
                />
              </span>
            </div>
            <div
              className={`${
                dom5open ? "d-flex" : "d-none"
              } flex-row pt-5 pb-5 text-faq col-10 ${styles.responseDiv}`}
            >
              <span>
                La funzione NFC nei modelli IOS più recenti è sempre attiva. Se
                così non dovesse essere, sblocca il tuo iPhone, apri le
                impostazioni ed entra in <b>“Centro di controllo” </b>, poi in
                <b> “Ulteriori controlli”</b> quindi seleziona con il tasto{" "}
                <b>+</b> il lettore <b>tag NFC</b>.
              </span>
            </div>
          </div>
          <div
            className={`d-flex mt-5 cursor-pointer flex-column align-items-center justify-content-center  col-sm-8 col-12 ${styles.faqRow}`}
            onClick={() => setdom6open(!dom6open)}
          >
            <div
              className={`d-flex flex-row align-items-center justify-content-start col-12 ${
                dom6open ? "pt-5" : ""
              }`}
            >
              <span
                className={`sub-title-faq col-1  d-flex flex-row align-items-center justify-content-center `}
              >
                &#x2022;
              </span>
              <span className={`sub-title-faq col-10  `}>
                Come posso attivare la funzione NFC su android?
              </span>
              <span
                className={`sub-title-faq col-1  d-flex flex-row align-items-center justify-content-center `}
              >
                <FontAwesomeIcon
                  icon={(!dom6open ? faAngleDown : faAngleUp) as IconProp}
                />
              </span>
            </div>
            <div
              className={`${
                dom6open ? "d-flex" : "d-none"
              } flex-row pt-5 pb-5 text-faq col-10 ${styles.responseDiv}`}
            >
              <span>
                Innanzi tutto apri le <b>Impostazioni</b> del dispositivo,
                facendo tap sull’ <b>icona dell’ingranaggio </b> residente nella
                schermata Home, oppure nel drawer, dopo di che fai tap sulle
                voci <b> Dispositivi collegati, </b>
                <b>Preferenze di connessione </b> ed
                <b> NFC</b> e sposta la levetta situata in cima alla schermata
                successiva da <b> OFF</b> a <b> ON</b>. <br /> In base alla
                <b> versione di Android</b> del tuo device, le voci del menu
                potrebbero essere diverse: <br /> Per i dispositivi{" "}
                <b> Xiaomi</b> bisogna aprire il menu
                <b>
                  {" "}
                  Impostazioni &#62; Connessione e condivisione &#62; NFC
                </b>; <br />
                Per i terminali <b> Huawei </b>il percorso da seguire è{" "}
                <b> Impostazioni &#62; Connessione dispositivo &#62; NFC </b>;{" "}
                <br />
                Per i device <b> Samsung</b> bisogna recarsi nel menu{" "}
                <b> Connessioni &#62; NFC</b>. <br /> Se non trovi l’opzione
                relativa a NFC all’interno del menu delle impostazioni del
                dispositivo, è molto probabile che la tecnologia non sia
                supportata.
              </span>
            </div>
          </div>
          <div
            className={`d-flex mt-5 cursor-pointer flex-column align-items-center justify-content-center  col-sm-8 col-12 ${styles.faqRow}`}
            onClick={() => setdom7open(!dom7open)}
          >
            <div
              className={`d-flex flex-row align-items-center justify-content-start col-12 ${
                dom7open ? "pt-5" : ""
              }`}
            >
              <span
                className={`sub-title-faq col-1  d-flex flex-row align-items-center justify-content-center `}
              >
                &#x2022;
              </span>
              <span className={`sub-title-faq col-10  `}>
                Quali sono i dispositivi compatibili con la tecnologia NFC DLC
                Tech?
              </span>
              <span
                className={`sub-title-faq col-1  d-flex flex-row align-items-center justify-content-center `}
              >
                <FontAwesomeIcon
                  icon={(!dom7open ? faAngleDown : faAngleUp) as IconProp}
                />
              </span>
            </div>
            <div
              className={`${
                dom7open ? "d-flex" : "d-none"
              } flex-row pt-5 pb-5 text-faq col-10 ${styles.responseDiv}`}
            >
              La maggior parte dei telefoni moderni supporta la tecnologia NFC.
              In particolare è sempre presente negli iphone da IOS 13 o successi
              e da android 5 o successivi. Verifica la compatibilità del tuo
              dispositivo consultando le specifiche del produttore. Qui una
              lista aggiornata dei dispositivi compatibili con la tecnologia
              NFC:
            </div>
          </div>
          <div
            className={`d-flex mt-5 cursor-pointer flex-column align-items-center justify-content-center  col-sm-8 col-12 ${styles.faqRow}`}
            onClick={() => setdom8open(!dom8open)}
          >
            <div
              className={`d-flex flex-row align-items-center justify-content-start col-12 ${
                dom8open ? "pt-5" : ""
              }`}
            >
              <span
                className={`sub-title-faq col-1  d-flex flex-row align-items-center justify-content-center `}
              >
                &#x2022;
              </span>
              <span className={`sub-title-faq col-10  `}>
                Cosa devo fare se il mio telefono non rileva il link NFC?
              </span>
              <span
                className={`sub-title-faq col-1  d-flex flex-row align-items-center justify-content-center `}
              >
                <FontAwesomeIcon
                  icon={(!dom8open ? faAngleDown : faAngleUp) as IconProp}
                />
              </span>
            </div>
            <div
              className={`${
                dom8open ? "d-flex" : "d-none"
              } flex-row pt-5 pb-5 text-faq col-10 ${styles.responseDiv}`}
            >
              Se il tuo telefono non rileva il link NFC, assicurati che la
              funzione NFC sia attivata. Verifica anche che il prodotto DLC Tech
              sia correttamente posizionato e che non ci siano ostacoli tra il
              telefono e il dispositivo.
            </div>
          </div>
          <div
            className={`d-flex mt-5 cursor-pointer flex-column align-items-center justify-content-center  col-sm-8 col-12 ${styles.faqRow}`}
            onClick={() => setdom9open(!dom9open)}
          >
            <div
              className={`d-flex flex-row align-items-center justify-content-start col-12 ${
                dom9open ? "pt-5" : ""
              }`}
            >
              <span
                className={`sub-title-faq col-1  d-flex flex-row align-items-center justify-content-center `}
              >
                &#x2022;
              </span>
              <span className={`sub-title-faq col-10  `}>
                Cosa devo fare se ho problemi con la notifica contenente il link
                NFC?
              </span>
              <span
                className={`sub-title-faq col-1  d-flex flex-row align-items-center justify-content-center `}
              >
                <FontAwesomeIcon
                  icon={(!dom9open ? faAngleDown : faAngleUp) as IconProp}
                />
              </span>
            </div>
            <div
              className={`${
                dom9open ? "d-flex" : "d-none"
              } flex-row pt-5 pb-5 text-faq col-10 ${styles.responseDiv}`}
            >
              Se incontri problemi con la notifica, assicurati di avere una
              connessione Internet stabile. Inoltre, verifica che le
              impostazioni di notifica del tuo telefono consentano l'apertura di
              link NFC.
            </div>
          </div>
          <div
            className={`d-flex mt-5 cursor-pointer flex-column align-items-center justify-content-center  col-sm-8 col-12 ${styles.faqRow}`}
            onClick={() => setdom10open(!dom10open)}
          >
            <div
              className={`d-flex flex-row align-items-center justify-content-start col-12 ${
                dom10open ? "pt-5" : ""
              }`}
            >
              <span
                className={`sub-title-faq col-1  d-flex flex-row align-items-center justify-content-center `}
              >
                &#x2022;
              </span>
              <span className={`sub-title-faq col-10  `}>
                Cosa fare se il telefono non supporta la tecnologia NFC?
              </span>
              <span
                className={`sub-title-faq col-1  d-flex flex-row align-items-center justify-content-center `}
              >
                <FontAwesomeIcon
                  icon={(!dom10open ? faAngleDown : faAngleUp) as IconProp}
                />
              </span>
            </div>
            <div
              className={`${
                dom10open ? "d-flex" : "d-none"
              } flex-row pt-5 pb-5 text-faq col-10 ${styles.responseDiv}`}
            >
              Se il tuo telefono non supporta la tecnologia NFC, puoi ancora
              utilizzare i nostri prodotti tramite il codice QR Code stampato
              nella piastra metallica situata sotto al prodotto.
            </div>
          </div> */}
        </div>

        {/* MOBILE */}
        <div className="d-flex d-sm-none flex-column align-items-center p-5 justify-content-start col-12"></div>
        <Footer dark={false} />
      </div>
    </>
  );
};
export default FAQ;
