import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

import translationEn from "../../assets/locales/en/en.json";
import translationIt from "../../assets/locales/it/it.json";
i18next
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    fallbackLng: "en",
    resources: {
      en: {
        translation: translationEn,
      },
      it: {
        translation: translationIt,
      },
    },
    interpolation: {
      escapeValue: false,
    },
  });
