import React, { useEffect, useState } from "react";

import styles from "./BusinessCardEnvironmentPopUp.module.scss";

import { Modal } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faXmark } from "@fortawesome/free-solid-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

import parse from "html-react-parser";
import { useTranslation } from "react-i18next";

const environmentImg =
  "https://d16qu8ed6p45v5.cloudfront.net/dlctechwebsite/environmentDentro.png";
const albero1 =
  "https://d16qu8ed6p45v5.cloudfront.net/dlctechwebsite/albero1.png";
const albero2 =
  "https://d16qu8ed6p45v5.cloudfront.net/dlctechwebsite/albero2.png";
const alberidistanza =
  "https://d16qu8ed6p45v5.cloudfront.net/dlctechwebsite/distanza_alberi.png";

interface PopUpExampleProps {
  popup: boolean;
  close: () => void;
}

const BusinessCardEnvironmentPopUp: React.FC<PopUpExampleProps> = (props) => {
  const { t } = useTranslation();
  const [popup, setPopup] = useState(false);

  return (
    <>
      <Modal
        show={props.popup}
        onHide={() => (setPopup(!popup), props.close)}
        className={`d-flex flex-row justify-content-center align-items-center  `}
        style={{
          height: "100vh",
          width: "100vw",
          overflow: "scroll",
          //   border: "1px solid red",
          background: "rgba(0,0,0,0.85)",
        }}
        contentClassName={`${styles.bodyModal}  `}
      >
        <div className={`${styles.body} `}>
          <Modal.Body
            className={`d-flex flex-column align-items-start  justify-content-start p-4
            bg-light color-black 
            
            `}
            style={{
              height: "90vh",
              width: "90vw",
              borderRadius: "20px",
              overflow: "scroll",
              //   border: `2px solid red`,
            }}
          >
            <div
              className="d-flex flex-row align-items-center justify-content-end col-12 "
              style={{ height: "50px" }}
            >
              <span
                className={`${styles.closeButton} `}
                onClick={() => props.close()}
              >
                <FontAwesomeIcon icon={faXmark as IconProp} />
              </span>
            </div>
            <div className="ps-7 pe-7 d-md-flex d-none flex-column align-items-start justify-content-start col-12 ">
              <div className="d-flex flex-row align-items-center  justify-content-start col-12 ">
                <span className=" sub-title">
                  {parse(t(`Business_quadratoni_titoletto_3`))}
                  {/* Environment */}
                </span>
              </div>
              <div className="d-flex flex-row align-items-center  justify-content-start col-12 mt-4">
                <span className=" small-title fw-bold">
                  {parse(t(`Business_quadratoni3_titolo_interno`))}
                  {/* Stop  (wasting) */}
                </span>
              </div>
              <div className="d-flex flex-row align-items-center  justify-content-start col-12 mt-4">
                <span className=" sub-title fw-normal  col-12">
                  {parse(t(`Business_quadratoni3_testo_interno`))}
                  {/* Traditional paper production is resource-intensive and
                  polluting. <br /> Our NFC digital business cards represent the
                  alternative. */}
                </span>
              </div>
              <div className={`${styles.content} col-12  mt-5 `}>
                <div className="d-flex flex-row align-items-center justify-content-center col-12  ">
                  <div className=" col-4 h-100 d-flex  flex-column align-items-center justify-content-start">
                    <div className={`col-12   ${styles.imgContainer1}`}>
                      <img src={environmentImg} alt="environmentImg" />
                    </div>
                  </div>
                  <div className="col-8  h-100 d-flex  flex-column align-items-center justify-content-center ">
                    <div className="d-flex flex-row align-items-center justify-content-start  col-10 ps-5 ">
                      <span
                        className="light-title fw-bold  "
                        style={{ lineHeight: 1.1 }}
                      >
                        {parse(t(`Business_quadratoni3_testo_interno1`))}
                        {/* Reduce your environmental impact */}
                      </span>
                    </div>
                    <div className="d-flex flex-column align-items-start justify-content-center  col-10 ps-5 mt-10">
                      <span className="text-xxl  " style={{ lineHeight: 1.1 }}>
                        {parse(t(`Business_quadratoni3_testo_interno2`))}
                        {/* Approximately <b>88%</b> of <b> paper </b> business
                        cards <b> are discarted </b> within a week of receiving
                        them. */}
                      </span>
                      <span className="text-xxl  mt-7">
                        {parse(t(`Business_quadratoni3_testo_interno2_2`))}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className={`${styles.content} col-12  mt-5 `}>
                <div className="d-flex flex-row align-items-center justify-content-center col-12  ">
                  <div className="col-6  h-100 d-flex  flex-column align-items-center justify-content-center ">
                    <div className="d-flex flex-row align-items-center justify-content-start  col-10 ps-5 ">
                      <span
                        className="light-title fw-bold  "
                        style={{ lineHeight: 1.1 }}
                      >
                        {parse(t(`Business_quadrationi3_testo_interno3`))}
                        {/* 500'000 trees */}
                      </span>
                    </div>
                    <div className="d-flex flex-column align-items-start justify-content-center  col-10 ps-5 mt-4">
                      <span className="text-xxl  " style={{ lineHeight: 1.1 }}>
                        {parse(t(`Business_quadrationi3_testo_interno4`))}
                        {/* are torn down every year to produce paper business
                        cards. */}
                      </span>
                    </div>
                  </div>
                  <div className=" col-6 h-100 d-flex  flex-column align-items-center justify-content-start">
                    <div className={`col-12   ${styles.imgContainer1}`}>
                      <img src={albero1} alt="albero1" />
                    </div>
                  </div>
                </div>
              </div>
              <div className={`${styles.content} col-12  mt-5 `}>
                <div className="d-flex flex-row align-items-center justify-content-center col-12   ">
                  <div className="d-flex flex-row col-12 text-xxl justify-content-center p-4">
                    {parse(t(`Business_quadrationi3_testo_interno5`))}
                    {/* To help you visualizing, that's enough trees to cover the
                    distance between Venice and Los Angeles, every year. */}
                  </div>
                </div>
                <div
                  className={`${styles.bigImageContainer} mt-5 p-7 d-flex flex-row align-items-center justify-content-center`}
                >
                  <img src={alberidistanza} alt="alberiDistanza"/>
                </div>
              </div>
              {/* <div className={`${styles.content} col-12  mt-5 `}>
                <div className="d-flex flex-row align-items-center justify-content-center col-12  ">
                  <div className=" col-6 h-100 d-flex  flex-column align-items-center justify-content-start">
                    <div className={`col-12  ${styles.imgContainer1}`}>
                      <img src={albero2} alt="albero2"/>
                    </div>
                  </div>
                  <div className="col-6 h-100 d-flex  flex-column align-items-center justify-content-center ">
                    <div className="d-flex flex-row align-items-center justify-content-start  col-10 ps-5 ">
                      <span
                        className="light-title fw-bold  "
                        style={{ lineHeight: 1.1 }}
                      >
                        1 Card = 1 Tree
                      </span>
                    </div>
                    <div className="d-flex flex-column align-items-start justify-content-center  col-10 ps-5 mt-5">
                      <span className="text-xxl  " style={{ lineHeight: 1.1 }}>
                        For every DLC Business Card sold,
                        <br /> we are going to plant a tree.
                      </span>

                      <span
                        className="text-xxl mt-7 "
                        style={{ lineHeight: 1.1 }}
                      >
                        Our Partnership with <br /> ...
                      </span>
                      <span className={`${styles.greenBtn} col-3 mt-7`}>
                        Learn more
                      </span>
                    </div>
                  </div>
                </div>
              </div> */}
            </div>
            <div className=" d-flex d-sm-none flex-column align-items-start justify-content-start col-12 pb-4">
              <div className="d-flex flex-row align-items-center justify-content-start col-12 ">
                <span className=" sub-title">
                  {parse(t(`Business_quadratoni_titoletto_3`))}
                  {/* Environment */}
                </span>
              </div>
              <div className="d-flex flex-row align-items-center justify-content-start col-12 mt-4">
                <span
                  className=" small-title fw-bold"
                  style={{ lineHeight: "1.05" }}
                >
                  {parse(t(`Business_quadratoni3_titolo_interno`))}
                  {/* Stop the Waste */}
                </span>
              </div>
              <div className="d-flex flex-row align-items-center justify-content-start col-12 mt-4">
                <span className=" text fw-normal">
                  {parse(t(`Business_quadratoni3_testo_interno`))}
                  {/* Traditional paper production is resource-intensive and
                  polluting.
                  <br /> Our NFC digital business cards represent the
                  alternative. */}
                </span>
              </div>
              <div className={`${styles.content} col-12  mt-5  `}>
                <div className="d-flex flex-column align-items-start justify-content-start col-12  ">
                  <div className=" col-12  d-flex  flex-column align-items-center justify-content-center">
                    <div className={` col-12  ${styles.imgContainer1}`}>
                      <img src={environmentImg} alt="environmentImg" />
                    </div>
                  </div>
                  <div className="col-12   mt-4 d-flex  flex-column align-items-center justify-content-center pt-3">
                    <div className="d-flex flex-row align-items-center justify-content-center col-12">
                      <span
                        className="light-title fw-bold  text-center"
                        style={{ lineHeight: 1.1 }}
                      >
                        {parse(t(`Business_quadratoni3_testo_interno1`))}
                        {/* Reduce your environmental impact */}
                      </span>
                    </div>
                    <div className="d-flex flex-column align-items-center justify-content-center col-12 mt-4 p-3">
                      <span
                        className="text-xxl  text-center"
                        style={{ lineHeight: 1.1 }}
                      >
                        {parse(t(`Business_quadratoni3_testo_interno2`))}
                        {/* Approximately <b>88%</b> of <b> paper </b> business
                        cards <b> are discarted </b> within a week of receiving
                        them. <br /> */}
                        <br />
                        <br />
                        {parse(t(`Business_quadratoni3_testo_interno2_2`))}
                        {/* This means that out of the 100 billions paper business
                        cards produced yearly, almost <b>
                          90 billions
                        </b> are <b>trashed straight away.</b> */}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className={`${styles.content} col-12  mt-5  `}>
                <div className="d-flex flex-column align-items-start justify-content-start col-12  ">
                  <div className="col-12 h-50  mt-5 d-flex  flex-column align-items-center justify-content-center pt-3">
                    <div className="d-flex flex-row align-items-center justify-content-center col-12">
                      <span
                        className="light-title fw-bold  text-center"
                        style={{ lineHeight: 1.1 }}
                      >
                        {parse(t(`Business_quadrationi3_testo_interno3`))}
                        {/* 500'000 Trees */}
                      </span>
                    </div>
                    <div className="d-flex flex-column align-items-center justify-content-center col-12  p-3">
                      <span
                        className="text-xxl  text-center"
                        style={{ lineHeight: 1.1 }}
                      >
                        {parse(t(`Business_quadrationi3_testo_interno4`))}
                        {/* are torn down every year to produce paper business
                        cards. */}
                      </span>
                    </div>
                  </div>
                  <div className=" col-12  d-flex  flex-column align-items-center justify-content-center">
                    <div className={` col-12  ${styles.imgContainer1}`}>
                      <img src={albero1} alt="albero1" />
                    </div>
                  </div>
                </div>
              </div>
              <div className={`${styles.contentHorizontal} col-12  mt-5  `}>
                <div className="d-flex flex-column align-items-start justify-content-start col-12  ">
                  <div className="col-12  d-flex  flex-column align-items-center justify-content-center ">
                    <div className="d-flex flex-column align-items-center justify-content-center col-12  p-4">
                      <span
                        className="text-xxl  text-center"
                        style={{ lineHeight: 1.1 }}
                      >
                        {parse(t(`Business_quadrationi3_testo_interno5`))}
                        {/* To help you visualizing, that's enough trees to cover
                        the distance between Venice and Los Angeles, every year. */}
                      </span>
                    </div>
                  </div>
                  <div className=" col-12  d-flex  flex-column align-items-center justify-content-center">
                    <div className={`   ${styles.imgContainerHorizontal}`}>
                      <img src={alberidistanza} alt="alberiDistanza" />
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className={`${styles.content} col-12  mt-5  `}>
                <div className="d-flex flex-column align-items-start justify-content-start col-12  ">
                  <div className=" col-12  d-flex  flex-column align-items-center justify-content-center">
                    <div className={`   ${styles.imgContainer1}`}>
                      <img src={albero2} alt="albero2" />
                    </div>
                  </div>
                  <div className="col-12    d-flex  flex-column align-items-center justify-content-center pt-3">
                    <div className="d-flex flex-row align-items-center justify-content-center col-12">
                      <span
                        className="light-title fw-bold  text-center"
                        style={{ lineHeight: 1.1 }}
                      >
                        1 Card = 1 Tree
                      </span>
                    </div>
                    <div className="d-flex flex-column align-items-center justify-content-center col-12 mt-4 p-3">
                      <span
                        className="text-xxl  text-center"
                        style={{ lineHeight: 1.1 }}
                      >
                        For every Business Card sold, we are going to plant a
                        tree.
                      </span>
                      <span
                        className="text-xxl  text-center mt-4"
                        style={{ lineHeight: 1.1 }}
                      >
                        Our Partnership with ...
                      </span>
                      <span
                        className={`${styles.greenBtn} text-xxl col-6 mt-5`}
                        style={{ lineHeight: 1.1 }}
                      >
                        Learn more
                      </span>
                    </div>
                  </div>
                </div>
              </div> */}
            </div>
          </Modal.Body>
        </div>
      </Modal>
    </>
  );
};

export default BusinessCardEnvironmentPopUp;
