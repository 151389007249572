import React, { forwardRef, useEffect, useState } from "react";

import styles from "./DropDownButton.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faAngleUp } from "@fortawesome/free-solid-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

interface DropDownButtonProps {
  text: string;
  toggle: () => void;
  open: boolean;
}

const DropDownButton: React.FC<DropDownButtonProps> = (props) => {
  const [selectedValue, setSelectedValue] = useState("");

  return (
    <>
      <div
        className={`d-flex cursor-pointer flex-row align-items-center justify-content-between col-12 color-black  text-helvetica ${
          styles.styledBtn
        } ${props.open && styles.styledBtnOpen}`}
        onClick={props.toggle}
      >
        <span className="col-10 text-start ps-5 ">{props.text}</span>
        <span className="pe-5 text-center ">
          <FontAwesomeIcon
            icon={(props.open ? faAngleUp : faAngleDown) as IconProp}
            className=""
          />
        </span>
      </div>
    </>
  );
};

export default DropDownButton;
