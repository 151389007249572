import { useNavigate } from "react-router-dom";

import styles from "./Header.module.scss";
import { useTranslation } from "react-i18next";

import { useState } from "react";

const logoBlack =
  "https://d16qu8ed6p45v5.cloudfront.net/dlctechwebsite/LOGO_FINALE1.png";
const logoWhite =
  "https://d16qu8ed6p45v5.cloudfront.net/dlctechwebsite/LOGO_WHITE.png";

interface HeaderProps {
  dark: boolean;
  selected: string;
}

const lngs = {
  it: { nativeName: "Italiano" },
  en: { nativeName: "English" },
};

const Header: React.FC<HeaderProps> = (props) => {
  const { t, i18n } = useTranslation();
  const redirect = useNavigate();
  const path = window.location.pathname;
  const [language, setLanguage] = useState("it");

  const [lngOpen, setLngOpen] = useState(false);

  return (
    <>
      <div
        className=" d-flex flex-column flex-md-row col-12   align-items-md-center justify-content-md-between align-items-start justify-content-start "
        style={{ backgroundColor: `transparent` }}
      >
        <div
          className={`pe-5  mt-5  d-md-none  col-12    d-flex flex-row  align-items-center justify-content-end  `}
        >
          <div
            className={`border ${
              styles.flagsContainer
            } col-3  d-flex flex-row align-items-center justify-content-center ${
              props.dark ? "bg-white" : "bg-black"
            }`}
          >
            <div
              className={`${
                styles.flag
              } col-5 d-flex flex-row align-items-center justify-content-center ${
                props.dark
                  ? language === "it"
                    ? "bg-black color-white"
                    : "bg-white color-black"
                  : language === "it"
                  ? "bg-white color-black"
                  : "bg-black color-white"
              } `}
            >
              {/* <img
                src={italy}
                onClick={() => (
                  setLngOpen(!lngOpen),
                  setLanguage("it"),
                  i18n.changeLanguage("it")
                )}
              /> */}
              <span
                className={`sub-title `}
                onClick={() => (
                  setLngOpen(!lngOpen),
                  setLanguage("it"),
                  i18n.changeLanguage("it")
                )}
              >
                Ita
              </span>
            </div>
            <div
              className={`${
                styles.flag
              } col-5 ms-3 d-flex flex-row align-items-center justify-content-center  ${
                props.dark
                  ? language === "en"
                    ? "bg-black color-white"
                    : "bg-white color-black"
                  : language === "en"
                  ? "bg-white color-black"
                  : "bg-black color-white"
              }`}
            >
              <span
                className={`sub-title `}
                onClick={() => (
                  setLngOpen(!lngOpen),
                  setLanguage("en"),
                  i18n.changeLanguage("en")
                )}
              >
                Eng
              </span>
              {/* <img
                src={england}
                onClick={() => (
                  setLngOpen(!lngOpen),
                  setLanguage("en"),
                  i18n.changeLanguage("en")
                )}
              /> */}
            </div>
          </div>
        </div>
        <div
          className={`ps-5 ms-1   d-md-none  col-8  d-flex flex-column flex-md-row align-items-center justify-content-center ${styles.logo} `}
        >
          <img
            onClick={() => redirect("/")}
            className="cursor-pointer  "
            src={props.dark ? logoWhite : logoBlack}
            style={{ maxHeight: "10vh", maxWidth: "100%", zIndex: "2" }}
            alt="logo"
          />
        </div>
        <div className={`col-2     d-md-block d-none     ${styles.logo} `}>
          <img
            onClick={() => redirect("/")}
            className="cursor-pointer "
            src={props.dark ? logoWhite : logoBlack}
            style={{ maxHeight: "13vh", maxWidth: "85%" }}
            alt="logo"
          />
        </div>

        <div className="col-7    h-100  pb-4   d-flex flex-md-row align-items-md-start justify-content-md-start  d-none d-md-block  ">
          <span
            onClick={() => redirect("/Products")}
            className={`sub-title px-xl-5 px-2  color-dark_grey_50 cursor-pointer  ${
              styles.headerTitle
            } ${
              path.includes("Products") &&
              (props.dark ? styles.selectedDark : styles.selected)
            }`}
          >
            {t(`HeaderProducts`)}
          </span>
          <span
            onClick={() => redirect("/Services")}
            className={`sub-title px-xl-5 px-3 color-dark_grey_50 cursor-pointer  ${
              styles.headerTitle
            } ${
              path.includes("Services") &&
              (props.dark ? styles.selectedDark : styles.selected)
            }`}
          >
            {t(`HeaderServices`)}
          </span>
          <span
            onClick={() => redirect("/Contact-us")}
            className={`sub-title px-xl-5 px-3 color-dark_grey_50 cursor-pointer  ${
              styles.headerTitle
            } ${
              path.includes("Contact-us") &&
              (props.dark ? styles.selectedDark : styles.selected)
            }`}
          >
            {t(`HeaderContacts`)}
          </span>
          <span
            onClick={() => redirect("/Assistance")}
            className={`sub-title px-xl-5 px-3 color-dark_grey_50 cursor-pointer  ${
              styles.headerTitle
            } ${
              path.includes("Assistance") &&
              (props.dark ? styles.selectedDark : styles.selected)
            }`}
          >
            {t(`HeaderAssistance`)}
          </span>
          <span
            onClick={() => redirect("/AboutUs")}
            className={`sub-title px-xl-5 px-3 color-dark_grey_50 cursor-pointer  ${
              styles.headerTitle
            } ${
              path.includes("AboutUs") &&
              (props.dark ? styles.selectedDark : styles.selected)
            }`}
          >
            {t(`HeaderAbout`)}
          </span>
        </div>
        <div className="col-1  d-md-flex d-none flex-row  align-items-start justify-content-start ">
          <div className={`${styles.languageFlag}`}>
            {!lngOpen && (
              // <img
              //   src={language === "it" ? italy : england}
              //
              // />
              <div
                className={`sub-title ${
                  props.dark ? "color-white" : "color-black"
                }`}
                onClick={() => setLngOpen(!lngOpen)}
              >
                {language === "it" ? "Ita" : "Eng"}
              </div>
            )}
            {lngOpen && (
              <div
                className={`${styles.chooseLanguageDiv}  ${
                  props.dark ? "background-black" : "background-white"
                }`}
              >
                <div
                  className={` pt-2 d-flex flex-row align-items-center justify-content-center text-language ${
                    props.dark ? "color-white" : "color-black"
                  }`}
                >
                  {t(`language`)}
                  {/* Language */}
                </div>
                <div className="pt-2 d-flex flex-row align-items-center justify-content-center">
                  <div
                    className={`sub-title cursor-pointer ${
                      props.dark ? "color-white" : "color-black"
                    }`}
                    onClick={() => (
                      setLngOpen(!lngOpen),
                      setLanguage("it"),
                      i18n.changeLanguage("it")
                    )}
                  >
                    Ita
                  </div>
                  {/* <img src={italy} /> */}
                </div>
                <div className="pt-2 pb-2 d-flex flex-row align-items-center justify-content-center">
                  <div
                    className={`sub-title cursor-pointer ${
                      props.dark ? "color-white" : "color-black"
                    }`}
                    onClick={() => (
                      setLngOpen(!lngOpen),
                      setLanguage("en"),
                      i18n.changeLanguage("en")
                    )}
                  >
                    Eng
                  </div>
                  {/* <img
                    src={england}
                    onClick={() => (
                     
                    )}
                  /> */}
                </div>
              </div>
            )}
          </div>
        </div>

        {/* MOBILE */}
        <div
          className="col-12 flex-row   d-flex d-md-none d-block align-items-start justify-content-start  "
          style={{ height: "25vh" }}
        >
          <div
            className="col-6  flex-column   d-flex d-md-none d-block align-items-start justify-content-start  "
            style={{ height: "25vh" }}
          >
            <span
              onClick={() => redirect("/Products")}
              className={`sub-title-mob ps-5 mb-2 color-dark_grey_50 cursor-pointer  ${
                styles.headerTitle
              } ${
                path.includes("Products") &&
                (props.dark ? styles.selectedDark : styles.selected)
              }`}
            >
              {t(`HeaderProducts`)}
            </span>
            <span
              onClick={() => redirect("/Services")}
              className={`sub-title-mob ps-5 mb-2 color-dark_grey_50 cursor-pointer  ${
                styles.headerTitle
              } ${
                path.includes("Services") &&
                (props.dark ? styles.selectedDark : styles.selected)
              }`}
            >
              {t(`HeaderServices`)}
            </span>
            <span
              onClick={() => redirect("/Contact-us")}
              className={`sub-title-mob ps-5 mb-2 color-dark_grey_50 cursor-pointer  ${
                styles.headerTitle
              } ${
                path.includes("Contact-us") &&
                (props.dark ? styles.selectedDark : styles.selected)
              }`}
            >
              {t(`HeaderContacts`)}
            </span>
            <span
              onClick={() => redirect("/Assistance")}
              className={`sub-title-mob ps-5 mb-2  color-dark_grey_50 cursor-pointer  ${
                styles.headerTitle
              } ${
                path.includes("Assistance") &&
                (props.dark ? styles.selectedDark : styles.selected)
              }`}
            >
              {t(`HeaderAssistance`)}
            </span>
            <span
              onClick={() => redirect("/AboutUs")}
              className={`sub-title-mob ps-5  color-dark_grey_50 cursor-pointer  ${
                styles.headerTitle
              } ${
                path.includes("AboutUs") &&
                (props.dark ? styles.selectedDark : styles.selected)
              }`}
              style={{ fontSize: "5rem !important" }}
            >
              {t(`HeaderAbout`)}
            </span>
          </div>
        </div>
      </div>
    </>
  );
};
export default Header;
