import { useRef, useState } from "react";

import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";

import styles from "./Assistance.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeftLong } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import parse from "html-react-parser";
import { useForm } from "react-hook-form";
import axiosHelper from "../../helpers/axiosHelper";

const freccetta =
  "https://d16qu8ed6p45v5.cloudfront.net/dlctechwebsite/freccia nera.png";

const Assistance: React.FC = () => {
  const { t } = useTranslation();
  const redirect = useNavigate();
  const [hover, setHover] = useState(false);
  const [clientName, setClientName] = useState("");
  const [email, setEmail] = useState("");
  const [reason, setReason] = useState("");
  const [showText, setShowText] = useState(true);
  const [message, setMessage] = useState("");
  const [privacy, setPrivacy] = useState(false);

  const [formError, setFormError] = useState(false);
  const [formSuccess, setFormSuccess] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<AssistanceForm>();

  const onSubmit = async (data: AssistanceForm) => {
    console.log("submit");
    if (reason !== "") {
      data.reason = reason;
    } else {
      setFormError(true);
    }

    if (clientName !== "") {
      data.client_name = clientName;
    } else {
      setFormError(true);
    }

    if (email !== "") {
      data.email = email;
    } else {
      setFormError(true);
    }

    if (message !== "") {
      data.message = message;
    } else {
      setFormError(true);
    }

    if (privacy === false) {
      setFormError(true);
    }

    if (!formError) {
      await axiosHelper
        .assistanceReq(data)
        .then((response: any) => setFormSuccess(true));
    }
  };

  return (
    <>
      <Header dark={false} selected="Assistance" />

      <div
        className="d-flex flex-column align-items-start justify-content-start col-12 p-5 overflow-hidden "
        // style={{ height: "175vh" }}
      >
        <div className="d-md-flex d-none  flex-column align-items-start justify-content-start col-12 p-5">
          <div className="d-flex flex-row align-items-start justify-content-start bigger-title  color-dark_grey_50 col-12 ">
            {t(`assistance_title_1`)}
            {/* how can */}
          </div>
          <div className="d-flex flex-row align-items-start justify-content-start bigger-title col-12 color-black col-12  ">
            {t(`assistance_title_2`)}
            {/* we help you? */}
          </div>
        </div>
        <div className="d-flex d-md-none  flex-column align-items-start justify-content-start col-12 ">
          <div className="d-flex flex-row align-items-start justify-content-start bigger-title-mob  color-dark_grey_50 col-12 ">
            {t(`assistance_title_1`)}
            {/* how can */}
          </div>
          <div className="d-flex flex-row align-items-start justify-content-start bigger-title-mob col-12 color-black col-12  ">
            {t(`assistance_title_2`)}
            {/* we help you? */}
          </div>
        </div>
        <div
          className="d-md-flex d-none flex-row col-12 align-items-start justify-content-start  p-5"
          style={{ height: "60vh", marginTop: "12vh" }}
        >
          <div className={` col-sm-6 col-7  ${styles.videodiv}`}>
            <video
              width="100%"
              height="100%"
              controls
              onClick={() => setShowText(false)}
            >
              <source
                src={`https://d16qu8ed6p45v5.cloudfront.net/video_assistenza.mp4`}
                type="video/mp4"
              />
            </video>

            <div
              className={`  position-absolute  col-12   ps-5 small-title color-light ${
                !showText && "d-none"
              }`}
              style={{ marginTop: "37vh" }}
            >
              {t(`assistance_card1_title`)}
              {/* How does DLC work? */}
            </div>
            <div
              className="  position-absolute  col-12  ps-5 text-16 color-dark_grey_50 fw-bold"
              style={{ marginTop: "46vh" }}
            >
              {t(`assistance_card1_text`)}
              {/* Video tutorial - */}
              <span className="text-16-esquare">
                {t(`assistance_card1_rev`)}
              </span>
              {/* dlc review */}
              {t(`assistance_card1_and`)}
              {/* and */}
              <span className="text-16-esquare">
                {t(`assistance_card1_menu`)}
              </span>
              {/* dlc menu */}
            </div>
          </div>
          <div className="col-6 d-flex flex-row align-items-start  justify-content-start  h-75 ">
            <div
              className={`col-8  ms-5   ${styles.imagediv}`}
              onMouseEnter={() => setHover(true)}
              onMouseLeave={() => setHover(false)}
            >
              <div
                className={` col-12 ${!hover && "d-none"}  ${
                  styles.words
                }`}
              >
                <div>
                  <div className="d-flex flex-row col-12 small-title-assistance p-5  color-dark_grey_50 fw-bold">
                    {t(`assistance_card2_title`)}
                    {/* DLC require an nfc connection to work. */}
                  </div>
                  <div className="d-flex flex-row col-12 sub-title ps-5  color-white fw-bold">
                    {parse(t(`assistance_card2_ios`))}
                    {/* &#183; IOS 13 or later */}
                  </div>
                  <div className="d-flex flex-row col-12 sub-title ps-5  color-white fw-bold">
                    {parse(t(`assistance_card2_android`))}
                    {/* &#183; Android 5 or later */}
                  </div>
                </div>
                <div className="d-flex  flex-row col-12  ps-5 pe-5 pb-3  color-dark_grey_50 fw-bold">
                  {parse(t(`assistance_card2_info`))}
                  {/* For further informations contact-us using the form below. */}
                </div>
              </div>
            </div>
            <div className="col-4  px-5 d-flex flex-row align-items-end pb-5 h-100 flex-wrap  ">
              <div className="d-flex flex-column align-items-start justify-content-end  col-12">
                <span className="col-8  d-flex flex-row align-items-center justify-content-start sub-title-ml color-black mb-2 fw-bold">
                  {parse(t(`assistance_card2_usable`))}
                  {/* Usable devices */}
                </span>
                <span className="col-12  d-flex flex-row align-items-center justify-content-start text-16 color-dark_grey_50 fw-bold">
                  <FontAwesomeIcon
                    icon={faArrowLeftLong}
                    fontSize={"2rem"}
                    className="color-dark_grey_50 me-2"
                  />
                  {/* see the list */}
                </span>
              </div>
            </div>
          </div>
        </div>

        {/* MOBILE  */}
        <div className="d-flex d-md-none mt-7 flex-column align-items-center justify-content-start col-12 ">
          <div className={` col-12  ${styles.videodiv}`}>
            <video
              width="100%"
              height="100%"
              style={{}}
              controls
              onClick={() => setShowText(false)}
            >
              <source
                src={`https://d16qu8ed6p45v5.cloudfront.net/video_assistenza.mp4`}
                type="video/mp4"
              />
            </video>
            {/* <div className={`${styles.playBtn}`}>
              <FontAwesomeIcon
                icon={faPlay as IconProp}
                fontSize={"6vh"}
                className={`${styles.icon}`}
              />
            </div> */}
            <div
              className={`  position-absolute  col-12  pb-3 ps-5 small-title-mob color-light ${
                !showText && "d-none"
              }`}
              style={{ marginTop: "23vh" }}
            >
              {parse(t(`assistance_card1_title`))}
              {/* How does DLC work? */}
            </div>
            <div
              className="  position-absolute  col-12  ps-5 text-16-tab color-dark_grey_50 fw-bold"
              style={{ marginTop: "32vh" }}
            >
              {t(`assistance_card1_text`)}
              {/* Video tutorial - */}
              <span className="text-16-esquare">
                {t(`assistance_card1_rev`)}
              </span>
              {/* dlc review */}
              {t(`assistance_card1_and`)}
              {/* and */}
              <span className="text-16-esquare">
                {t(`assistance_card1_menu`)}
              </span>
              {/* dlc menu */}
              {/* Video tutorial - dlc reviews and dlc menu */}
            </div>
          </div>
          {/* <div className="col-12 mt-5 d-flex flex-row align-items-center justify-content-start  h-75 ">
            <div className={`col-8    ${styles.imagediv}`}></div>
            <div className="col-4   ps-3 d-flex flex-row align-items-end pb-5 h-100 flex-wrap  ">
              <div className="d-flex flex-column align-items-start justify-content-end  col-12">
                <span className=" d-flex flex-row align-items-center justify-content-start sub-title-mob color-black mb-2 fw-bold">
                  {parse(t(`assistance_card2_usable`))}
                  Discoverable devices
                </span>
                <span className="col-12  d-flex flex-row align-items-center justify-content-start text-16-tab color-dark_grey_50 fw-bold">
                  <FontAwesomeIcon
                    icon={faArrowLeftLong}
                    fontSize={"2rem"}
                    className="color-dark_grey_50 me-2"
                  />
                </span>
              </div>
            </div>
          </div> */}
          <div className="col-12 mt-5 d-flex flex-row align-items-center justify-content-end   ">
            <div
              className={`col-8    ${styles.faqdiv}`}
              onClick={() => redirect("/Assistance/FAQ")}
            >
              <span className="color-white bigger-title">
                {t(`assistance_card_faq`)}
                {/* FAQ */}
              </span>
              <div className={`${styles.freccetta} border`}>
                <img
                  src={freccetta}
                  alt="freccetta"
                  onClick={() => redirect("/Assistance/FAQ")}
                />
              </div>
            </div>
          </div>
          <div
            className={`col-12  d-flex flex-column align-items-center justify-content-center `}
          >
            <div className={`col-12 mb-5 p-5 ${styles.requestdiv}`}>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="d-flex flex-row align-items-center justify-content-start   color-light small-title">
                  {t(`assistance_form_title`)}
                  {/* Request assistance */}
                </div>

                <div
                  className="d-flex flex-row ps-5  pb-1 "
                  style={{ paddingTop: "1.9rem" }}
                >
                  <span className="sub-title color-white">
                    {t(`assistance_form_name`)}
                    {/* Business name */}
                  </span>
                </div>
                <div className="d-flex flex-row pt-2">
                  <div className={`${styles.inputDiv}`}>
                    <input
                      type="text"
                      onChange={(e: any) => setClientName(e.target.value)}
                      value={clientName}
                      className=" col-12  d-flex ps-5 flex-row align-items-center justify-content-center  text-16 color-white"
                      placeholder={`${t(`assistance_form_name_placeholder`)}`}
                    />
                  </div>
                </div>
                <div
                  className="d-flex flex-row ps-5  pb-1 "
                  style={{ paddingTop: "1.9rem" }}
                >
                  <span className="sub-title color-white">
                    {t(`assistance_form_mail`)}
                    {/* Mail */}
                  </span>
                </div>
                <div className="d-flex flex-row  pt-2">
                  <div className={`${styles.inputDiv}`}>
                    <input
                      type="email"
                      onChange={(e: any) => setEmail(e.target.value)}
                      value={email}
                      className=" col-12  d-flex ps-5 flex-row align-items-center justify-content-center  text-16 color-white"
                      placeholder={`${t(`assistance_form_mail_placeholder`)}`}
                    />
                  </div>
                </div>
                <div
                  className="d-flex flex-row align-items-center justify-content-start ps-5 sub-title color-white"
                  style={{ paddingTop: "1.9rem" }}
                >
                  {t(`assistance_form_choose`)}
                  {/* Choose: */}
                </div>
                <div className="d-flex flex-column align-items-center justify-content-between  color-white col-12 pt-2 flex-wrap ">
                  <div
                    className={`${
                      reason === "reviews"
                        ? styles.choosenDiv
                        : styles.choicediv
                    } cursor-pointer`}
                    onClick={() => setReason("reviews")}
                  >
                    {t(`assistance_form_rev`)}
                    {/* REVIEWS */}
                  </div>
                  <div
                    className={`${
                      reason === "business card"
                        ? styles.choosenDiv
                        : styles.choicediv
                    } cursor-pointer`}
                    onClick={() => setReason("business card")}
                  >
                    {t(`assistance_form_men`)}
                    {/* MENU */}
                  </div>
                  <div
                    className={`${
                      reason === "services"
                        ? styles.choosenDiv
                        : styles.choicediv
                    } cursor-pointer`}
                    onClick={() => setReason("services")}
                  >
                    {t(`assistance_form_serv`)}
                    {/* SERVICES */}
                  </div>
                  <div
                    className={`${
                      reason === "other" ? styles.choosenDiv : styles.choicediv
                    } cursor-pointer`}
                    onClick={() => setReason("other")}
                  >
                    {t(`assistance_form_other`)}
                    {/* OTHER */}
                  </div>
                </div>
                <div
                  className="d-flex flex-row align-items-center justify-content-start ps-5 sub-title color-white "
                  style={{ paddingTop: "1.9rem" }}
                >
                  {t(`assistance_form_problem`)}
                  {/* Describe the problem */}
                </div>
                <div className="col-12 mt-2 d-flex flex-row align-items-center justify-content-center">
                  <textarea
                    onChange={(e: any) =>
                      e.target.value.length < 150 && setMessage(e.target.value)
                    }
                    value={message}
                    className="d-flex flex-row col-12 text-16 ps-5 pt-4 color-white"
                    placeholder={`${t(`assistance_form_problem_placeholder`)}`}
                  />
                </div>
                <div className="d-flex flex-row align-items-start justify-content-start col-12 mt-5 color-white ">
                  <div className="col-2  ">
                    <label className={`${styles.checkboxContainer}  `}>
                      <input
                        type="checkbox"
                        // checked={privacy}
                        onClick={() => setPrivacy(!privacy)}
                      />
                      <span className={`${styles.checkmark} `}></span>
                    </label>
                  </div>
                  <div className="col-10  " style={{ fontSize: "1.2rem" }}>
                    {parse(t(`Business_preventivo_testo_privacypolicy_before`))}
                    <a href="/Privacy-Policy" className="color-white ">
                      privacy policy
                    </a>
                    {` `}
                    {parse(t(`Business_preventivo_testo_privacypolicy_after`))}
                  </div>
                </div>
                <div className="mt-5 text-end  text col-12 ">
                  {formError === true && (
                    <span style={{ color: "red" }}>
                      {parse(t(`Business_preventivo_error_text`))}
                      {/* Fill all the required fields and accept the privacy policy */}
                    </span>
                  )}
                  {formSuccess === true && (
                    <span style={{ color: "white" }}>
                      {parse(t(`Business_preventivo_success_text`))}
                      {/* Your request has been sent. <br /> You will be contacted
                      as soon as possible */}
                    </span>
                  )}
                </div>
                <div className="d-flex flex-row align-items-center justify-content-end  col-12 mt-5">
                  <button
                    className="d-flex flex-row align-items-center justify-content-center col-3  fw-bold sub-title cursor-pointer"
                    style={{
                      backgroundColor: "white",
                      height: "45px",
                      borderRadius: "20px",
                      border: "0px",
                    }}
                    type="submit"
                  >
                    {t(`assistance_form_send`)}
                    {/* Send */}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>

        <div className="col-12 d-md-flex d-none flex-row align-items-start justify-content-between  ">
          <div className="col-6 d-flex  flex-column align-items-end  justify-content-between pt-5 h-75 ">
            <div
              className={`col-8  ms-5  cursor-pointer ${styles.faqdiv}`}
              onClick={() => redirect("/Assistance/FAQ")}
            >
              <span className="color-white bigger-title">{t(`faq_title`)}</span>
              <div className={`${styles.freccetta} border`}>
                <img
                  src={freccetta}
                  alt="freccetta"
                  onClick={() => redirect("/Assistance/FAQ")}
                />
              </div>
            </div>
            {/* <div className="col-8 ">
              <div
                className={`col-12  d-flex flex-column align-items-start  justify-content-center border pe-5 h-50`}
              >
                <span className="sub-title color-black fw-bold d-flex flex-row align-items-center justify-content-start mt-10 ">
                  <span className="text fw-bold me-2 ">&#9312; </span> Return
                  policies assistance
                </span>
                <span className="sub-title color-black fw-bold d-flex flex-row align-items-center justify-content-start mt-4 ">
                  <span className="text fw-bold me-2">&#9313; </span> Warranty
                  assistance
                </span>
                <span className="sub-title color-black fw-bold d-flex flex-row align-items-center justify-content-start mt-4">
                  <span className="text fw-bold me-2">&#9314; </span>{" "}
                  After-sales assistance
                </span>
              </div>
            </div> */}
          </div>

          <div
            className={`col-6  d-flex flex-column align-items-center justify-content-center `}
          >
            <div className={`col-11  p-7 ${styles.requestdiv}`}>
              <div className="d-flex flex-row align-items-center justify-content-start   color-light small-title">
                {t(`assistance_form_title`)}
                {/* Request assistance */}
              </div>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div
                  className="d-flex flex-row ps-5  pb-1 "
                  style={{ paddingTop: "1.9rem" }}
                >
                  <span className="sub-title color-white">
                    {t(`assistance_form_name`)}
                    {/* Business name */}
                  </span>
                </div>
                <div className="d-flex flex-row pt-2">
                  <div className={`${styles.inputDiv}`}>
                    <input
                      type="text"
                      {...register("client_name", {
                        onChange: (e) => setClientName(e.target.value),
                      })}
                      value={clientName}
                      className=" col-12  d-flex ps-5 flex-row align-items-center justify-content-center  text-16 color-white"
                      placeholder={`${t(`assistance_form_name_placeholder`)}`}
                    />
                  </div>
                </div>
                <div
                  className="d-flex flex-row ps-5  pb-1 "
                  style={{ paddingTop: "1.9rem" }}
                >
                  <span className="sub-title color-white">
                    {t(`assistance_form_mail`)}
                    {/* Mail */}
                  </span>
                </div>
                <div className="d-flex flex-row  pt-2">
                  <div className={`${styles.inputDiv}`}>
                    <input
                      type="email"
                      {...register("email", {
                        onChange: (e) => setEmail(e.target.value),
                      })}
                      value={email}
                      className=" col-12  d-flex ps-5 flex-row align-items-center justify-content-center  text-16 color-white"
                      placeholder={`${t(`assistance_form_mail_placeholder`)}`}
                    />
                  </div>
                </div>
                <div
                  className="d-flex flex-row align-items-center justify-content-start ps-5 sub-title color-white"
                  style={{ paddingTop: "1.9rem" }}
                >
                  Choose:
                </div>
                <div className="d-flex flex-row align-items-center justify-content-between   color-white col-12 pt-2  ">
                  <div
                    className={`${
                      reason === "reviews"
                        ? styles.choosenDiv
                        : styles.choicediv
                    } cursor-pointer`}
                    onClick={() => setReason("reviews")}
                  >
                    {t(`assistance_form_rev`)}
                    {/* REVIEWS */}
                  </div>
                  <div
                    className={`${
                      reason === "business card"
                        ? styles.choosenDiv
                        : styles.choicediv
                    } cursor-pointer`}
                    onClick={() => setReason("business card")}
                  >
                    {t(`assistance_form_men`)}
                    {/* MENU */}
                  </div>
                  <div
                    className={`${
                      reason === "services"
                        ? styles.choosenDiv
                        : styles.choicediv
                    } cursor-pointer`}
                    onClick={() => setReason("services")}
                  >
                    {t(`assistance_form_serv`)}
                    {/* SERVICES */}
                  </div>
                  <div
                    className={`${
                      reason === "other" ? styles.choosenDiv : styles.choicediv
                    } cursor-pointer`}
                    onClick={() => setReason("other")}
                  >
                    {t(`assistance_form_other`)}
                    {/* OTHER */}
                  </div>
                </div>
                <div
                  className="d-flex flex-row align-items-center justify-content-start ps-5 sub-title color-white "
                  style={{ paddingTop: "1.9rem" }}
                >
                  {t(`assistance_form_problem`)}
                  {/* Describe the problem */}
                </div>
                <div className="col-12 mt-2 d-flex flex-row align-items-center justify-content-center">
                  <textarea
                    {...register("message", {
                      onChange: (e) =>
                        e.target.value.length < 150 &&
                        setMessage(e.target.value),
                    })}
                    value={message}
                    className="d-flex flex-row col-12 text-16 ps-5 pt-4 color-white"
                    placeholder={`${t(`assistance_form_problem_placeholder`)}`}
                  />
                </div>
                <div className="d-flex flex-row align-items-start justify-content-start col-12 mt-5  ">
                  <div className="col-1  ">
                    <label className={`${styles.checkboxContainer}  `}>
                      <input
                        type="checkbox"
                        // checked={privacy}
                        onClick={() => setPrivacy(!privacy)}
                      />
                      <span className={`${styles.checkmark} `}></span>
                    </label>
                  </div>
                  <div
                    className="col-11 pe-3 color-white  "
                    style={{ fontSize: "1.2rem" }}
                  >
                    {parse(t(`Business_preventivo_testo_privacypolicy_before`))}
                    <a href="/Privacy-Policy" className="color-white ">
                      privacy policy
                    </a>
                    {` `}
                    {parse(t(`Business_preventivo_testo_privacypolicy_after`))}
                  </div>
                </div>
                <div className="mt-5 text-end pe-5 text col-12 ">
                  {formError === true && (
                    <span style={{ color: "red" }}>
                      {parse(t(`Business_preventivo_error_text`))}
                      {/* Fill all the required fields and accept the privacy policy */}
                    </span>
                  )}
                  {formSuccess === true && (
                    <span style={{ color: "white" }}>
                      {parse(t(`Business_preventivo_success_text`))}
                      {/* Your request has been sent. <br /> You will be contacted
                      as soon as possible */}
                    </span>
                  )}
                </div>
                <div className="d-flex flex-row align-items-center justify-content-end  col-12 mt-5">
                  <button
                    className="d-flex cursor-pointer flex-row align-items-center justify-content-center col-3 border fw-bold sub-title"
                    style={{
                      backgroundColor: "white",
                      height: "45px",
                      borderRadius: "20px",
                    }}
                    type="submit"
                  >
                    {t(`assistance_form_send`)}
                    {/* Send */}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <Footer dark={false} />
    </>
  );
};
export default Assistance;
