import styles from "./../Services/Services.module.scss";

import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const websiteCreation =
  "https://d16qu8ed6p45v5.cloudfront.net/dlctechwebsite/mockup mac2.png";

const Services: React.FC = () => {
  const redirect = useNavigate();
  const { t } = useTranslation();

  return (
    <>
      <div className="overflow-hidden">
        <Header dark={false} selected="Services" />
        <div
          className="d-sm-flex d-none  flex-row align-items-center justify-content-center col-12"
          style={{ height: "88vh" }}
        >
          {/* <div className="col-2  d-flex flex-column align-items-center  h-75 "></div> */}

          <div className="col-xl-3 col-sm-4 d-flex   flex-column align-items-center h-75 ">
            <div
              className={`col-12  h-75  d-flex flex-row align-items-center justify-content-center ${styles.productsDiv}`}
              style={{ borderRadius: "70px" }}
            >
              <img
                src={websiteCreation}
                alt="websiteCreationImage"
                style={{ maxWidth: "150%" }}
                className="cursor-pointer"
                onClick={() => redirect("/Services/WebsiteCreation")}
              />
            </div>
            <div className="col-12 h-25  d-flex flex-column align-items-center justify-content-start ">
              <div className="mt-3 ">
                <span className="esquare-title-prod color-black">
                  Website Creation
                </span>
              </div>
              <div className="mt-3 col-12  d-flex flex-row aling-items-center justify-content-center">
                <span
                  className={`${styles.detailsBtn} col-8 sub-title`}
                  onClick={() => redirect("/Services/WebsiteCreation")}
                >
                  {t(`Products_btn`)}
                </span>
              </div>
            </div>
          </div>
        </div>

        {/* MOBILE */}
        <div className="d-flex d-sm-none flex-column align-items-center p-5 justify-content-start col-12">
          <div
            className="col-12  d-flex flex-column align-items-center h-75  "
            // style={{ marginTop: "0vh" }}
          >
            <div
              className={`col-12  h-75 d-flex flex-row align-items-center justify-content-center `}
              style={{ borderRadius: "70px" }}
            >
              <img
                src={websiteCreation}
                alt="websitecreationImage"
                style={{ maxWidth: "150%" }}
                onClick={() => redirect("/Services/WebsiteCreation")}
              />
            </div>
            <div className="col-12 mb-5  d-flex flex-column align-items-center justify-content-start ">
              <div className="mt-3 ">
                <span className="esquare-title-prod color-black">
                  Website Creation
                </span>
              </div>
              {/* <div className="mt-5 ">
                <span className="text-16"> $ 80</span>
              </div> */}
            </div>
          </div>
        </div>
        <Footer dark={false} />
      </div>
    </>
  );
};
export default Services;
