import React, { useState } from "react";

import styles from "./ReviewsTechnologyPopUp.module.scss";

import { Modal } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

import parse from "html-react-parser";
import { useTranslation } from "react-i18next";

const manorev =
  "https://d16qu8ed6p45v5.cloudfront.net/dlctechwebsite/manoreview.png";

const revback =
  "https://d16qu8ed6p45v5.cloudfront.net/dlctechwebsite/revBack.png";
const detectablearea =
  "https://d16qu8ed6p45v5.cloudfront.net/dlctechwebsite/DetectableArea.png";
interface PopUpExampleProps {
  popup: boolean;
  close: () => void;
}

const ReviewsTechnologyPopUp: React.FC<PopUpExampleProps> = (props) => {
  const { t } = useTranslation();

  const [popup, setPopup] = useState(false);

  return (
    <>
      <Modal
        show={props.popup}
        onHide={() => (setPopup(!popup), props.close)}
        className={`d-flex flex-row justify-content-center align-items-center  `}
        style={{
          height: "100vh",
          width: "100vw",
          overflow: "scroll",
          background: "rgba(0,0,0,0.85)",
        }}
        contentClassName={`${styles.bodyModal}  `}
      >
        <div className={`${styles.body} `}>
          <Modal.Body
            className={`d-flex flex-column align-items-start  justify-content-start p-4
            bg-light color-black 
            `}
            style={{
              height: "90vh",
              width: "90vw",
              borderRadius: "20px",
              overflow: "scroll",
            }}
          >
            <div
              className="d-flex flex-row align-items-center justify-content-end col-12 "
              style={{ height: "50px" }}
            >
              <span
                className={`${styles.closeButton} `}
                onClick={() => props.close()}
              >
                <FontAwesomeIcon icon={faXmark as IconProp} />
              </span>
            </div>
            <div className="ps-7 pe-7 d-md-flex d-none flex-column align-items-start justify-content-start col-12 ">
              <div className="d-flex flex-row align-items-center  justify-content-start col-12 ">
                <span className=" sub-title">
                  {parse(t(`Review_quadratoni_titoletto_2`))}
                  {/* Technology */}
                </span>
              </div>
              <div className="d-flex flex-row align-items-center  justify-content-start col-12 mt-4">
                <span className=" small-title fw-bold">
                  {parse(t(`Review_quadratoni_titolo_2_interno`))}
                  {/* SpeedLovers */}
                </span>
              </div>
              <div className={`${styles.content} col-12  mt-5 `}>
                <div className="d-flex flex-row align-items-center justify-content-center col-12  ">
                  <div className=" col-6 h-100 d-flex  flex-column align-items-center justify-content-start">
                    <span className="small-title mt-5">NFC</span>
                    <div className={`col-12 mt-3    ${styles.imgContainer1}`}>
                      <img src={manorev} alt="reviewstechnologyhand" />
                    </div>
                  </div>
                  <div className="col-6  h-100 d-flex  flex-column align-items-start justify-content-center ps-7">
                    <span className="light-title fw-bold">
                      {parse(t(`Review_quadratoni_2_interno2`))}
                      {/* GET CLOSE */}
                    </span>
                    <span className="sub-title fw-normal ">
                      {parse(t(`Review_quadratoni_2_interno3`))}
                      {/* with your phone to the product. */}
                    </span>
                    <span className="light-title fw-bold mt-7">
                      {parse(t(`Review_quadratoni_2_interno4`))}
                      {/* CLICK */}
                    </span>
                    <span className="sub-title fw-normal ">
                      {parse(t(`Review_quadratoni_2_interno5`))}
                      {/* on the nfc notification. */}
                    </span>
                    <span className="light-title fw-bold mt-7">
                      {parse(t(`Review_quadratoni_2_interno6`))}
                      {/* DONE. */}
                    </span>
                  </div>
                  \
                </div>
              </div>
              <div className={`${styles.content} col-12  mt-5 `}>
                <div className="d-flex flex-row align-items-center justify-content-center col-12  ">
                  <div className="col-6  h-100 d-flex flex-column align-items-start justify-content-center ps-7">
                    <span className="small-title fw-bold">NFC</span>
                    <span
                      className="light-title fw-bold "
                      style={{ color: "#afd774" }}
                    >
                      {parse(t(`Review_quadratoni_2_interno8`))}
                      {/* Detectable Area */}
                    </span>
                  </div>
                  <div className=" col-6 h-100 d-flex flex-column align-items-center justify-content-start">
                    <div className={`col-12 mt-3   ${styles.imgContainer1}`}>
                      <img src={detectablearea} alt="reviewsdetectablearea" />
                    </div>
                  </div>
                </div>
              </div>
              <div className={`${styles.content} col-12  mt-5 `}>
                <div className="d-flex flex-row align-items-center justify-content-center col-12  ">
                  <div className=" col-6 h-100 d-flex flex-column align-items-center justify-content-start">
                    <span className="small-title mt-5">QR CODE</span>
                    <div className={`col-12 mt-3   ${styles.imgContainer1}`}>
                      <img src={revback} alt="reviewsback" />
                    </div>
                  </div>
                  <div className="col-6  h-100 d-flex flex-column align-items-start justify-content-center ps-7">
                    <span className="light-title fw-bold">
                      {parse(t(`Review_quadratoni_2_interno10`))}
                      {/* SCAN */}
                    </span>
                    <span className="sub-title fw-normal ">
                      {parse(t(`Review_quadratoni_2_interno11`))}
                      {/* the QR code under the product. */}
                    </span>
                    <span className="light-title fw-bold mt-7">
                      {parse(t(`Review_quadratoni_2_interno12`))}
                      {/* CLICK. */}
                    </span>

                    <span className="light-title fw-bold mt-7">
                      {parse(t(`Review_quadratoni_2_interno13`))}
                      {/* DONE. */}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className=" d-flex d-sm-none flex-column align-items-start justify-content-start col-12 pb-4">
              <div className="d-flex flex-row align-items-center justify-content-start col-12 ">
                <span className=" sub-title">
                  {parse(t(`Review_quadratoni_titoletto_2`))}
                  {/* Technology */}
                </span>
              </div>
              <div className="d-flex flex-row align-items-center justify-content-start col-12 mt-4">
                <span className=" small-title fw-bold">
                  {parse(t(`Review_quadratoni_titolo_2_interno`))}
                  {/* SpeedLovers */}
                </span>
              </div>
              <div className={`${styles.content} col-12  mt-5  `}>
                <span className=" col-12 text-center small-title mt-4 ">
                  NFC
                </span>
                <div className={`${styles.imgContainer1} col-12  `}>
                  <img src={manorev} alt="reviewstechnologyhand" />
                </div>
                <div className=" d-flex flex-column  align-items-center justify-content-center text-center col-12">
                  <span className="light-title fw-bold mt-7">
                    {parse(t(`Review_quadratoni_2_interno2`))}
                    {/* GET CLOSE */}
                  </span>
                  <span className="sub-title fw-normal ">
                    {parse(t(`Review_quadratoni_2_interno3`))}
                    {/* with your phone to the product. */}
                  </span>
                  <span className="light-title fw-bold mt-5 ">
                    {parse(t(`Review_quadratoni_2_interno4`))}
                    {/* CLICK */}
                  </span>
                  <span className="sub-title fw-normal ">
                    {parse(t(`Review_quadratoni_2_interno5`))}
                    {/* on the nfc notification. */}
                  </span>
                  <span className="light-title fw-bold mt-5">
                    {parse(t(`Review_quadratoni_2_interno13`))}
                    {/* DONE. */}
                  </span>
                </div>
              </div>
              <div className={`${styles.content} col-12  mt-5  `}>
                <div className="d-flex flex-column align-items-center justify-content-center h-50  col-12">
                  <span className="small-title">NFC </span>
                  <span
                    className="light-title fw-bold mt-3"
                    style={{ color: "#afd774" }}
                  >
                    {parse(t(`Review_quadratoni_2_interno8`))}
                    {/* Detectable Area */}
                  </span>
                </div>

                <div className={`${styles.imgContainer1}  `}>
                  <img src={detectablearea} alt="reviewsdetectablearea" />
                </div>
              </div>
              <div className={`${styles.content} col-12  mt-5  `}>
                <span className=" col-12 text-center small-title mt-4 ">
                  QR CODE
                </span>
                <div className={`${styles.imgContainer1}  `}>
                  <img src={revback} alt="reviewsback" />
                </div>
                <div className="d-flex flex-column align-items-center justify-content-center text-center  col-12 ">
                  <span className="light-title fw-bold mt-7">
                    {parse(t(`Review_quadratoni_2_interno10`))}
                    {/* GET CLOSE */}
                  </span>
                  <span className="sub-title fw-normal ">
                    {parse(t(`Review_quadratoni_2_interno11`))}
                    {/* with your phone to the product. */}
                  </span>
                  <span className="light-title fw-bold mt-5 ">
                    {parse(t(`Review_quadratoni_2_interno12`))}
                    {/* CLICK */}
                  </span>
                  <span className="sub-title fw-normal ">
                    {parse(t(`Review_quadratoni_2_interno14`))}
                    {/* on the nfc notification. */}
                  </span>
                  <span className="light-title fw-bold mt-5">
                    {parse(t(`Review_quadratoni_2_interno13`))}
                    {/* DONE. */}
                  </span>
                </div>
              </div>
            </div>
          </Modal.Body>
        </div>
      </Modal>
    </>
  );
};

export default ReviewsTechnologyPopUp;
