import { useNavigate } from "react-router-dom";
import styles from "./ProductCard.module.scss";

import parse from "html-react-parser";
import { useTranslation } from "react-i18next";

interface ProductCardProps {
  name: string;
  image: string;
  link: string;
}

const ProductCard: React.FC<ProductCardProps> = (props) => {
  const { t } = useTranslation();

  const redirect = useNavigate();

  return (
    <>
      <div
        className={`col-xl-4 col-sm-5 col-12 d-flex flex-column align-items-center justify-content-center ${styles.prod}`}
      >
        <span className={`${styles.prodImg} pb-md-10`}>
          <img src={props.image} alt="productCardImg" />
        </span>
        <span className="light-title-esquare-prod color-black mt-4">
          {props.name}
        </span>
        <span
          className={` mt-4 ${styles.prodBtn} sub-title color-white cursor-pointer`}
          onClick={() => redirect(props.link)}
        >
          {parse(t(`AboutUs_bottone2`))}
          {/* Discover */}
        </span>
      </div>
    </>
  );
};

export default ProductCard;
