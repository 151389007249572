import { useEffect, useRef, useState } from "react";

import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";

import styles from "./AnnexB.module.scss";

import { useTranslation } from "react-i18next";
import parse from "html-react-parser";

const AnnexB: React.FC = () => {
  const { t } = useTranslation();

  const rightDivRef = useRef<null | HTMLDivElement>(null);
  const [scrollTop, setScrollTop] = useState(0);
  const ref = useRef<null | HTMLDivElement>(null);
  const refImageMob = useRef<null | HTMLDivElement>(null);
  const refMobile = useRef<null | HTMLDivElement>(null);

  const [imageN, setImageN] = useState(0);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleScroll = () => {
    let vDistance = window.scrollY;

    if (rightDivRef.current) {
      console.log(rightDivRef.current.getBoundingClientRect().top, vDistance);
      setScrollTop(vDistance);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <div className="bg-black ">
        <Header dark={true} selected="Assistance" />
        <div
          className="d-md-flex   flex-column align-items-center  justify-content-center col-12  p-7"
          style={{ backgroundColor: "black" }}
        >
          <div className="d-flex flex-row align-items-center justify-content-center color-white small-title">
            {parse(t(`annex_b_title`))}
          </div>

          <div className="d-flex flex-column align-items-center justify-content-center col-md-6 col-12  mt-7 color-white text">
            <span className="d-flex flex-row col-12 align-items-center justify-content-start">
              {parse(t(`annex_b_text_1`))}
            </span>
            <span className="d-flex flex-row col-12 mt-5 sub-title">
              {parse(t(`annex_b_subtitle_1`))}
            </span>
            <span className="d-flex flex-row col-12 mt-3 ">
              {parse(t(`annex_b_text_2`))}
            </span>
            <span className="d-flex flex-row col-12 mt-5 sub-title">
              {parse(t(`annex_b_subtitle_2`))}
            </span>
            <span className="d-flex flex-row col-12 mt-3 ">
              {parse(t(`annex_b_text_3`))}
            </span>
            <span className="d-flex flex-row col-12 mt-5 sub-title">
              {parse(t(`annex_b_subtitle_3`))}
            </span>
            <span className="d-flex flex-row col-12 mt-3">
              {parse(t(`annex_b_text_4`))}
            </span>
            <span className="d-flex flex-row col-12 mt-5 sub-title">
              {parse(t(`annex_b_subtitle_4`))}
            </span>
            <span className="d-flex flex-row col-12 mt-3">
              {parse(t(`annex_b_text_5`))}
            </span>
            <span className="d-flex flex-row col-12 mt-5 sub-title">
              {parse(t(`annex_b_subtitle_6`))}
            </span>
            <span className="d-flex flex-row col-12 mt-3">
              {parse(t(`annex_b_text_6`))}
            </span>
            <span className="d-flex flex-row col-12 mt-5 sub-title">
              {parse(t(`annex_b_subtitle_7`))}
            </span>
            <span className="d-flex flex-row col-12 mt-3">
              {parse(t(`annex_b_text_7`))}
            </span>
          </div>
        </div>

        <Footer dark={true} />
      </div>
    </>
  );
};
export default AnnexB;
