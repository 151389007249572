import React, { useEffect, useState } from "react";

import styles from "./AboutUsPopUp.module.scss";

import { Modal } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faXmark } from "@fortawesome/free-solid-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import parse from "html-react-parser";
import { useTranslation } from "react-i18next";

interface PopUpText {
  title: string;
  text: string;
}

interface PopUpExampleProps {
  image: string;
  popup: boolean;
  close: () => void;
  title: string;
  leftText: PopUpText[];
  rightText: PopUpText[];
  bgColor: string;
}

const AboutUsPopUp: React.FC<PopUpExampleProps> = (props) => {
  const { t } = useTranslation();
  const [popup, setPopup] = useState(false);

  return (
    <>
      <Modal
        show={props.popup}
        onHide={() => (setPopup(!popup), props.close)}
        className={`d-flex flex-row justify-content-center align-items-center  `}
        style={{
          height: "100vh",
          width: "100vw",
          overflow: "scroll",
          // border: "1px solid red",
          background: "rgba(0,0,0,0.85)",
        }}
        contentClassName={`${styles.bodyModal}  `}
      >
        <div className={`${styles.body} `}>
          <Modal.Body
            className={`d-flex flex-column align-items-start  justify-content-start p-4 color-black 
            
            `}
            style={{
              height: "90vh",
              width: "90vw",
              borderRadius: "20px",
              overflow: "hidden",
              zIndex: 2,
              backgroundColor: `white  `,
              //   border: `2px solid red`,
            }}
          >
            <span
              className={`${styles.closeButton} `}
              onClick={() => props.close()}
            >
              <FontAwesomeIcon icon={faXmark as IconProp} />
            </span>
            <div
              className=" h-100 d-md-flex d-none flex-row align-items-start justify-content-start col-12 "
              style={{
                backgroundColor: `${props.bgColor}`,
                borderRadius: "20px",
                overflow: "hidden",
              }}
            >
              <div className={`col-4   ${styles.imageDiv}`}>
                <span
                  className={`${styles.weAre} small-title fw-bold color-dark_grey_50`}
                >
                  {t(`AboutUs_Values_quadratroni1_1`)}
                </span>
                <span
                  className={`${styles.title} small-title fw-bold color-white`}
                >
                  {props.title}
                </span>
                <img src={props.image} alt="AboutUsPopUpImage"></img>
              </div>
              <div className="col-4 h-100 d-flex flex-column align-items-start justify-content-start pt-5 ps-5">
                {props.leftText.map((item) => (
                  <>
                    <div
                      className={`${styles.textWrapper} d-flex flex-column align-items-center justify-content-start align-items-start`}
                    >
                      <span
                        className={`small-title fw-bold color-white   col-12 ${styles.itemTitle}`}
                      >
                        {item.title}
                      </span>
                      <span
                        className={`material-light-title  color-white mt-3 ${styles.itemText}`}
                      >
                        {parse(item.text)}
                      </span>
                    </div>
                  </>
                ))}
              </div>
              <div className="col-4 h-100 d-flex flex-column align-items-start justify-content-start pt-5">
                {props.rightText.map((item) => (
                  <>
                    <div
                      className={`${styles.textWrapper} d-flex flex-column align-items-center justify-content-start align-items-start`}
                    >
                      <span
                        className={`small-title fw-bold color-white  col-12 ${styles.itemTitle}`}
                      >
                        {item.title}
                      </span>
                      <span
                        className={`material-light-title  color-white mt-3 ${styles.itemText}`}
                      >
                        {parse(item.text)}
                      </span>
                    </div>
                  </>
                ))}
              </div>
            </div>
            <div className=" d-flex d-sm-none flex-column align-items-start justify-content-start col-12 pb-4">
              <div className="d-flex flex-column align-items-sart justify-content-start col-12 ">
                <span className="sub-title color-dark_grey_50">
                  {t(`AboutUs_Values_quadratroni1_1`)}
                  {/* We are */}
                </span>
                <span className="small-title color-black mt-3">
                  {props.title}
                </span>
              </div>
              <div
                className={`d-flex flex-column align-items-start justify-content-start col-12  ps-4 pe-4 pb-4 mt-5 ${styles.mobileWrapper}`}
                style={{
                  borderRadius: "20px",
                  backgroundColor: `${props.bgColor}`,
                  overflowY: "scroll",
                }}
              >
                {props.leftText.map((item) => (
                  <>
                    <span className="light-title fw-bold mt-5 color-white ">
                      {item.title}
                    </span>
                    <span className="sub-title fw-normal mt-3 color-white">
                      {parse(item.text)}
                    </span>
                  </>
                ))}
                {props.rightText.map((item) => (
                  <>
                    <span className="light-title fw-bold mt-5 color-white ">
                      {item.title}
                    </span>
                    <span className="sub-title fw-normal mt-3 color-white">
                      {parse(item.text)}
                    </span>
                  </>
                ))}
              </div>
            </div>
          </Modal.Body>
        </div>
      </Modal>
    </>
  );
};

export default AboutUsPopUp;
