import { useState } from "react";

import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";

import styles from "./AboutUs.module.scss";
import { useTranslation } from "react-i18next";
import parse from "html-react-parser";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleLeft,
  faAngleRight,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import ProductCard from "../../components/ProductCard/ProductCard";
import { useNavigate } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import AboutUsPopUp from "../../components/AboutUsPopUp/AboutUsPopUp";

import card from "../../assets/aboutus-businesscard.png";
import businesscard from "../../assets/busscard_388.png";
import review from "../../assets/dlc tech.302.jpg";
const image =
  "https://d16qu8ed6p45v5.cloudfront.net/dlctechwebsite/aboutus-businesscard.png";

const innovatorsImg =
  "https://d16qu8ed6p45v5.cloudfront.net/dlctechwebsite/Aboutus_innovators.png";
const efficiencyImg =
  "https://d16qu8ed6p45v5.cloudfront.net/dlctechwebsite/Aboutus_efficiency.png";
const responsableImg =
  "https://d16qu8ed6p45v5.cloudfront.net/dlctechwebsite/Aboutus_responsable.png";

const AboutUs: React.FC = () => {
  const { t } = useTranslation();
  const [hoverProducts, setHoverProducts] = useState(false);
  const [hoverServices, setHoverServices] = useState(false);
  const [hoverContacts, setHoverContacts] = useState(false);
  const [hoverAssistence, setHoverAssistence] = useState(false);
  const [hoverAbout, setHoverAbout] = useState(false);
  const [innovatorsPopUp, setInnovatorsPopUp] = useState(false);
  const [responsablePopUp, setResponsablePopUp] = useState(false);
  const [efficiencyPopUp, setEfficiencyPopUp] = useState(false);

  const redirect = useNavigate();

  return (
    <>
      <div className={`${styles.first} `}>
        <Header dark={false} selected="AboutUs" />
        <div
          className={`d-none d-md-flex flex-column align-items-center justify-content-md-end justify-content-start  col-12  ${styles.pageDiv} `}
        >
          <div className="col-12 pt-7 mt-10  d-flex flex-row align-items-center justify-content-center">
            <div className="col-6  d-flex flex-column align-items-start justify-content-start">
              <span className="color-dark_grey_50 sub-title-about">
                {parse(t(`AboutUs_titoletto`))}
                {/* About us */}
              </span>
              <span className="color-black bold-title-helvetica-about mt-4 pe-5">
                {parse(t(`AboutUs_titolo`))}
                {/* Helping businesses and individuals through sustainable
                innovations */}
              </span>
              <span
                className="color-dark_grey_50 sub-title-about  mt-3 pe-3"
                style={{ lineHeight: "1.5" }}
              >
                {parse(t(`AboutUs_titolo_testo`))}
                {/* DLC Tech is a vibrant and innovative startup ​dedicated to
                simplifying daily tasks and enhancing ​efficiency in everyday
                life. <br /> Using NFC technology, our products help ​businesses
                and individuals to expand their networks ​and enhance their
                reputations. */}
              </span>
              <span className="d-flex flex-row align-items-center justify-content-end col-12 pe-3 mt-4">
                <span
                  className={`${styles.productsBtn} cursor-pointer col-xl-5 col-sm-7`}
                  onClick={() => redirect("/Products")}
                >
                  {parse(t(`AboutUs_bottone1`))}
                  {/* Discover our products */}
                </span>
              </span>
            </div>
            <div className="col-6  d-flex flex-row align-items-center justify-content-center">
              <img
                src={image}
                style={{ height: "100%", width: "100%" }}
                alt="aboutusImage"
              />
            </div>
          </div>
          <div className="d-flex flex-row align-items-between  justify-content-around  mt-10 pt-7 pb-7 col-12 ">
            <div className="col-6 p-4">
              <div
                className={`col-12 d-flex flex-column align-items-start justify-content-start  ${styles.greyDiv}`}
              >
                <span className="bold-title-helvetica-about">
                  {parse(t(`AboutUs_titolo_mission`))}
                  {/* Mission */}
                </span>
                <span
                  className="sub-title-about mt-5"
                  style={{ lineHeight: "1.5" }}
                >
                  {parse(t(`AboutUs_testo_mission`))}
                  {/* Our mission at DLC Tech is to simplify and ​enhance everyday
                  tasks by providing ​innovative, user-friendly solutions
                  through ​NFC technology. We help businesses and ​individuals
                  to grow and succeed in the digital ​age. Committed to
                  sustainability, we strive ​to create eco-friendly products
                  that ​positively impact society and the ​environment. */}
                </span>
              </div>
            </div>
            <div className="col-6 p-4">
              <div
                className={`col-12 d-flex flex-column  align-items-start justify-content-start   ${styles.greyDiv}`}
              >
                <span className="bold-title-helvetica-about">
                  {parse(t(`AboutUs_titolo_vision`))}
                  {/* Vision */}
                </span>
                <span
                  className="sub-title-about mt-5"
                  style={{ lineHeight: "1.5" }}
                >
                  {parse(t(`AboutUs_testo_vision`))}
                  {/* Our vision at DLC Tech is to become a ​global leader in
                  technological solutions, ​transforming how people and
                  businesses ​interact with the world. We set new ​standards for
                  efficiency and connectivity ​while promoting a sustainable
                  future. Our ​innovations aim to contribute to the well-​being
                  of the planet and its inhabitants. */}
                </span>
              </div>
            </div>
          </div>
        </div>

        <div className="col-12 bg-white d-md-block d-none   mt-10">
          <div className="d-flex flex-row col-12 justify-content-center ">
            <span className="small-title color-black">
              {parse(t(`AboutUs_titolo_Values`))}
              {/* Our Values */}
            </span>
          </div>
          {/* <div className="d-sm-flex d-none border flex-column align-items-center justify-content-start col-12 color-white p-7"> */}
          <Swiper
            className="p-11"
            slidesPerView={"auto"}
            spaceBetween={30}
            breakpoints={{
              // 300: {
              //   slidesPerView: 1.2,
              //   spaceBetween: 30,
              // },
              // 430: {
              //   slidesPerView: 1.5,
              //   spaceBetween: 30,
              // },
              // 600: {
              //   slidesPerView: 2.2,
              //   spaceBetween: 30,
              // },
              1024: {
                slidesPerView: 3,
                spaceBetween: 30,
              },
            }}
          >
            <SwiperSlide
              className={`${styles.valueSlide}`}
              onClick={() => setInnovatorsPopUp(!innovatorsPopUp)}
            >
              <div className={`${styles.BackgroundImage}`}>
                <img src={innovatorsImg} alt="aboutusInnovatorsImg" />
              </div>
              <div
                className={` ps-4 pt-4 d-flex flex-row color-dark_grey_50 small-title ${styles.greyWeAre} `}
              >
                {parse(t(`AboutUs_Values_quadratroni1_1`))}
                {/* We are */}
              </div>
              <div
                className={` ps-4 pt-4 d-flex flex-row color-white small-title ${styles.title} `}
              >
                {parse(t(`AboutUs_Values_quadratroni1_2`))}
                {/* Innovators */}
              </div>
              <div className={` color-black col-12  pe-4  ${styles.buttonRow}`}>
                <span className={` ${styles.button}`}>
                  <FontAwesomeIcon
                    icon={faPlus as IconProp}
                    style={{ fontSize: "2.5rem" }}
                  />
                </span>
              </div>
              {/* </div> */}
            </SwiperSlide>
            <AboutUsPopUp
              popup={innovatorsPopUp}
              close={() => setInnovatorsPopUp(!innovatorsPopUp)}
              title={`${t(`AboutUs_Values_quadratroni1_2`)}`}
              leftText={[
                {
                  title: `${t(`AboutUs_Values_quadratroni1_testo1`)}`,
                  text: `${t(`AboutUs_Values_quadratroni1_testo2`)}`,
                },
                {
                  title: `${t(`AboutUs_Values_quadratroni1_testo3`)}`,
                  text: `${t(`AboutUs_Values_quadratroni1_testo4`)}`,
                },
                {
                  title: `${t(`AboutUs_Values_quadratroni1_testo5`)}`,
                  text: `${t(`AboutUs_Values_quadratroni1_testo6`)}`,
                },
              ]}
              rightText={[
                {
                  title: `${t(`AboutUs_Values_quadratroni1_testo7`)}`,
                  text: `${t(`AboutUs_Values_quadratroni1_testo8`)}`,
                },
                {
                  title: `${t(`AboutUs_Values_quadratroni1_testo9`)}`,
                  text: `${t(`AboutUs_Values_quadratroni1_testo10`)}`,
                },
                {
                  title: `${t(`AboutUs_Values_quadratroni1_testo11`)}`,
                  text: `${t(`AboutUs_Values_quadratroni1_testo12`)}`,
                },
              ]}
              image={innovatorsImg}
              bgColor={"#B0BDC7"}
            />
            <SwiperSlide
              className={`${styles.valueSlide}`}
              onClick={() => setResponsablePopUp(!responsablePopUp)}
            >
              <div className={`${styles.BackgroundImage}`}>
                <img src={responsableImg} alt="aboutusResponsibleImg" />
              </div>
              <div
                className={` ps-4 pt-4 d-flex flex-row color-dark_grey_50 small-title ${styles.greyWeAre} `}
              >
                {parse(t(`AboutUs_Values_quadratroni1_1`))}
                {/* We are */}
              </div>
              <div
                className={` ps-4 pt-4 d-flex flex-row color-white light-title  small-title ${styles.title} `}
              >
                {parse(t(`AboutUs_Values_quadratroni2_2`))}
                {/* Responsable */}
              </div>
              <div className={` color-black col-12  pe-4  ${styles.buttonRow}`}>
                <span className={` ${styles.button}`}>
                  <FontAwesomeIcon
                    icon={faPlus as IconProp}
                    style={{ fontSize: "2.5rem" }}
                  />
                </span>
              </div>
              {/* </div> */}
            </SwiperSlide>
            <AboutUsPopUp
              popup={responsablePopUp}
              close={() => setResponsablePopUp(!responsablePopUp)}
              title={`${t(`AboutUs_Values_quadratroni2_2`)}`}
              leftText={[
                {
                  title: `${t(`AboutUs_Values_quadratroni2_testo1`)}`,
                  text: `${t(`AboutUs_Values_quadratroni2_testo2`)}`,
                },

                {
                  title: `${t(`AboutUs_Values_quadratroni2_testo7`)}`,
                  text: `${t(`AboutUs_Values_quadratroni2_testo8`)}`,
                },
                {
                  title: `${t(`AboutUs_Values_quadratroni2_testo3`)}`,
                  text: `${t(`AboutUs_Values_quadratroni2_testo4`)}`,
                },
              ]}
              rightText={[
                {
                  title: `${t(`AboutUs_Values_quadratroni2_testo5`)}`,
                  text: `${t(`AboutUs_Values_quadratroni2_testo6`)}`,
                },

                {
                  title: `${t(`AboutUs_Values_quadratroni2_testo9`)}`,
                  text: `${t(`AboutUs_Values_quadratroni2_testo10`)}`,
                },
              ]}
              image={responsableImg}
              bgColor={"#9FBC81"}
            />
            <SwiperSlide
              className={` ${styles.valueSlide}`}
              onClick={() => setEfficiencyPopUp(!efficiencyPopUp)}
            >
              <div className={`${styles.BackgroundImage}`}>
                <img src={efficiencyImg} alt="aboutusEfficiencyImg" />
              </div>
              <div
                className={` ps-4 pt-4 d-flex flex-row color-dark_grey_50 small-title ${styles.greyWeAre} `}
              >
                {parse(t(`AboutUs_Values_quadratroni1_1`))}
                {/* We are */}
              </div>
              <div
                className={` ps-4 pt-4 d-flex flex-row color-white small-title ${styles.title} `}
              >
                {parse(t(`AboutUs_Values_quadratroni3_2`))}
                {/* Efficiency */}
              </div>
              <div className={` color-black col-12  pe-4  ${styles.buttonRow}`}>
                <span className={` ${styles.button}`}>
                  <FontAwesomeIcon
                    icon={faPlus as IconProp}
                    style={{ fontSize: "2.5rem" }}
                  />
                </span>
              </div>
            </SwiperSlide>
            <AboutUsPopUp
              popup={efficiencyPopUp}
              close={() => setEfficiencyPopUp(!efficiencyPopUp)}
              title={`${t(`AboutUs_Values_quadratroni3_2`)}`}
              leftText={[
                {
                  title: `${t(`AboutUs_Values_quadratroni3_testo1`)}`,
                  text: `${t(`AboutUs_Values_quadratroni3_testo2`)}`,
                },
                {
                  title: `${t(`AboutUs_Values_quadratroni3_testo3`)}`,
                  text: `${t(`AboutUs_Values_quadratroni3_testo4`)}`,
                },
                {
                  title: `${t(`AboutUs_Values_quadratroni3_testo5`)}`,
                  text: `${t(`AboutUs_Values_quadratroni3_testo6`)}`,
                },
              ]}
              rightText={[
                {
                  title: `${t(`AboutUs_Values_quadratroni3_testo7`)}`,
                  text: `${t(`AboutUs_Values_quadratroni3_testo8`)}`,
                },
                {
                  title: `${t(`AboutUs_Values_quadratroni3_testo9`)}`,
                  text: `${t(`AboutUs_Values_quadratroni3_testo10`)}`,
                },
                {
                  title: `${t(`AboutUs_Values_quadratroni3_testo11`)}`,
                  text: `${t(`AboutUs_Values_quadratroni3_testo12`)}`,
                },
              ]}
              image={efficiencyImg}
              bgColor={"#B0BDC7"}
            />
          </Swiper>
          {/* </div> */}
        </div>
        <div
          className={`${styles.pageDiv} d-md-flex d-none col-12 flex-column align-items-center justify-content-center `}
        >
          <div className=" col-12 d-flex flex-column align-items-center justify-content-center mt-5  ">
            <span className="bold-title-helvetica-about color-black">
              {parse(t(`AboutUs_Team_titolo`))}
              {/* Our Team */}
            </span>
            <span className="sub-title-about color-dark_grey_50 col-7 mt-5">
              {parse(t(`AboutUs_Team_testo`))}
              {/* We are a young and enthusiastic group, dedicated to taking
              ​sustainable innovation to the next level. Our diverse team of
              ​collaborators brings together a wide range of skills and
              expertise, all ​united by a shared passion for creating
              eco-friendly solutions that ​make a difference. At DLC Tech, we
              are committed to driving positive ​change. */}
            </span>
          </div>

          <div className="d-flex flex-column align-items-center justify-content-center mt-10 col-12 mb-10 pt-7">
            <div className="d-flex flex-row align-items-center justify-content-center col-6 ">
              <span className="bold-title-helvetica-about color-black">
                {parse(t(`AboutUs_OurProducts`))}
                {/* Discover our products */}
              </span>
            </div>

            <div className="d-flex flex-row align-items-center justify-content-around col-10  mt-7 ">
              <ProductCard
                name={`${parse(t(`AboutUs_OurProducts_Review`))}`}
                link="/Products/DLCReviews"
                image={review}
              />
              <ProductCard
                name={`${parse(t(`AboutUs_OurProducts_business`))}`}
                image={businesscard}
                link={"/Products/Businesscard"}
              />
            </div>
          </div>
        </div>
      </div>

      {/* MOBILE */}
      <div
        className="col-12   d-flex d-md-none flex-column align-items-start justify-content-start"
        style={{ marginTop: "2vh" }}
      >
        <div
          className={`d-flex p-5 flex-row align-items-center justify-content-center col-12  `}
        >
          <img src={card} style={{ maxWidth: "100%" }} />
        </div>
        <div className=" p-5 col-12 d-flex flex-column align-items-start justify-content-center mt-4">
          <span className="bold-title-helvetica-about col-12">
            {parse(t(`AboutUs_titolo`))}
            {/* Helping businesses and individuals through sustainable innovations */}
          </span>
          <span
            className="sub-title-about-mob color-dark_grey_50 mt-3"
            style={{ lineHeight: "1.5" }}
          >
            {parse(t(`AboutUs_titolo_testo`))}
            {/* DLC Tech is a vibrant and innovative startup ​dedicated to
            simplifying daily tasks and enhancing ​efficiency in everyday life.{" "}
            <br /> Utilizing NFC technology, our products help ​businesses and
            individuals expand their networks ​and enhance their reputations. */}
          </span>
          <span className="d-flex flex-row col-12 mt-4 align-items-center justify-content-end">
            <span
              className={`${styles.productsBtn} col-7`}
              onClick={() => redirect("/Products")}
            >
              {parse(t(`AboutUs_bottone1`))}
              {/* Discover our products */}
            </span>
          </span>
        </div>
        <div className="col-12 p-5 d-flex flex-column align-items-start justify-content-start mt-10">
          <div
            className={`col-12  ${styles.greyDiv} d-flex flex-column align-items-start justify-content-start `}
          >
            <span className="bold-title-helvetica-about col-12">
              {parse(t(`AboutUs_titolo_mission`))}
              {/* Mission */}
            </span>
            <span
              className="sub-title-about-mob mt-3 col-12"
              style={{ lineHeight: "1.5" }}
            >
              {parse(t(`AboutUs_testo_mission`))}
              {/* Our mission at DLC Tech is to simplify and ​enhance everyday tasks
              by providing ​innovative, user-friendly solutions through ​NFC
              technology. We help businesses and ​individuals grow and succeed
              in the digital ​age. Committed to sustainability, we strive ​to
              create eco-friendly products that ​positively impact society and
              the ​environment. */}
            </span>
          </div>
          <div
            className={`col-12  ${styles.greyDiv} d-flex flex-column align-items-start justify-content-start mt-5`}
          >
            <span className="bold-title-helvetica-about col-12 text-start ">
              {parse(t(`AboutUs_titolo_vision`))}
              {/* Vision */}
            </span>
            <span
              className="sub-title-about-mob mt-3 col-12 text-start"
              style={{ lineHeight: "1.5" }}
            >
              {parse(t(`AboutUs_testo_vision`))}
              {/* Our vision at DLC Tech is to become a ​global leader in
              technological solutions, ​transforming how people and businesses
              ​interact with the world. We set new ​standards for efficiency and
              connectivity ​while promoting a sustainable future. Our
              ​innovations aim to contribute to the well-​being of the planet
              and its inhabitants. */}
            </span>
          </div>
        </div>
        <div className="d-flex flex-row align-items-center justify-content-center col-12 small-title mt-10">
          {parse(t(`AboutUs_titolo_Values`))}
          {/* Our Values */}
        </div>
        <div className="col-12 bg-white d-md-none d-block mt-7  pb-7">
          {/* <div className="d-sm-flex d-none border flex-column align-items-center justify-content-start col-12 color-white p-7"> */}
          <Swiper
            className=" p-md-5 p-4  "
            slidesPerView={"auto"}
            spaceBetween={30}
            breakpoints={{
              300: {
                slidesPerView: 1.2,
                spaceBetween: 30,
              },
              430: {
                slidesPerView: 1.5,
                spaceBetween: 30,
              },
              600: {
                slidesPerView: 2.2,
                spaceBetween: 30,
              },
              800: {
                slidesPerView: 2.5,
                spaceBetween: 30,
              },
              // 1024: {
              //   slidesPerView: 3.5,
              //   spaceBetween: 30,
              // },
            }}
          >
            <SwiperSlide
              className={`${styles.valueSlide}`}
              onClick={() => setInnovatorsPopUp(!innovatorsPopUp)}
            >
              <div className={`${styles.BackgroundImage}`}>
                <img src={innovatorsImg} alt="aboutusInnovatorsImg" />
              </div>
              <div
                className={` ps-4 pt-4 d-flex flex-row color-dark_grey_50 sub-title ${styles.greyWeAre} `}
              >
                {parse(t(`AboutUs_Values_quadratroni1_1`))}
                {/* We are */}
              </div>
              <div
                className={` ps-4 pt-4 d-flex flex-row color-white small-title ${styles.title} `}
              >
                {parse(t(`AboutUs_Values_quadratroni1_2`))}
                {/* Innovators */}
              </div>
              <div className={` color-black col-12  pe-4  ${styles.buttonRow}`}>
                <span className={` ${styles.button}`}>
                  <FontAwesomeIcon
                    icon={faPlus as IconProp}
                    style={{ fontSize: "2.5rem" }}
                  />
                </span>
              </div>
              {/* </div> */}
            </SwiperSlide>
            {/* <BusinessCardEfficiencyPopUp
              popup={efficiencyPopUp}
              close={() => setEfficiencyPopUp(!efficiencyPopUp)}
            /> */}
            <SwiperSlide
              className={`${styles.valueSlide}`}
              onClick={() => setResponsablePopUp(!responsablePopUp)}
            >
              <div className={`${styles.BackgroundImage}`}>
                <img src={responsableImg} alt="aboutusResponsibleImg" />
              </div>
              <div
                className={` ps-4 pt-4 d-flex flex-row color-dark_grey_50 sub-title ${styles.greyWeAre} `}
              >
                {parse(t(`AboutUs_Values_quadratroni1_1`))}
                {/* We are */}
              </div>
              <div
                className={` ps-4 pt-4 d-flex flex-row color-white small-title ${styles.title} `}
              >
                {parse(t(`AboutUs_Values_quadratroni2_2`))}
                {/* Responsable */}
              </div>
              <div className={` color-black col-12  pe-4  ${styles.buttonRow}`}>
                <span className={` ${styles.button}`}>
                  <FontAwesomeIcon
                    icon={faPlus as IconProp}
                    style={{ fontSize: "2.5rem" }}
                  />
                </span>
              </div>
              {/* </div> */}
            </SwiperSlide>
            {/* <BusinessCardMoneyPopUp
              popup={moneyPopUp}
              close={() => setMoneyPopUp(!moneyPopUp)}
            /> */}
            <SwiperSlide
              className={` ${styles.valueSlide}`}
              onClick={() => setEfficiencyPopUp(!efficiencyPopUp)}
            >
              <div className={`${styles.BackgroundImage}`}>
                <img src={efficiencyImg} alt="aboutusEfficiencyImg" />
              </div>
              <div
                className={` ps-4 pt-4 d-flex flex-row color-dark_grey_50 sub-title ${styles.greyWeAre} `}
              >
                {parse(t(`AboutUs_Values_quadratroni1_1`))}
                {/* We are */}
              </div>
              <div
                className={` ps-4 pt-4 d-flex flex-row color-white small-title ${styles.title} `}
              >
                {parse(t(`AboutUs_Values_quadratroni3_2`))}
                {/* Efficiency */}
              </div>
              <div className={` color-black col-12  pe-4  ${styles.buttonRow}`}>
                <span className={` ${styles.button}`}>
                  <FontAwesomeIcon
                    icon={faPlus as IconProp}
                    style={{ fontSize: "2.5rem" }}
                  />
                </span>
              </div>
            </SwiperSlide>
            {/* <BusinessCardEnvironmentPopUp
              popup={environmentPopUp}
              close={() => setEnvironmentPopUp(!environmentPopUp)}
            /> */}
          </Swiper>
          {/* </div> */}
        </div>

        <div className=" p-5  d-flex flex-column align-items-start justify-content-start col-12">
          <div className="d-flex flex-row align-items-center justify-content-center col-12">
            <span className="bold-title-helvetica-about text-center color-black col-12">
              {parse(t(`AboutUs_Team_titolo`))}
              {/* Our team */}
            </span>
          </div>
          <div className="d-flex flex-row align-items-center justify-content-start col-12">
            <span className="sub-title-about-mob col-12 mt-3 color-dark_grey_50">
              {parse(t(`AboutUs_Team_testo`))}
              {/* We are a young and enthusiastic group, dedicated to taking
              ​sustainable innovation to the next level. Our diverse team of
              ​collaborators brings together a wide range of skills and
              expertise, all ​united by a shared passion for creating
              eco-friendly solutions that ​make a difference. At DLC Tech, we
              are committed to driving positive ​change. */}
            </span>
          </div>
        </div>
        <div className="p-5  mt-5 d-flex flex-column align-items-start justify-content-start">
          <span className="bold-title-helvetica-about color-black col-12 text-center ">
            {parse(t(`AboutUs_OurProducts`))}
            {/* Discover our products */}
          </span>
          <div className="d-flex flex-column align-items-center justify-content-center col-12 ">
            <span className="mt-4">
              <ProductCard
                name={`${parse(t(`AboutUs_OurProducts_Review`))}`}
                link="/Products/DLCReviews"
                image={review}
              />
            </span>
            <span className="mt-4">
              <ProductCard
                name={`${parse(t(`AboutUs_OurProducts_business`))}`}
                image={businesscard}
                link={"/Products/Businesscard"}
              />
            </span>
          </div>
        </div>
      </div>
      <Footer dark={false} />
    </>
  );
};
export default AboutUs;
