import React, { useEffect, useState } from "react";

import styles from "./HomeCustomizationPopUp.module.scss";

import { Modal } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faXmark } from "@fortawesome/free-solid-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

import parse from "html-react-parser";

import { useTranslation } from "react-i18next";

const disegno =
  "https://d16qu8ed6p45v5.cloudfront.net/dlctechwebsite/drawing.png";
interface PopUpExampleProps {
  popup: boolean;
  close: () => void;
}

const HomeCustomizationPopUp: React.FC<PopUpExampleProps> = (props) => {
  const { t } = useTranslation();
  const [popup, setPopup] = useState(false);

  return (
    <>
      <Modal
        show={props.popup}
        onHide={() => (setPopup(!popup), props.close)}
        className={`d-flex flex-row justify-content-center align-items-center  `}
        style={{
          height: "100vh",
          width: "100vw",
          overflow: "scroll",
          //   border: "1px solid red",
          background: "rgba(0,0,0,0.85)",
        }}
        contentClassName={`${styles.bodyModal}  `}
      >
        <div className={`${styles.body} `}>
          <Modal.Body
            className={`d-flex flex-column align-items-start  justify-content-start p-4
            bg-light color-black 
            
            `}
            style={{
              height: "90vh",
              width: "90vw",
              borderRadius: "20px",
              overflow: "scroll",
              //   border: `2px solid red`,
            }}
          >
            <div
              className="d-flex flex-row align-items-center justify-content-end col-12 "
              style={{ height: "50px" }}
            >
              <span
                className={`${styles.closeButton} `}
                onClick={() => props.close()}
              >
                <FontAwesomeIcon icon={faXmark as IconProp} />
              </span>
            </div>
            <div className="ps-7 pe-7 d-md-flex d-none flex-column align-items-start justify-content-start col-12 ">
              <div className="d-flex flex-row align-items-center  justify-content-start col-12 ">
                <span className=" sub-title">
                  {parse(t(`Homepage_quadratoni_titoletto_4`))}
                  {/* Customization */}
                </span>
              </div>
              <div className={`${styles.content} col-12  mt-5 `}>
                <div className="d-flex flex-row align-items-center justify-content-between col-12  ">
                  <div className={`col-6   ${styles.imgContainer1}`}>
                    <img src={disegno} alt="disegno" />
                  </div>
                  <div className="col-xl-6 col-sm-5 h-100   d-flex flex-column align-items-start justify-content-center pb-xl-0 pb-10">
                    <span
                      className="small-title "
                      style={{ lineHeight: "1.1" }}
                    >
                      {parse(t(`Homepage_quadratoni_titolo_interno_4`))}
                      {/* Your Design <br /> as you like */}
                    </span>

                    <span className="mt-5  col-xl-6 col-10 text">
                      {parse(t(`Homepage_quadratoni_interno_4`))}
                      {/* From personalized colors and materials to specific design
                      elements, you have the freedom to create a product that
                      reflects your brand identity or your individual style. */}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className=" d-flex d-sm-none flex-column align-items-start justify-content-start col-12 pb-4">
              <div className="d-flex flex-row align-items-center justify-content-start col-12 ">
                <span className=" sub-title">
                  {parse(t(`Homepage_quadratoni_titoletto_4`))}
                  {/* Customization */}
                </span>
              </div>
              <div className={`${styles.content} col-12  mt-5  `}>
                <div className="d-flex flex-column col-12  ">
                  <span
                    className="small-title col-12 ps-4 pe-4 pt-4"
                    style={{ lineHeight: "1.1" }}
                  >
                    {parse(t(`Homepage_quadratoni_titolo_interno_4`))}
                    {/* Your Design <br />
                    as you like */}
                  </span>

                  <span className="mt-5 col-12 ps-4 pe-4 text">
                    {parse(t(`Homepage_quadratoni_interno_4`))}
                    {/* From personalized colors and materials to specific design
                    elements, you have the freedom to create a product that
                    reflects your brand identity or your individual style. */}
                  </span>
                </div>
                <div className={`${styles.imgContainer1}  mt-5`}>
                  <img src={disegno} alt="disegno" />
                </div>
              </div>
            </div>
          </Modal.Body>
        </div>
      </Modal>
    </>
  );
};

export default HomeCustomizationPopUp;
