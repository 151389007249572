import { useEffect, useState } from "react";

import styles from "./PrecontactCreation.module.scss";
import Dropzone from "react-dropzone";

import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import axiosHelper from "../../helpers/axiosHelper";
import WebsiteStore, { setCurrentPrecontact } from "../../store/WebsiteStore";
import { useStoreState } from "pullstate";
import { getCurrentPrecontact } from "../../store/Selector";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import parse from "html-react-parser";
const modeNight =
  "https://d16qu8ed6p45v5.cloudfront.net/dlctechwebsite/businessweb1.png";
const modeLight =
  "https://d16qu8ed6p45v5.cloudfront.net/dlctechwebsite/businessweb2.png";

const PrecontactCreation: React.FC = () => {
  const { t } = useTranslation();
  const redirect = useNavigate();
  const currentPrecontact = useStoreState(WebsiteStore, getCurrentPrecontact);
  const [image, setImage] = useState<File>();
  const [imagebtn, setImagebtn] = useState(false);
  const [logo, setLogo] = useState<File>();
  const [logobtn, setLogobtn] = useState(true);
  const [whatsapp, setWhatsapp] = useState(false);
  const [mode, setMode] = useState("");
  const [imageresponse, setImageResponse] = useState(0);
  const [logoresponse, setLogoResponse] = useState(0);
  const [privacy, setPrivacy] = useState(false);
  const [privacyError, setPrivacyError] = useState(false);
  const [terms, setTerms] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<PrecontactData>();

  const onSubmit = async (data: PrecontactData) => {
    if (whatsapp) {
      data.whatsapp = 1;
    } else {
      data.whatsapp = 0;
    }

    data.mode = mode;

    // data.orderId = "test";

    console.log(data);
    if (privacy) {
      setPrivacyError(false);
      await axiosHelper
        .createPreContact(data)
        .then((response) => {
          console.log(response);

          setCurrentPrecontact(response);
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      setPrivacyError(true);
    }
  };

  const onDropImage = (e: any) => {
    console.log(e?.[0]);
    setImage(e?.[0]);
  };

  const onDropLogo = (e: any) => {
    console.log(e?.[0]);
    setLogo(e?.[0]);
  };

  const uploadImage = async () => {
    if (image) {
      const data: uploadImageData = {
        uuid: currentPrecontact.uuid,
        image: image,
      };
      await axiosHelper
        .addPrecontactImage(data)
        .then((response: any) => {
          console.log(response);
          setImageResponse(response.status);
        })
        .catch((error: any) => {
          console.log(error);
          setImageResponse(500);
        });
    }
  };

  const uploadLogo = async () => {
    if (logo) {
      const data: uploadImageData = {
        uuid: currentPrecontact.uuid,
        image: logo,
      };
      await axiosHelper
        .addPrecontactLogo(data)
        .then((response: any) => {
          console.log(response);
          setLogoResponse(200);
        })
        .catch((error: any) => {
          console.log(error);
          setLogoResponse(500);
        });
    }
  };

  const completeProcess = async () => {
    if (image && imageresponse === 0) {
      uploadImage().then(() =>
        redirect("/Products/Businesscard/GenerateContact/Done")
      );
    } else {
      redirect("/Products/Businesscard/GenerateContact/Done");
    }
  };

  useEffect(() => {
    console.log(imageresponse);
  }, [imageresponse]);

  return (
    <>
      <Header dark={false} selected={""} />
      <div className="col-12 d-flex flex-row align-items-start justify-content-center p-5 p-md-0 pt-5 flex-wrap "></div>
      <div className="p-5 d-flex flex-column align-items-center justify-content-start ">
        <form
          onSubmit={handleSubmit(onSubmit)}
          className=" col-12 rounded color-black "
        >
          <div className=" d-flex flex-row  col-12 align-items-center justify-content-center  text-center">
            <span className="esquare-title">
              Generate your <br className="d-md-none" />
              contact
            </span>
          </div>
          <div className=" text-center d-flex flex-row align-items-center justify-content-center mt-10  col-12  ">
            <span className="sub-title col-md-4 col-12">
              Once the contact has been created with the chosen information, it
              will also be possible to add the contact image.
            </span>
          </div>
          <div className=" d-flex flex-column  pb-5 align-items-center justify-content-center mt-10 ">
            <div className="sub-title d-flex flex-row align-items-center justify-content-center col-12 col-md-4  ">
              name :
            </div>
            <div className="col-12 col-md-4 mt-3 ">
              <input
                type="text"
                placeholder="name"
                className={`text-center w-100 sub-title ${styles.inputText}`}
                {...register("name")}
              />
            </div>
          </div>
          <div className=" d-flex flex-column pb-5  align-items-center justify-content-center mt-5 ">
            <div className="sub-title d-flex flex-row align-items-center justify-content-center col-12 col-md-4  ">
              surname :
            </div>
            <div className="col-12 col-md-4 mt-3">
              <input
                type="text"
                placeholder="surname"
                className={`text-center w-100 sub-title ${styles.inputText}`}
                {...register("surname")}
              />
            </div>
          </div>
          <div className=" d-flex flex-column pb-5  align-items-center justify-content-center mt-5 ">
            <div className="sub-title d-flex flex-row align-items-center justify-content-center col-12 col-md-4  ">
              email :
            </div>
            <div className="col-12 col-md-4 mt-3 ">
              <input
                type="email"
                placeholder="email"
                className={`text-center w-100 sub-title ${styles.inputText}`}
                {...register("email")}
              />
            </div>
          </div>
          <div className=" d-flex flex-column pb-5  align-items-center justify-content-center mt-5 ">
            <div className="sub-title d-flex flex-row align-items-center justify-content-center col-12 col-md-4  ">
              phone number:
            </div>
            <div className="col-12 col-md-4 mt-3">
              <input
                type="text"
                className={`text-center w-100 sub-title ${styles.inputText}`}
                placeholder="phone number complete with prefix : '+....'"
                {...register("phone")}
              />
            </div>
          </div>
          <div className=" d-flex flex-column pb-5  align-items-center justify-content-center mt-7 ">
            <div className="sub-title d-flex flex-row align-items-center justify-content-around col-12 col-md-4   ">
              whatsapp link :
              <label
                className={`col-1  d-flex flex-row align-items-center justify-content-center sub-title ${styles.containerCheckbox}`}
              >
                <input
                  type="checkbox"
                  checked={whatsapp}
                  onClick={() => setWhatsapp(!whatsapp)}
                  {...register("mode")}
                />
                <span
                  className={` sub-title cursor-pointer ${styles.checkmark}`}
                  onClick={() => setMode("LIGHT")}
                ></span>
              </label>
            </div>
          </div>
          <div className=" d-flex flex-column pb-5  align-items-center justify-content-center mt-7 ">
            <div className="sub-title d-flex flex-row align-items-center justify-content-center col-12 col-md-4  ">
              workmail :
            </div>
            <div className="col-12 col-md-4 mt-3">
              <input
                placeholder="workmail"
                type="email"
                className={`text-center w-100 sub-title ${styles.inputText}`}
                {...register("workmail")}
              />
            </div>
          </div>

          <div className=" d-flex flex-column pb-5  align-items-center justify-content-center mt-7 ">
            <div className="sub-title d-flex flex-row align-items-center justify-content-center col-12 col-md-4  ">
              company :
            </div>
            <div className="col-12 col-md-4 mt-3 ">
              <input
                type="text"
                placeholder="company"
                className={`text-center w-100 sub-title ${styles.inputText}`}
                {...register("company")}
              />
            </div>
          </div>
          <div className=" d-flex flex-column pb-5  align-items-center justify-content-center mt-7 ">
            <div className="sub-title d-flex flex-row align-items-center justify-content-center col-12 col-md-4 ">
              role :
            </div>
            <div className="col-12 col-md-4 mt-3">
              <input
                placeholder="role"
                type="text"
                className={`text-center w-100 sub-title ${styles.inputText}`}
                {...register("role")}
              />
            </div>
          </div>
          <div className=" d-flex flex-column pb-5  align-items-center justify-content-center mt-7 ">
            <div className="sub-title d-flex flex-row align-items-center justify-content-center col-12 col-md-4">
              website :
            </div>
            <div className="col-12 col-md-4 mt-3 ">
              <input
                type="text"
                placeholder="website with prefix 'https://...'"
                className={`text-center w-100 sub-title ${styles.inputText}`}
                {...register("website")}
              />
            </div>
          </div>
          <div className=" d-flex flex-column pb-5  align-items-center justify-content-center mt-7 ">
            <div className="sub-title d-flex flex-row align-items-center justify-content-center col-12 col-md-4  ">
              linkedin :
            </div>
            <div className="col-12 col-md-4 mt-3">
              <input
                type="text"
                placeholder="linkedin link with prefix 'https://...'"
                className={`text-center w-100 sub-title ${styles.inputText}`}
                {...register("linkedin")}
              />
            </div>
          </div>
          <div className=" d-flex flex-column pb-5  align-items-center justify-content-center mt-7 ">
            <div className="sub-title d-flex flex-row align-items-center justify-content-center col-12 col-md-4  ">
              facebook :
            </div>
            <div className="col-12 col-md-4 mt-3 ">
              <input
                type="text"
                placeholder=" facebook link with prefix 'https://...'"
                className={`text-center w-100 sub-title ${styles.inputText}`}
                {...register("facebook")}
              />
            </div>
          </div>
          <div className=" d-flex flex-column pb-5  align-items-center justify-content-center mt-7 ">
            <div className="sub-title d-flex flex-row align-items-center justify-content-center col-12 col-md-4  ">
              instagram :
            </div>
            <div className="col-12 col-md-4 mt-3 ">
              <input
                type="text"
                placeholder="instagram link with prefix 'https://...'"
                className={`text-center w-100 sub-title ${styles.inputText}`}
                {...register("instagram")}
              />
            </div>
          </div>

          <div className=" d-flex flex-column pb-5  align-items-center justify-content-center mt-7 ">
            <div className="sub-title d-flex flex-row align-items-center justify-content-center col-12 col-md-4  mb-3">
              mode :
            </div>
            <div className="col-12 col-md-4 d-flex flex-row align-items-center justify-content-center">
              <label
                className={`col-6 d-flex flex-row align-items-center justify-content-center sub-title ${styles.containerCheckbox}`}
              >
                <input
                  type="checkbox"
                  checked={mode === "NIGHT"}
                  onClick={() => setMode("NIGHT")}
                  {...register("mode")}
                />
                <span
                  className={` sub-title cursor-pointer ${styles.checkmark}`}
                  onClick={() => setMode("NIGHT")}
                ></span>
                <span> Night</span>
              </label>
              <label
                className={`col-6 d-flex flex-row align-items-center justify-content-center sub-title ${styles.containerCheckbox}`}
              >
                <input
                  type="checkbox"
                  checked={mode === "LIGHT"}
                  onClick={() => setMode("LIGHT")}
                  {...register("mode")}
                />
                <span
                  className={` sub-title cursor-pointer ${styles.checkmark}`}
                  onClick={() => setMode("LIGHT")}
                ></span>
                <span className="ms-4">Light</span>
              </label>
            </div>
            <div className="col-12  col-md-4 d-flex flex-row align-items-center justify-content-center mt-3">
              <div
                className={`col-6 d-flex flex-row align-items-center justify-content-center ${styles.modeImg} `}
              >
                <img src={modeNight} alt="businesscardwebpageNight" />
              </div>
              <div
                className={`col-6 d-flex flex-row align-items-center justify-content-center ${styles.modeImg} `}
              >
                <img src={modeLight} alt="businesscardwebpageLight" />
              </div>
            </div>

            <div className="sub-title d-flex flex-row align-items-center justify-content-around col-12 col-md-4   ">
              <label
                className={`col-1  d-flex flex-row align-items-center justify-content-center sub-title ${styles.containerCheckbox}`}
              >
                <input
                  type="checkbox"
                  checked={privacy}
                  onClick={() => setWhatsapp(!whatsapp)}
                  {...register("privacy")}
                />
                <span
                  className={` sub-title cursor-pointer ${styles.checkmark}`}
                  onClick={() => {
                    setPrivacy(!privacy);
                  }}
                ></span>
              </label>
              <span className={`col-10 text-helvetica fw-bold ${styles.link}`}>
                {parse(t(`precontact_privacy`))}
                <span className="color-dark_red">*</span>
                <br />
                <a href="/Privacy-Policy" target="_blank">
                  {t(`precontact_privacy_link`)}
                </a>
              </span>
            </div>
            {privacyError && (
              <div className="text-helvetica d-flex flex-row align-items-center justify-content-center mt-2 color-dark_red col-12 col-md-4 ">
                <span className="col-1 "></span>
                <span className="col-10 text-center ">
                  {parse(t(`precontact_privacy_error`))}
                </span>
              </div>
            )}
          </div>

          <div className=" d-flex flex-row   align-items-center justify-content-center mt-7   ">
            <button className="btn-black sub-title  col-6 rounded">
              CREATE CONTACT
            </button>
          </div>
        </form>

        {currentPrecontact.uuid && (
          <div className="d-flex flex-row align-items-center justify-content-center col-12 mt-7 ">
            <span className="sub-title col-md-4 col-12 text-center">
              Your contact has been created! <br /> Now you can add your profile
              picture
            </span>
          </div>
        )}

        {currentPrecontact.uuid !== "" && (
          <div className=" col-12 d-flex flex-row align-items-center justify-content-center mt-10">
            <span className="sub-title ">Contact image:</span>
          </div>
        )}
        {!image && currentPrecontact.uuid !== "" && (
          <>
            <div className="d-flex flex-column align-items-center justify-content-center col-12 mt-5 ">
              <div
                className={` mt-3 bg-light col-md-6 col-12 d-flex flex-row align-items-center justify-content-center ${styles.dropImage}`}
              >
                <Dropzone
                  onDrop={onDropImage}
                  accept={"image/png, image/jpg, image/jpeg, image/svg"}
                  maxSize={31457280}
                >
                  {({ getRootProps, getInputProps }) => (
                    <section
                      style={{
                        height: "100%",
                        width: "100%",
                        backgroundColor: "black",
                        borderRadius: "10px",
                      }}
                      className={` sub-title  d-flex align-items-center  justify-content-center `}
                    >
                      <div
                        className="col-12 d-flex h-100 flex-column align-items-center justify-content-center text-center"
                        {...getRootProps()}
                      >
                        <input {...getInputProps()} />
                        <span>
                          Drag and drop an image or{" "}
                          <span className="color-blue">choose a file</span> from
                          your computer <br />
                          Allowed formats: .jpg, .png
                        </span>
                      </div>
                    </section>
                  )}
                </Dropzone>
              </div>
            </div>
          </>
        )}
        <div
          className={`col-12 mb-7 ${
            image && currentPrecontact.uuid !== ""
              ? "d-flex flex-row align-items-center justify-content-center"
              : "d-none"
          }`}
        >
          <div
            className={`col-12 col-md-8   d-flex flex-column align-items-center justify-content-center `}
          >
            {image && currentPrecontact.uuid !== "" && (
              <>
                <div className=" mt-4 d-flex" style={{ width: "100%" }}>
                  <div className={`${styles.previewImg}`}>
                    <img
                      alt="contactImage"
                      src={URL.createObjectURL(image)}
                      className={`${styles.previewImg}`}
                    />
                  </div>
                  <div className="d-flex flex-row w-100 align-items-center justify-content-center ">
                    <span className="text-xl">{image.name}</span>
                  </div>
                  <div className=" d-flex align-items-center justify-content-end ">
                    <div
                      className={` ${styles.removeButton}`}
                      onClick={() => {
                        setImage(undefined);
                        setImageResponse(0);
                      }}
                    >
                      <FontAwesomeIcon
                        icon={faXmark as IconProp}
                        className=" text-16 me-0"
                      />
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
        {image && currentPrecontact.uuid !== "" && (
          <div className="d-flex flex-row align-items-center justify-content-center col-12 mb-5">
            <div className="btn-black rounded  " onClick={() => uploadImage()}>
              Add Image
            </div>
          </div>
        )}
        {imageresponse !== 500 && imageresponse !== 0 && (
          <div className="d-flex flex-row align-items-center justify-content-center col-12 col-md-4 text center  mt-4">
            <span className="sub-title">
              Image successfully added to the contact
            </span>
          </div>
        )}
        {imageresponse === 500 && (
          <div className="d-flex flex-row align-items-center justify-content-center col-12 col-md-4 text center  mt-4">
            <span className="sub-title color-dark_red text-center">
              There was an error on the image upload <br /> please retry
            </span>
          </div>
        )}

        {currentPrecontact.uuid && (
          <div
            className="btn-black mt-10 rounded sub-title "
            onClick={() => completeProcess()}
          >
            COMPLETE PROCESS
          </div>
        )}
      </div>
      <Footer dark={false} />
    </>
  );
};
export default PrecontactCreation;
