import React from "react";


import { BrowserRouter } from "react-router-dom";
import Container from "./Containter";


function App() {
  return (
    
    <>
      
      <BrowserRouter>
        <Container />

      </BrowserRouter>
    </>
  );
}

export default App;
