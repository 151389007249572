import React, { useEffect, useState } from "react";

import styles from "./HomePrivacyPopUp.module.scss";

import { Modal } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faXmark } from "@fortawesome/free-solid-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

import parse from "html-react-parser";
import { useTranslation } from "react-i18next";

const lucchetto =
  "https://d16qu8ed6p45v5.cloudfront.net/dlctechwebsite/lucchetto_4:5.png";
const europe =
  "https://d16qu8ed6p45v5.cloudfront.net/dlctechwebsite/europe_1.png";
interface PopUpExampleProps {
  popup: boolean;
  close: () => void;
}

const HomePrivacyPopUp: React.FC<PopUpExampleProps> = (props) => {
  const { t } = useTranslation();
  const [popup, setPopup] = useState(false);

  return (
    <>
      <Modal
        show={props.popup}
        onHide={() => (setPopup(!popup), props.close)}
        className={`d-flex flex-row justify-content-center align-items-center  `}
        style={{
          height: "100vh",
          width: "100vw",
          overflow: "scroll",
          //   border: "1px solid red",
          background: "rgba(0,0,0,0.85)",
        }}
        contentClassName={`${styles.bodyModal}  `}
      >
        <div className={`${styles.body} `}>
          <Modal.Body
            className={`d-flex flex-column align-items-start  justify-content-start p-4
            bg-light color-black 
            
            `}
            style={{
              height: "90vh",
              width: "90vw",
              borderRadius: "20px",
              overflow: "scroll",
              //   border: `2px solid red`,
            }}
          >
            <div
              className="d-flex flex-row align-items-center justify-content-end col-12 "
              style={{ height: "50px" }}
            >
              <span
                className={`${styles.closeButton} `}
                onClick={() => props.close()}
              >
                <FontAwesomeIcon icon={faXmark as IconProp} />
              </span>
            </div>
            <div className="ps-7 pe-7 d-md-flex d-none flex-column align-items-start justify-content-start col-12 ">
              <div className="d-flex flex-row align-items-center  justify-content-start col-12 ">
                <span className=" sub-title">
                  {parse(t(`Homepage_quadratoni_titoletto_3`))}
                  {/* Privacy */}
                </span>
              </div>
              <div className={`${styles.content} col-12  mt-5 `}>
                <div className="d-flex flex-row align-items-center justify-content-center col-12  ">
                  <div className={`col-4   ${styles.imgContainer1}`}>
                    <img src={lucchetto} alt="dlclucchetto" />
                  </div>
                  <div className="col-2"></div>
                  <div className="col-6 h-100 ms-5  d-flex flex-column align-items-start justify-content-center">
                    <span
                      className="small-title "
                      style={{ lineHeight: "1.1" }}
                    >
                      {parse(t(`Homepage_quadratoni_interno_3`))}
                      {/* Data Safety */}
                    </span>
                    <span className="mt-sm-3 mt-5 col-xl-6 col-sm-10 text">
                      {parse(t(`Homepage_quadratoni_interno_3_1`))}
                      {/* At DLC Tech, protecting your data and information is our
                      top priority. */}
                    </span>
                    <span className="mt-xl-10 mt-7  col-6 light-title fw-bold">
                      {parse(t(`Homepage_quadratoni_interno_3_2`))}
                      {/* GDPR Compliant */}
                    </span>
                    <span className=" mt-xl-5 mt-sm-3 col-sm-10 col-xl-6 text">
                      {parse(t(`Homepage_quadratoni_interno_3_3`))}
                      {/* Our products and services are fully GDPR compliant,
                      ensuring that your personal data is handled with the
                      highest standard of care and privacy. */}
                    </span>
                  </div>
                </div>
              </div>
              <div className={`${styles.content} col-12  mt-5 `}>
                <div className="d-flex flex-row align-items-center justify-content-center col-12  ">
                  <div className="col-6 ps-7 d-flex flex-column   align-items-start justify-content-center ">
                    <div className="d-flex flex-column col-11  ">
                      <span className="col-xl-10 col-sm-12 light-title fw-bold ">
                        {parse(t(`Homepage_quadratoni_interno_3_4`))}
                        {/* Hosted in Europe */}
                      </span>
                      <span className=" mt-xl-7 mt-sm-4 text">
                        {parse(t(`Homepage_quadratoni_interno_3_5`))}
                        {/* Developed in Italy, our software is hosted on secure
                        servers in Ireland, meeting European data protection
                        regulations.
                        <br />
                        By hosting and developing our technology exclusively in
                        Europe, we ensure that your data stays within the EU,
                        providing an added layer of security and compliance. */}
                      </span>
                      <span className="mt-5 text ">
                        {parse(t(`Homepage_quadratoni_interno_3_6`))}
                        {/* With DLC Tech, you can trust that your team will work
                        securely, backed by robust data protection protocols
                        designed to meet all your essential requirements. */}
                      </span>
                    </div>
                  </div>
                  <div
                    className={`col-6  d-flex flex-row align-items-center justify-content-end ${styles.imgContainer2}`}
                  >
                    <img src={europe} alt="dlceurope" />
                  </div>
                </div>
              </div>
            </div>
            <div className=" d-flex d-sm-none flex-column align-items-start justify-content-start col-12 pb-4">
              <div className="d-flex flex-row align-items-center justify-content-start col-12 ">
                <span className=" sub-title">
                  {parse(t(`Homepage_quadratoni_titoletto_3`))}
                  {/* Privacy */}
                </span>
              </div>
              <div className={`${styles.content} col-12  mt-5  `}>
                <div className="d-flex flex-column col-12  ">
                  <span
                    className="small-title col-12 ps-4 pe-4 pt-4"
                    style={{ lineHeight: "1.1" }}
                  >
                    {parse(t(`Homepage_quadratoni_interno_3`))}
                    {/* Data Safety */}
                  </span>
                  <span className="mt-4 col-12 ps-4 pe-4">
                    {parse(t(`Homepage_quadratoni_interno_3_1`))}
                    {/* At DLC Tech, protecting your data and information is our top
                    priority. */}
                  </span>
                  <span className="mt-7 col-12 ps-4 pe-4 light-title fw-bold">
                    {parse(t(`Homepage_quadratoni_interno_3_2`))}
                    {/* GDPR Compliant */}
                  </span>
                  <span className="mt-4 col-12 ps-4 pe-4">
                    {parse(t(`Homepage_quadratoni_interno_3_3`))}
                    {/* Our products and services are fully GDPR compliant, ensuring
                    that your personal data is handled with the highest standard
                    of care and privacy. */}
                  </span>
                  <div className={`${styles.imgContainer1}  mt-5`}>
                    <img src={lucchetto} alt="dlclucchetto" />
                  </div>
                </div>
              </div>
              <div className={`${styles.content} col-12  mt-5  `}>
                <div className="d-flex flex-column col-12  ">
                  <span className="mt-5 col-12 ps-4 pe-4 light-title fw-bold">
                    {parse(t(`Homepage_quadratoni_interno_3_4`))}
                    {/* Hosted in Europe */}
                  </span>
                  <span className="mt-5 col-12 ps-4 pe-4">
                    {parse(t(`Homepage_quadratoni_interno_3_5`))}
                    {/* Developed in Italy, our software is hosted on secure servers
                    in Ireland, meeting European data protection regulations.
                    <br />
                    By hosting and developing our technology exclusively in
                    Europe, we ensure that your data stays within the EU,
                    providing an added layer of security and compliance. */}
                  </span>
                  <span className="mt-4 col-12 ps-4 pe-4">
                    {parse(t(`Homepage_quadratoni_interno_3_6`))}
                    {/* With DLC Tech, you can trust that your team will work
                    securely, backed by robust data protection protocols
                    designed to meet all your essential requirements. */}
                  </span>
                  <div className={`${styles.imgContainer2}  mt-5`}>
                    <img src={europe} alt="dlceurope" />
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
        </div>
      </Modal>
    </>
  );
};

export default HomePrivacyPopUp;
