import styles from "./ContactComplete.module.scss";

import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const ContactComplete: React.FC = () => {
  const { t } = useTranslation();
  const redirect = useNavigate();

  return (
    <>
      <Header dark={false} selected={""} />
      <div
        className={`d-flex flex-column align-items-center justify-content-center ${styles.firstDiv} col-12`}
      >
        <div className="d-flex flex-row align-items-center justify-content-center col-12 col-md-6">
          <span className={`esquare-title text-center ${styles.text}`}>
            Thank you! <br /> You’ll be hearing from us soon
          </span>
        </div>
      </div>
      <Footer dark={false} />
    </>
  );
};
export default ContactComplete;
