import React, { useEffect, useState } from "react";

import styles from "./BusinessCardTimePopUp.module.scss";

import { Modal } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faXmark } from "@fortawesome/free-solid-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

import parse from "html-react-parser";
import { useTranslation } from "react-i18next";

const timeImg =
  "https://d16qu8ed6p45v5.cloudfront.net/dlctechwebsite/CLESSIODRA.png";
interface PopUpExampleProps {
  popup: boolean;
  close: () => void;
}

const BusinessCardTimePopUp: React.FC<PopUpExampleProps> = (props) => {
  const { t } = useTranslation();
  const [popup, setPopup] = useState(false);

  return (
    <>
      <Modal
        show={props.popup}
        onHide={() => (setPopup(!popup), props.close)}
        className={`d-flex flex-row justify-content-center align-items-center  `}
        style={{
          height: "100vh",
          width: "100vw",
          overflow: "scroll",
          //   border: "1px solid red",
          background: "rgba(0,0,0,0.85)",
        }}
        contentClassName={`${styles.bodyModal}  `}
      >
        <div className={`${styles.body} `}>
          <Modal.Body
            className={`d-flex flex-column align-items-start  justify-content-start p-4
            bg-light color-black 
            
            `}
            style={{
              height: "90vh",
              width: "90vw",
              borderRadius: "20px",
              overflow: "scroll",
              //   border: `2px solid red`,
            }}
          >
            <div
              className="d-flex flex-row align-items-center justify-content-end col-12 "
              style={{ height: "50px" }}
            >
              <span
                className={`${styles.closeButton} `}
                onClick={() => props.close()}
              >
                <FontAwesomeIcon icon={faXmark as IconProp} />
              </span>
            </div>
            <div className="ps-7 pe-7 d-md-flex d-none flex-column align-items-start justify-content-start col-12 ">
              <div className="d-flex flex-row align-items-center  justify-content-start col-12 ">
                <span className=" sub-title">
                  {parse(t(`Business_quadratoni_titoletto_4`))}
                  {/* Time */}
                </span>
              </div>
              <div className="d-flex flex-row align-items-center  justify-content-start col-12 mt-4">
                <span className=" small-title fw-bold">
                  {parse(t(`Business_quadratoni4_titolo_interno`))}
                  {/* Don't lose it */}
                </span>
              </div>
              <div className="d-flex flex-row align-items-center  justify-content-start col-12 mt-4">
                <span className=" sub-title fw-normal  col-12">
                  {parse(t(`Business_quadratoni4_testo_interno`))}
                  {/* Update your informations in a matter of seconds. */}
                </span>
              </div>
              <div className={`${styles.content} col-12  mt-5 `}>
                <div className="d-flex flex-row align-items-center justify-content-center col-12  ">
                  <div className=" col-4 h-100 d-flex  flex-column align-items-center justify-content-start">
                    <div className={`col-4   ${styles.imgContainer1}`}>
                      <img src={timeImg} alt="timeImg"/>
                    </div>
                  </div>
                  <div className="col-8  h-100 d-flex  flex-column align-items-center justify-content-center ">
                    <div className="d-flex flex-row align-items-center justify-content-start  col-10 ps-5 ">
                      <span
                        className="light-title fw-bold  "
                        style={{ lineHeight: 1.1 }}
                      >
                        {parse(t(`Business_quadratoni4_testo_interno1`))}
                        {/* No reprinting need */}
                      </span>
                    </div>
                    <div className="d-flex flex-column align-items-start justify-content-center  col-10 ps-5 mt-7">
                      <span className="text-xxl  " style={{ lineHeight: 1.1 }}>
                        {parse(t(`Business_quadratoni4_testo_interno2`))}
                        {/* Need to change the informations on your card? <br /> No
                        need to reprint!
                        <br /> Update your web information with a couple of
                        clicks. */}
                      </span>
                      <span className="light-title fw-bold  mt-10">
                        {parse(t(`Business_quadrationi4_testo_interno3`))}
                        {/* All your new contacts, in one place */}
                      </span>
                      <span className="text-xxl  mt-7">
                        {parse(t(`Business_quadrationi4_testo_interno4`))}
                        {/* Everytime you connect with a new individual, after a
                        taps on your card you can recive his contact
                        information, via email and in our platform. */}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className=" d-flex d-sm-none flex-column align-items-start justify-content-start col-12 pb-4">
              <div className="d-flex flex-row align-items-center justify-content-start col-12 ">
                <span className=" sub-title">Time</span>
              </div>
              <div className="d-flex flex-row align-items-center justify-content-start col-12 mt-4">
                <span
                  className=" small-title fw-bold"
                  style={{ lineHeight: "1.05" }}
                >
                  {parse(t(`Business_quadratoni4_titolo_interno`))}
                  {/* Don't lose it */}
                </span>
              </div>
              <div className="d-flex flex-row align-items-center justify-content-start col-12 mt-4">
                <span className=" text fw-normal">
                  {parse(t(`Business_quadratoni4_testo_interno`))}
                  {/* Update your informations in a matter of seconds. */}
                </span>
              </div>
              <div className={`${styles.content} col-12  mt-5  `}>
                <div className="d-flex flex-column align-items-start justify-content-start col-12  ">
                  <div className=" col-12  d-flex  flex-column align-items-center justify-content-center">
                    <div
                      className={` text-center mt-4  ${styles.imgContainer1}`}
                    >
                      <img src={timeImg} alt="timeImg" />
                    </div>
                  </div>
                  <div className="col-12    d-flex  flex-column align-items-center justify-content-center pt-3">
                    <div className="d-flex flex-row align-items-center justify-content-center col-12">
                      <span
                        className="light-title fw-bold  text-center"
                        style={{ lineHeight: 1.1 }}
                      >
                        {parse(t(`Business_quadratoni4_testo_interno1`))}
                        {/* No reprinting needed */}
                      </span>
                    </div>
                    <div className="d-flex flex-column align-items-center justify-content-center col-12 mt-4 px-5">
                      <span
                        className="text-xxl  text-center"
                        style={{ lineHeight: 1.1 }}
                      >
                        {parse(t(`Business_quadratoni4_testo_interno2`))}

                        {/* Need to change the informations
                        <br /> on your card? <br />
                        <br /> No need to reprint!
                        <br /> Update your web information with a couple of
                        clicks. */}
                      </span>
                    </div>
                    <div className="d-flex flex-row align-items-center justify-content-center col-12 mt-4">
                      <span
                        className="light-title fw-bold  text-center"
                        style={{ lineHeight: 1.1 }}
                      >
                        {parse(t(`Business_quadrationi4_testo_interno3`))}
                        {/* All your new contacts,
                        <br /> in one place */}
                      </span>
                    </div>
                    <div className="d-flex flex-column align-items-center justify-content-center col-12 mt-3 p-3">
                      <span
                        className="text-xxl  text-center"
                        style={{ lineHeight: 1.1 }}
                      >
                        {parse(t(`Business_quadrationi4_testo_interno4`))}
                        {/* Everytime you connect with a new individual, after a
                        taps on your card you can recive his contact
                        information,via email and in our platform. */}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
        </div>
      </Modal>
    </>
  );
};

export default BusinessCardTimePopUp;
