import React, { useState } from "react";

import styles from "./HomeEnvironmentPopUp.module.scss";

import { Modal } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faXmark } from "@fortawesome/free-solid-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

import parse from "html-react-parser";
import { useTranslation } from "react-i18next";

const cardImg =
  "https://d16qu8ed6p45v5.cloudfront.net/dlctechwebsite/6_Group of 15 assets__New background.png";
const vergini =
  "https://d16qu8ed6p45v5.cloudfront.net/dlctechwebsite/reviews_pattern.png";

interface PopUpExampleProps {
  popup: boolean;
  close: () => void;
}

const HomeEnvironmentPopUp: React.FC<PopUpExampleProps> = (props) => {
  const [popup, setPopup] = useState(false);
  const { t } = useTranslation();

  return (
    <>
      <Modal
        show={props.popup}
        onHide={() => (setPopup(!popup), props.close)}
        className={`d-flex flex-row justify-content-center align-items-center  `}
        style={{
          height: "100vh",
          width: "100vw",
          overflow: "scroll",
          //   border: "1px solid red",
          background: "rgba(0,0,0,0.85)",
        }}
        contentClassName={`${styles.bodyModal}  `}
      >
        <div className={`${styles.body} `}>
          <Modal.Body
            className={`d-flex flex-column align-items-start  justify-content-start p-4
            bg-light color-black 
            
            `}
            style={{
              height: "90vh",
              width: "90vw",
              borderRadius: "20px",
              overflow: "scroll",
              //   border: `2px solid red`,
            }}
          >
            <div
              className="d-flex flex-row align-items-center justify-content-end col-12 "
              style={{ height: "50px" }}
            >
              <span
                className={`${styles.closeButton} `}
                onClick={() => props.close()}
              >
                <FontAwesomeIcon icon={faXmark as IconProp} />
              </span>
            </div>
            <div className="ps-7 pe-7 d-md-flex d-none flex-column align-items-start justify-content-start col-12 ">
              <div className="d-flex flex-row align-items-center  justify-content-start col-12 ">
                <span className=" sub-title">
                  {parse(t(`Homepage_quadratoni_titoletto_2`))}
                  {/* Environment */}
                </span>
              </div>
              <div className={`${styles.content} col-12  mt-5 `}>
                <div className="d-flex flex-row align-items-center justify-content-center col-12  ">
                  <div className={`col-6   ${styles.imgContainer1}`}>
                    <img src={cardImg} alt="environmentcardImage"/>
                  </div>
                  <div className="col-6 h-100 ms-5  d-flex flex-column align-items-start justify-content-start">
                    <span
                      className="small-title mt-5"
                      style={{ lineHeight: "1.1" }}
                    >
                      {parse(t(`Homepage_quadratoni_titolo_interno_2`))}
                      {/* Our focus on <br /> Sustainability */}
                    </span>
                    <span className="mt-md-5 mt-xl-7 col-xl-6 col-sm-10 text">
                      {parse(t(`Homepage_quadratoni_interno_2`))}
                      {/* Our priority is to create products that enhance our
                      client’s efficiency while reducing their environmental
                      impact. */}
                    </span>
                    <span className="mt-md-3 mt-xl-5 col-xl-6 col-sm-10 text">
                      {parse(t(`Homepage_quadratoni_interno_2_2`))}
                      {/* We prioritize sustainable materials and actively work to
                      minimize carbon footprint */}
                    </span>
                    <span className="mt-md-3 mt-xl-5 col-xl-6 col-sm-10 text">
                      {parse(t(`Homepage_quadratoni_interno_2_3`))}
                      {/* Our goal is to be a NetZero company by 2025. */}
                    </span>
                  </div>
                </div>
              </div>
              <div className={`${styles.content} col-12 mt-5 `}>
                <div className="d-flex flex-row align-items-center justify-content-center col-12  ">
                  <div className="col-6 ps-5 d-flex flex-column align-items-start justify-content-start">
                    <span className=" col-7 fw-normal text">
                      {parse(t(`Homepage_quadratoni_interno_2_4`))}
                      {/* <b>DLC Review </b> is made entirely from wood sourced in
                      Europe, ensuring a low environmental impact. */}
                    </span>
                    <span className="mt-10 col-7 fw-normal text">
                      {parse(t(`Homepage_quadratoni_interno_2_5`))}
                      {/* <b>DLC Business Cards</b>, available in wood, recycled
                      PVC, and metal, contribute to decreasing paper waste,
                      providing an innovative and eco-friendly solution. */}
                    </span>
                  </div>
                  <div className={`col-6   border ${styles.imgContainer2}`}>
                    <img src={vergini} alt="environmentcardImagevergini" />
                  </div>
                </div>
              </div>
            </div>
            <div className=" d-flex d-sm-none flex-column align-items-start justify-content-start col-12 pb-4">
              <div className="d-flex flex-row align-items-center justify-content-start col-12 ">
                <span className=" sub-title">
                  {parse(t(`Homepage_quadratoni_titoletto_2`))}
                  {/* Environment */}
                </span>
              </div>
              <div className={`${styles.content} col-12  mt-5  `}>
                <div className="d-flex flex-column col-12  ">
                  <span
                    className="small-title col-12 p-4"
                    style={{ lineHeight: "1.1" }}
                  >
                    {parse(t(`Homepage_quadratoni_titolo_interno_2`))}
                    {/* Our focus on <br /> Sustainability */}
                  </span>
                  <span className="mt-5 col-12 ps-4 pe-4">
                    {parse(t(`Homepage_quadratoni_interno_2_2`))}
                    {/* Experience innovation with DLC's products leveraging
                    cutting-edge IoT technology, enhancing user interactions and
                    your efficiency. */}
                  </span>
                  <span className="mt-5 col-12 ps-4 pe-4">
                    {parse(t(`Homepage_quadratoni_interno_2_3`))}
                    {/* Our team is constantly working to implement new innovative
                    solutions and improving existing ones. */}
                  </span>
                  <div className={`${styles.imgContainer1}  mt-5`}>
                    <img src={cardImg} alt="environmentcardImage" />
                  </div>
                </div>
              </div>
              <div className={`${styles.content} col-12  mt-5  `}>
                <div className="d-flex flex-column col-12 h-50 ">
                  <span className="mt-10 col-12 ps-4 pe-4">
                    {parse(t(`Homepage_quadratoni_interno_2_4`))}
                    {/* <b>DLC Review </b> is made entirely from wood sourced in
                    Europe, ensuring a low environmental impact. */}
                  </span>
                  <span className="mt-5 col-12 ps-4 pe-4">
                    {parse(t(`Homepage_quadratoni_interno_2_5`))}
                    {/* <b>DLC Business Cards</b>, available in wood, recycled PVC,
                    and metal, contribute to decreasing paper waste, providing
                    an innovative and eco-friendly solution. */}
                  </span>
                </div>
                <div className={`${styles.imgContainer2}  mt-5 h-50`}>
                  <img src={vergini} alt="environmentcardImageVergini" />
                </div>
              </div>
            </div>
          </Modal.Body>
        </div>
      </Modal>
    </>
  );
};

export default HomeEnvironmentPopUp;
