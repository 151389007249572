import React, { useEffect, useState } from "react";

import styles from "./DropDownContent.module.scss";
import DropDownItem from "../DropDownItem/DropDownItem";
interface PopUpOption {
  value: string;
  label: string;
}

interface DropDownContentProps {
  open: boolean;
  options: PopUpOption[];
  click: (option: PopUpOption) => void;
}

const DropDownContent: React.FC<DropDownContentProps> = (props) => {
  const [selectedValue, setSelectedValue] = useState("");

  return (
    <>
      <div
        className={`${
          styles.contentDiv
        } d-flex flex-column align-items-start justify-content-start col-12 ${
          props.open && styles.contentDivOpen
        }`}
      >
        {props.options.map((item) => (
          <DropDownItem
            key={item.value}
            label={item.label}
            click={() => props.click(item)}
          />
        ))}
      </div>
    </>
  );
};

export default DropDownContent;
